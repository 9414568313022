import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { IOrder, IOrderInitialState } from "./orderModel";
import {
  createOrder,
  deleteOrder,
  findAllOrder,
  findAllPendingOrder,
  findbyOrderNo,
  getOrderById,
  updateOrder,
} from "./services/order.services";

const initialState: IOrderInitialState = {
  loading: false,
  formLoading: false,
  error: "",
  OrderList: [],
  OrderID: null,
  OrderDetail: {
    // order_date: new Date(),
    // delivery_date: new Date(new Date().setDate(new Date().getDate() + 12)),
    order_date: "",
    delivery_date: "",
    party_id: null,
    order_no: null,
    item_id: null,
    item_code_id: "",
    description: "",
    estimate_weight: null,
    purity: null,
    purity_name:"",
    priority_id: null,
    color: "",
    estimate_amount: null,
    remark: "",
    size: "",
    Images: [],
    processdetails: [
      {
        process_id: null,
        karigar_id: null,
        // given_date: new Date(new Date().setDate(new Date().getDate() + 15)),
        given_date: "",
        duration_days: null,
        // receive_date: new Date(new Date().setDate(new Date().getDate() + 15)),
        receive_date: "",
        estimated_weight: null,
        image_id: null,
        description: "",
      },
    ],
    createdby: null,
    Details: "",
    Message: "",
    sizedetails: [],
  },
  ProcessDeleteIndex: -1,
  SizeDeleteIndex: -1,
  PendingOrderList: [],
};

const orderSlice = createSlice({
  name: "order",
  initialState,
  reducers: {
    clearOrderDetail: (state) => {
      state.OrderDetail = initialState.OrderDetail;
    },
    setOrderID: (state, action) => {
      state.OrderID = action.payload;
    },
    clearOrderID: (state) => {
      state.OrderID = null;
    },
    setProcessDetailDeleteIndex: (state, action) => {
      state.ProcessDeleteIndex = action.payload;
    },
    setSizeDeleteIndex: (state, action) => {
      state.SizeDeleteIndex = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(findAllOrder.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(
      findAllOrder.fulfilled,
      (state, action: PayloadAction<IOrder[]>) => {
        state.loading = false;
        state.OrderList = action.payload || [];
      }
    );
    builder.addCase(findAllOrder.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message || "Something went wrong";
      state.OrderList = [];
    });

    // builder.addCase(findAllPendingOrder.pending, (state) => {
    //   state.loading = true;
    //   state.error = "";
    // });
    // builder.addCase(findAllPendingOrder.fulfilled, (state, action: any) => {
    //   state.loading = false;
    //   state.PendingOrderList = action.payload || [];
    // });
    // builder.addCase(findAllPendingOrder.rejected, (state, action) => {
    //   state.loading = false;
    //   state.error = action.error.message || "Something went wrong";
    //   state.PendingOrderList = [];
    // });
    builder.addCase(findAllPendingOrder.pending, (state) => {
      state.formLoading = true;
      state.error = "";
    });
    builder.addCase(findAllPendingOrder.fulfilled, (state, action: any) => {
      state.formLoading = false;
      state.PendingOrderList = action.payload;
    });
    builder.addCase(findAllPendingOrder.rejected, (state, action) => {
      state.formLoading = false;
      state.PendingOrderList = initialState.PendingOrderList;
      state.error = action.error.message || "Something went wrong";
    });

    builder.addCase(createOrder.pending, (state) => {
      state.formLoading = false;
      state.error = "";
    });
    builder.addCase(createOrder.fulfilled, (state) => {
      state.formLoading = false;
    });
    builder.addCase(createOrder.rejected, (state, action) => {
      state.formLoading = false;
      state.error = action.error.message || "Something went wrong";
    });

    builder.addCase(updateOrder.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(updateOrder.fulfilled, (state) => {
      state.loading = false;
    });
    builder.addCase(updateOrder.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message || "Something went wrong";
    });

    builder.addCase(deleteOrder.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(deleteOrder.fulfilled, (state) => {
      state.loading = false;
    });
    builder.addCase(deleteOrder.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message || "Something went wrong";
    });
    builder.addCase(getOrderById.pending, (state) => {
      state.formLoading = true;
      state.error = "";
    });
    builder.addCase(getOrderById.fulfilled, (state, action: any) => {
      state.formLoading = false;
      state.OrderDetail = action.payload;
    });
    builder.addCase(getOrderById.rejected, (state, action) => {
      state.formLoading = false;
      state.OrderDetail = initialState.OrderDetail;
      state.error = action.error.message || "Something went wrong";
    });

    builder.addCase(findbyOrderNo.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(findbyOrderNo.fulfilled, (state, action: any) => {
      state.loading = false;
      state.OrderDetail = action.payload;
    });
    builder.addCase(findbyOrderNo.rejected, (state, action) => {
      state.loading = false;
      state.OrderDetail = initialState.OrderDetail;
      state.error = action.error.message || "Something went wrong";
    });
  },
});

export const {
  clearOrderDetail,
  clearOrderID,
  setOrderID,
  setProcessDetailDeleteIndex,
  setSizeDeleteIndex,
} = orderSlice.actions;
export default orderSlice.reducer;
