import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { IGhatReceipt, IGhatReceiptInitialState } from "./ghatReceiptModel";
import {
  createGhatReceipt,
  deleteGhatReceipt,
  findAllGhatReceipt,
  findByOrderNoGhatReceipt,
  getAllOrderGhatIssue,
  getGhatReceiptById,
  updateGhatReceipt,
} from "./services/gharteceipt.services";

const initialState: IGhatReceiptInitialState = {
  loading: false,
  formLoading: false,
  error: "",
  GhatIssueOrderList: [],
  GhatReceiptList: [],
  GhatReceiptID: null,
  OrderDetailsGhat: {
    order_no: null,
    item_id: null,
    ghat_id: null,
    item_name: "",
    item_description: "",
    item_category_id: null,
    weight: null,
    purity: null,
  },
  GhatReceiptDetail: {
    karigar_id: null,
    receipt_date: "",
    order_no: null,
    item_id: null,
    item_name: "",
    receiver_weight: null,
    purity: null,
    remark: null,
    phine: null,
    amount: null,
    process_id: null,
    wastage: null,
    isactive: 1,
    issize: false,
    isweight: false,
    isdesign: false,
    isnaka: false,
    ispurity: false,
    iscolor: false,
  },
};

const ghatReceiptSlice = createSlice({
  name: "ghatreceipt",
  initialState,
  reducers: {
    clearGhatReceiptDetail: (state) => {
      state.GhatReceiptDetail = initialState.GhatReceiptDetail;
    },
    setGhatReceiptID: (state, action) => {
      state.GhatReceiptID = action.payload;
    },
    clearGhatReceiptID: (state) => {
      state.GhatReceiptID = null;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(findAllGhatReceipt.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(
      findAllGhatReceipt.fulfilled,
      (state, action: PayloadAction<IGhatReceipt[]>) => {
        state.loading = false;
        state.GhatReceiptList = action.payload || [];
      }
    );
    builder.addCase(findAllGhatReceipt.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message || "Something went wrong";
      state.GhatReceiptList = [];
    });

    builder.addCase(createGhatReceipt.pending, (state) => {
      state.formLoading = false;
      state.error = "";
    });
    builder.addCase(createGhatReceipt.fulfilled, (state) => {
      state.formLoading = false;
    });
    builder.addCase(createGhatReceipt.rejected, (state, action) => {
      state.formLoading = false;
      state.error = action.error.message || "Something went wrong";
    });

    builder.addCase(updateGhatReceipt.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(updateGhatReceipt.fulfilled, (state) => {
      state.loading = false;
    });
    builder.addCase(updateGhatReceipt.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message || "Something went wrong";
    });

    builder.addCase(deleteGhatReceipt.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(deleteGhatReceipt.fulfilled, (state) => {
      state.loading = false;
    });
    builder.addCase(deleteGhatReceipt.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message || "Something went wrong";
    });
    builder.addCase(getGhatReceiptById.pending, (state) => {
      state.formLoading = true;
      state.error = "";
    });
    builder.addCase(getGhatReceiptById.fulfilled, (state, action: any) => {
      state.formLoading = false;
      state.GhatReceiptDetail = action.payload;
    });
    builder.addCase(getGhatReceiptById.rejected, (state, action) => {
      state.formLoading = false;
      state.GhatReceiptDetail = initialState.GhatReceiptDetail;
      state.error = action.error.message || "Something went wrong";
    });
    builder.addCase(findByOrderNoGhatReceipt.pending, (state) => {
      // state.formLoading = true;
      state.error = "";
    });
    builder.addCase(
      findByOrderNoGhatReceipt.fulfilled,
      (state, action: any) => {
        state.formLoading = false;
        state.OrderDetailsGhat = action.payload;
      }
    );
    builder.addCase(findByOrderNoGhatReceipt.rejected, (state, action) => {
      state.formLoading = false;
      state.OrderDetailsGhat = initialState.OrderDetailsGhat;
      state.error = action.error.message || "Something went wrong";
    });
    builder.addCase(getAllOrderGhatIssue.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(getAllOrderGhatIssue.fulfilled, (state, action) => {
      state.loading = false;
      state.GhatIssueOrderList = action.payload || [];
    });
    builder.addCase(getAllOrderGhatIssue.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message || "Something went wrong";
      state.GhatIssueOrderList = initialState.GhatIssueOrderList;
    });
  },
});

export const { clearGhatReceiptDetail, clearGhatReceiptID, setGhatReceiptID } =
  ghatReceiptSlice.actions;
export default ghatReceiptSlice.reducer;
