import React, { useState } from "react";
import { PieChartOutlined, UserOutlined } from "@ant-design/icons";
import { GrTransaction } from "react-icons/gr";
import { GrUserManager } from "react-icons/gr";
import type { MenuProps } from "antd";
import { Layout, Menu } from "antd";
import { useLocation, useNavigate } from "react-router-dom";

const { Sider } = Layout;

type MenuItem = Required<MenuProps>["items"][number];

function getItem(
  label: React.ReactNode,
  key: React.Key,
  icon?: React.ReactNode,
  children?: MenuItem[]
): MenuItem {
  return {
    key,
    icon,
    children,
    label,
  } as MenuItem;
}

const items: MenuItem[] = [
  getItem("Dashboard", "", <PieChartOutlined />),
  getItem("User Management", "usermanagement", <GrUserManager />, [
    getItem("User", "user"),
    getItem("User Type", "usertype"),
    getItem("Menu", "menu"),
    getItem("Rights", "rights"),
  ]),
  getItem("Masters", "masters", <UserOutlined />, [
    getItem("Country", "country"),
    getItem("State", "state"),
    getItem("City", "city"),
    getItem("Charges", "charges"),
    getItem("Product Category", "itemcategory"),
    getItem("Product", "item"),
    getItem("Process", "process"),
    getItem("Priority", "priority"),
    getItem("Vepari", "party"),
    getItem("Karigar", "karigar"),
    getItem("Product Sub Category", "productsubcategory"),
    getItem("Purity", "purity"),
    getItem("Unit", "unit"),
  ]),
  getItem("Transaction", "transaction", <GrTransaction />, [
    getItem("Order", "order"),
    getItem("Ghat Issue", "ghatissue"),
    getItem("Ghat Issue Receipt", "ghatissuereceipt"),
  ]),
];

interface HeaderProps {
  collapsed: boolean;
  setCollapsed: (value: boolean) => void;
}

const Header: React.FC<HeaderProps> = ({ collapsed, setCollapsed }) => {
  const navigate = useNavigate();
  const location = useLocation();

  const onClick: MenuProps["onClick"] = (e) => {
    navigate(e.key);
  };

 /****************************ADD BY JYOTINDRA ******************************/
  const [openKeys, setOpenKeys] = useState<string[]>([]);
  const onOpenChange: MenuProps["onOpenChange"] = (keys) => {
    // Ensure only one menu item is open at a time
    const latestOpenKey = keys.find((key) => openKeys.indexOf(key) === -1);
    if (items.map((item) => item?.key).includes(latestOpenKey)) {
      setOpenKeys(latestOpenKey ? [latestOpenKey] : []);
    } else {
      setOpenKeys(keys);
    }
  };
  /*************************************************************************/

  return (
    <Sider
      collapsible
      collapsed={collapsed}
      onCollapse={(value) => setCollapsed(value)}
    >
      <div className="drawer-logo">
        <h4 style={{ textAlign: "center", color: "white" }}>HK</h4>
      </div>
      <Menu
        className="sider-scroll"
        style={{
          overflow: "auto",
          height: "calc(100vh - 115px)",
        }}
        onClick={onClick}
        theme="dark"
        onOpenChange={onOpenChange} // Add jyotindra
        openKeys={openKeys} // Add jyotindra
        defaultSelectedKeys={[""]}
        selectedKeys={[location.pathname?.substring(1)?.split("/")[0]]}
        mode="inline"
        items={items}
      />
    </Sider>
  );
};

export default Header;
