import {
  Field,
  FieldArray,
  Form,
  FormElement,
  FormRenderProps,
} from "@progress/kendo-react-form";
import React, { useEffect, useRef, useState } from "react";
import ShadowCard from "../../components/common/ShadowCard";
import { GridLayout, GridLayoutItem } from "@progress/kendo-react-layout";
import { Typography } from "@progress/kendo-react-common";
import FormSelectionField from "../../components/formFields/FormSelectionField";
import { requiredValidator } from "../../components/formFields/CommonValidator";
import FormDatePicker from "../../components/formFields/FormDateField";
import { Button } from "@progress/kendo-react-buttons";
import { closed } from "../../components/drawer/drawerSlice";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { useLocation, useNavigate } from "react-router-dom";
import { RootState } from "../../app/store";
import { LoadingPanel } from "../../components/layout/Loading";
import { clearGhatReceiptDetail } from "./ghatReceiptSlice";
import moment from "moment";
import { FaRegEye } from "react-icons/fa";
import { findAllActiveKarigar } from "../karigar/services/karigar.services";
import {
  createGhatReceipt,
  findByOrderNoGhatReceipt,
  getAllOrderGhatIssue,
  getGhatReceiptById,
  updateGhatReceipt,
} from "./services/gharteceipt.services";
import FormRichTextField from "../../components/formFields/FormRichTextField";
import FormTextField from "../../components/formFields/FormTextField";
import {
  findAllActiveItem,
  getImageById,
} from "../item/services/item.services";
import { findAllActivePurity } from "../purity/services/purity.services";
import { findAllActiveProcess } from "../process/services/process.services";
import { STATUSARRAY } from "../../_contstants/common";
import FormNumericTextField from "../../components/formFields/FormNumericTextField";
import { closeDialog, openDialog } from "../../components/dialog/dialogSlice";
import { findbyOrderNo } from "../order/services/order.services";
import AppDialog from "../../components/dialog/Dialog";
import Slider from "react-slick";
import "./selectionDialog.css";
import { ImCross } from "react-icons/im";
import {
  GridCellProps,
  GridCustomCellProps,
  GridCustomHeaderCellProps,
  GridFilterChangeEvent,
  Grid as KendoGrid,
  GridColumn as Column,
  GridPageChangeEvent,
} from "@progress/kendo-react-grid";
import {
  CompositeFilterDescriptor,
  filterBy,
} from "@progress/kendo-data-query";
import { setCurrentPage, setPageLimit } from "../pagination/paginationSlice";
import { HeaderThElement } from "@progress/kendo-react-data-tools";
import AlertBox from "../../components/common/AlertBox";
import ImageArray from "./ImageArray";
import FormCheckbox from "../../components/formFields/FormCheckbox";
import ButtonForAll from "../../components/common/Button";
import { clearOrderDetail } from "../order/orderSlice";
import { MdOutlineImageNotSupported } from "react-icons/md";

interface ChangePhineWatchers {
  formRenderProps: FormRenderProps;
}

const ChangePhineWatcher: React.FC<ChangePhineWatchers> = ({
  formRenderProps,
}) => {
  const weight = formRenderProps.valueGetter(`receiver_weight`);
  const purity = formRenderProps.valueGetter(`purity`);
  const wastage = formRenderProps.valueGetter(`wastage`);
  const ItemID = formRenderProps.valueGetter(`item_id`);
  const dispatch = useAppDispatch();
  useEffect(() => {
    const Weight = weight ? +weight : 0;
    const Purity = purity ? +purity : 0;
    const Wastage = wastage ? +wastage : 0;
    let phine = (Weight * (Purity + Wastage)) / 100;

    formRenderProps.onChange(`phine`, {
      value: phine.toFixed(2),
    });
  }, [weight, purity, wastage]);

  useEffect(() => {
    if (ItemID) {
      const fetchImage = async () => {
        const { payload: response, meta } = await dispatch(
          getImageById(ItemID)
        );
        if (meta.requestStatus === "fulfilled" && response) {
          const Images = JSON.parse(JSON.stringify(response));
          formRenderProps?.onChange("Images", { value: Images });
        }
      };
      fetchImage();
    }
  }, [ItemID]);

  return null;
};

const CreateGhatReceipt: React.FC = () => {
  const dispatch = useAppDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const gridRef = useRef<any>(null);
  const formLoading = useAppSelector((state) => state.ghatreceipt?.formLoading);
  const ItemLoading = useAppSelector((state) => state.item.formLoading);
  const KarigarList = useAppSelector((state) => state.karigar?.KarigarList);
  const PurityList = useAppSelector((state) => state.purity?.PurityList);
  const ProcessList = useAppSelector((state) => state.Process?.ProcessList);
  // const loading = useAppSelector((state) => state.item?.loading);
  const dialogName = useAppSelector((state) => state.dialog.dialogName);
  const GhatReceiptDetail = useAppSelector(
    (state: RootState) => state.ghatreceipt.GhatReceiptDetail
  );
  const ghat_receipt_guid = location?.state?.ghat_receipt_guid;
  // console.log("ghat_receipt_guid", ghat_receipt_guid);

  const ID = location?.state?.ID;
  const [formKey, setFormKey] = useState(1);
  const today = new Date();
  const maxDate =
    new Date(today.getFullYear(), today.getMonth(), today.getDate()) || null;

  useEffect(() => {
    setFormKey(formKey + 1);
  }, [GhatReceiptDetail]);

  const handleFormClose = () => {
    dispatch(closed());
    navigate("/ghatissuereceipt");
  };

  useEffect(() => {
    dispatch(findAllActiveProcess());
    dispatch(findAllActiveKarigar());
    dispatch(findAllActivePurity());
    dispatch(findAllActiveItem());
    dispatch(getAllOrderGhatIssue());
    return () => {
      dispatch(clearGhatReceiptDetail());
    };
  }, []);

  useEffect(() => {
    if (ghat_receipt_guid) {
      dispatch(getGhatReceiptById(ghat_receipt_guid));
      dispatch(closed());
    }
  }, [ghat_receipt_guid]);

  const handleSubmit = async (values: any) => {
    const formData = {
      karigar_id: values.karigar_id ? +values.karigar_id : null,
      receipt_date: values.receipt_date
        ? moment(values.receipt_date).format("YYYY-MM-DD")
        : "",
      order_no: values.order_no ? +values.order_no : null,
      item_id: values.item_id,
      receiver_weight: values.receiver_weight,
      purity: values.purity ? +values.purity : null,
      remark: values.remark,
      phine: values.phine ? +values.phine : null,
      amount: values.amount ? +values.amount : null,
      process_id: values.process_id ? values.process_id : null,
      wastage: values.wastage ? +values.wastage : null,
      issize: values.issize,
      isweight: values.isweight,
      isdesign: values.isdesign,
      isnaka: values.isnaka,
      ispurity: values.ispurity,
      iscolor: values.iscolor,
      isactive: values.isactive === 1,
    };
    if (ghat_receipt_guid) {
      const editPayload = {
        id: ID,
        ghat_receipt_guid: ghat_receipt_guid,
        ...formData,
      };
      try {
        const response = await dispatch(updateGhatReceipt(editPayload));
        if (response?.meta?.requestStatus === "fulfilled") {
          dispatch(clearGhatReceiptDetail());
          dispatch(closed());
          navigate("/ghatissuereceipt");
        }
      } catch (error) {
        console.error("Error in handleSubmit:", error);
        throw error;
      }
    } else {
      try {
        // console.log(formData);

        const response = await dispatch(createGhatReceipt(formData));
        if (response?.meta?.requestStatus === "fulfilled") {
          dispatch(clearGhatReceiptDetail());
          navigate("/ghatissuereceipt");
        }
      } catch (error) {
        console.error("Error in handleSubmit:", error);
        throw error;
      }
    }
  };

  const handleViewOrder = (order_no: any) => {
    dispatch(findbyOrderNo(order_no));
    dispatch(openDialog("viewOrderDialog"));
  };
  if (formLoading) return <LoadingPanel gridRef={gridRef} />;

  const handleSelectOrder = () => {
    dispatch(openDialog("SelectOrderDialog"));
  };

  // console.log("GhatReceiptDetail", GhatReceiptDetail);
  return (
    <>
      <Form
        onSubmit={handleSubmit}
        key={formKey}
        initialValues={GhatReceiptDetail}
        render={(formRenderProps: FormRenderProps) => (
          <FormElement>
            <ShadowCard style={{ padding: 12 }}>
              <GridLayout
                style={{ marginRight: 20 }}
                gap={{ rows: 0, cols: 10 }}
                cols={[{ width: "33%" }, { width: "33%" }, { width: "33%" }]}
              >
                <GridLayoutItem colSpan={3}>
                  <Typography.h4>
                    {ghat_receipt_guid
                      ? "Update Ghat Issue Receipt"
                      : "Create Ghat Issue Receipt"}
                  </Typography.h4>
                </GridLayoutItem>
                <GridLayoutItem>
                  <Field
                    name="receipt_date"
                    label="Receipt Date"
                    placeholder="day/month/year"
                    format={"dd/MM/yyyy"}
                    max={maxDate}
                    value={new Date()}
                    component={FormDatePicker}
                    validator={requiredValidator}
                  />
                </GridLayoutItem>
                <GridLayoutItem
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "end",
                  }}
                >
                  <Button
                    type="button"
                    fillMode={"solid"}
                    themeColor={"primary"}
                    style={{ width: "fit-content", padding: "5px" }}
                    onClick={handleSelectOrder}
                  >
                    Select Order
                  </Button>
                  {formRenderProps.valueGetter("order_no") !== null && (
                    <Field
                      name="order_no"
                      label="Order No"
                      placeholder="Order No"
                      disabled={"true"}
                      component={FormTextField}
                      validator={requiredValidator}
                    />
                  )}
                </GridLayoutItem>
                <SelectOrderDialog formRenderProps={formRenderProps} />
                <GridLayoutItem>
                  <Field
                    name="karigar_id"
                    label="Karigar Nick Name"
                    disabled={ghat_receipt_guid}
                    placeholder="Karigar Nick Name"
                    component={FormSelectionField}
                    validator={requiredValidator}
                    options={KarigarList?.map((karigar: any) => {
                      return {
                        value: karigar.id,
                        label: karigar.karigar_nick_name,
                      };
                    })}
                  />
                </GridLayoutItem>

                <GridLayoutItem>
                  <Field
                    name="item_name"
                    label="Product Name"
                    placeholder="Product Name"
                    disabled
                    component={FormTextField}
                    validator={requiredValidator}
                  />
                </GridLayoutItem>

                <GridLayoutItem colSpan={2} rowSpan={2}>
                  <Field
                    name="remark"
                    label="Product Description"
                    placeholder="Product Description"
                    component={FormRichTextField}
                  />
                </GridLayoutItem>
                <GridLayoutItem>
                  <Field
                    name="receiver_weight"
                    label="Receiver Weight"
                    type="number"
                    format="n3"
                    placeholder="0.000"
                    component={FormNumericTextField}
                    validator={requiredValidator}
                  />
                </GridLayoutItem>
                <GridLayoutItem>
                  <Field
                    name="purity"
                    label="Purity"
                    placeholder="Purity"
                    component={FormSelectionField}
                    validator={requiredValidator}
                    options={PurityList?.map((purity: any) => {
                      return {
                        value: purity.ID,
                        label: purity.purity_name,
                      };
                    })}
                  />
                </GridLayoutItem>
                <GridLayoutItem>
                  <Field
                    name="wastage"
                    label="Wastage"
                    type="number"
                    placeholder="0"
                    component={FormNumericTextField}
                    validator={requiredValidator}
                  />
                </GridLayoutItem>
                <GridLayoutItem>
                  <Field
                    name="phine"
                    label="Phine"
                    type="number"
                    disabled
                    placeholder="0"
                    component={FormTextField}
                  />
                </GridLayoutItem>
                <GridLayoutItem>
                  <Field
                    name="amount"
                    label="Amount"
                    type="number"
                    placeholder="0"
                    component={FormTextField}
                    validator={requiredValidator}
                  />
                </GridLayoutItem>
                <GridLayoutItem>
                  <Field
                    name="process_id"
                    label="Next Process"
                    placeholder="Next Process"
                    component={FormSelectionField}
                    options={ProcessList?.map((process: any) => {
                      return {
                        value: process.ID,
                        label: process.process_name,
                      };
                    })}
                  />
                </GridLayoutItem>
                <GridLayoutItem>
                  <Field
                    id={"IsActive"}
                    name={"isactive"}
                    label={"Status"}
                    component={FormSelectionField}
                    validator={requiredValidator}
                    options={STATUSARRAY?.map((status: any) => {
                      return {
                        value: status.value,
                        label: status.label,
                      };
                    })}
                  />
                </GridLayoutItem>
                {/* <OrderNoCahngeWatcher
                  formRenderProps={formRenderProps}
                  index={0}
                /> */}
                <ChangePhineWatcher formRenderProps={formRenderProps} />
              </GridLayout>
              <GridLayoutItem
                colSpan={3}
                style={{
                  textAlign: "end",
                  marginTop: "20px",
                  width: "100%",
                }}
              >
                <Button
                  type="button"
                  fillMode={"solid"}
                  themeColor={"primary"}
                  style={{ marginRight: 4 }}
                  disabled={
                    formRenderProps.valueGetter("order_no") === null ||
                    formRenderProps.valueGetter("order_no") === ""
                  }
                  onClick={() =>
                    handleViewOrder(formRenderProps.valueGetter("order_no"))
                  }
                >
                  View Order
                </Button>
                <Button
                  type="button"
                  fillMode={"outline"}
                  themeColor={"primary"}
                  style={{ marginRight: 4 }}
                  onClick={handleFormClose}
                >
                  Cancel
                </Button>

                <Button
                  type="button"
                  themeColor={"primary"}
                  fillMode={"solid"}
                  onClick={() => dispatch(openDialog("CheckListModalDialog"))}
                  disabled={!formRenderProps.allowSubmit || formLoading}
                >
                  {!ghat_receipt_guid ? "Create" : "Update"}
                </Button>
              </GridLayoutItem>
              {dialogName === "CheckListModalDialog" && (
                <AppDialog>
                  <div style={{ width: "20vw" }}>
                    <GridLayout
                      style={{
                        marginLeft: 10,
                      }}
                      gap={{ rows: 10, cols: 10 }}
                    >
                      <GridLayoutItem
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "space-between",
                        }}
                      >
                        <Typography.h4 style={{ margin: "0" }}>
                          {"Check List"}
                        </Typography.h4>
                        <ImCross
                          style={{
                            fontSize: 24,
                            color: "white",
                            background: "red",
                            borderRadius: "50%",
                            padding: "5px",
                          }}
                          onClick={() => dispatch(closeDialog())}
                        />
                      </GridLayoutItem>
                      <GridLayoutItem>
                        <Field
                          name="issize"
                          title={"Size"}
                          label="Size"
                          component={FormCheckbox}
                          validator={requiredValidator}
                          wrapperClassName="ghat-reciept-checkbox"
                        />
                      </GridLayoutItem>
                      <GridLayoutItem>
                        <Field
                          name="isweight"
                          title={"Weight"}
                          label="Weight"
                          component={FormCheckbox}
                          validator={requiredValidator}
                          wrapperClassName="ghat-reciept-checkbox"
                        />
                      </GridLayoutItem>
                      <GridLayoutItem>
                        <Field
                          name="isdesign"
                          title={"Design"}
                          component={FormCheckbox}
                          validator={requiredValidator}
                          label="Design"
                          wrapperClassName="ghat-reciept-checkbox"
                        />
                      </GridLayoutItem>
                      <GridLayoutItem>
                        <Field
                          name="isnaka"
                          title={"NAKA"}
                          component={FormCheckbox}
                          validator={requiredValidator}
                          label="NAKA"
                          wrapperClassName="ghat-reciept-checkbox"
                        />
                      </GridLayoutItem>
                      <GridLayoutItem>
                        <Field
                          name="iscolor"
                          title={"Color"}
                          component={FormCheckbox}
                          validator={requiredValidator}
                          label="Color"
                          wrapperClassName="ghat-reciept-checkbox"
                        />
                      </GridLayoutItem>
                      <GridLayoutItem>
                        <Field
                          name="ispurity"
                          title={"Purity"}
                          component={FormCheckbox}
                          validator={requiredValidator}
                          label="Purity"
                          wrapperClassName="ghat-reciept-checkbox"
                        />
                      </GridLayoutItem>
                      <GridLayoutItem
                        style={{
                          textAlign: "end",
                          marginTop: "20px",
                          width: "100%",
                        }}
                      >
                        <Button
                          type="button"
                          fillMode={"outline"}
                          themeColor={"primary"}
                          style={{ marginRight: 4 }}
                          onClick={() => dispatch(closeDialog())}
                        >
                          Cancel
                        </Button>
                        <ButtonForAll
                          label={"Submit"}
                          themeColor="primary"
                          type="button"
                          onClick={formRenderProps.onSubmit}
                          disabled={!formRenderProps.allowSubmit}
                        />
                      </GridLayoutItem>
                    </GridLayout>
                  </div>
                </AppDialog>
              )}
              <ViewOrderDialog />
            </ShadowCard>
            {formRenderProps.valueGetter("item_id") !== null && (
              <>
                {ItemLoading ? (
                  <LoadingPanel gridRef={gridRef} />
                ) : (
                  <ShadowCard style={{ marginTop: 10 }}>
                    <GridLayoutItem colSpan={3}>
                      <Typography.h4>Documents Upload</Typography.h4>
                    </GridLayoutItem>
                    {formRenderProps.valueGetter("item_id") !== null &&
                      formRenderProps.valueGetter("Images")?.length > 0 && (
                        <FieldArray
                          formRenderProps={formRenderProps}
                          component={ImageArray}
                          name="Images"
                        />
                      )}
                  </ShadowCard>
                )}
              </>
            )}
          </FormElement>
        )}
      />
    </>
  );
};

export default CreateGhatReceipt;

const ViewOrderDialog = () => {
  const dialogName = useAppSelector((state) => state.dialog.dialogName);
  const orderDetails = useAppSelector((state) => state.order?.OrderDetail);
  const loading = useAppSelector((state) => state.order?.loading);
  const gridRef = useRef<any>(null);
  const dispatch = useAppDispatch();

  const handleOpenImage = (url: string) => {
    if (url && typeof url === "string") {
      window.open(url);
    }
  };
  const settings = {
    dots: true,
    infinite: true,
    autoplay: false,
    centerMode: false,
    autoplaySpeed: 2000,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: true,
    draggable: true,
    responsive: [
      {
        breakpoint: 991,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  useEffect(() => {
    return () => {
      dispatch(clearOrderDetail());
    };
  }, []);
  return (
    <>
      {dialogName === "viewOrderDialog" && (
        <AppDialog>
          {loading ? (
            <div>
              <LoadingPanel gridRef={gridRef} />
            </div>
          ) : (
            <div style={{ width: 500, height: "80vh" }}>
              <GridLayout
                style={{
                  marginRight: 10,
                }}
                gap={{ rows: 10, cols: 10 }}
                cols={[{ width: "50%" }, { width: "50%" }]}
              >
                <GridLayoutItem
                  colSpan={2}
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <Typography.h4 style={{ margin: 0 }}>
                    {"Order Details"}
                  </Typography.h4>
                  <ImCross
                    style={{
                      color: "white",
                      background: "red",
                      borderRadius: "50%",
                      padding: "5px",
                      fontSize: "24px",
                    }}
                    onClick={() => dispatch(closeDialog())}
                  />
                </GridLayoutItem>
                <GridLayoutItem>
                  <Typography.p>
                    <b>Order No :</b> {orderDetails.order_no}
                  </Typography.p>
                </GridLayoutItem>
                <GridLayoutItem>
                  <Typography.p>
                    <b>Product Name :</b> {orderDetails.item_name}
                  </Typography.p>
                </GridLayoutItem>
                <GridLayoutItem>
                  <Typography.p>
                    <b>Order Date :</b>{" "}
                    {orderDetails.order_date
                      ? moment(orderDetails.order_date).format("DD-MM-YYYY")
                      : " "}
                  </Typography.p>
                </GridLayoutItem>
                <GridLayoutItem>
                  <Typography.p>
                    <b>Delivery Date :</b>{" "}
                    {orderDetails.delivery_date
                      ? moment(orderDetails.delivery_date).format("DD-MM-YYYY")
                      : " "}
                  </Typography.p>
                </GridLayoutItem>
                <GridLayoutItem>
                  <Typography.p>
                    <b>Estimated Weight :</b> {orderDetails.weight}
                  </Typography.p>
                </GridLayoutItem>
                <GridLayoutItem>
                  <Typography.p>
                    <b>Estimated Amount :</b>₹{orderDetails.estimate_amount}
                  </Typography.p>
                </GridLayoutItem>
                <GridLayoutItem>
                  <Typography.p>
                    <b>Purity:</b> {orderDetails.purity}
                  </Typography.p>
                </GridLayoutItem>
                <GridLayoutItem>
                  <Typography.p>
                    <b>Size :</b> {orderDetails.size}
                  </Typography.p>
                </GridLayoutItem>
                <GridLayoutItem>
                  <Typography.p>
                    <b>Color:</b> {orderDetails.color}
                  </Typography.p>
                </GridLayoutItem>
                <GridLayoutItem></GridLayoutItem>
                <GridLayoutItem
                  colSpan={2}
                  style={{ display: "flex", justifyContent: "center" }}
                >
                  <div className="slider-container">
                    {orderDetails.Images && orderDetails.Images.length > 1 ? (
                      <Slider {...settings}>
                        {orderDetails.Images &&
                          orderDetails.Images.length > 0 &&
                          orderDetails.Images.map(
                            (image: any, index: number) => (
                              <div key={index}>
                                <img
                                  src={image.image_name}
                                  style={{ width: "100%", height: "auto" }}
                                />
                                <Typography.p style={{ textAlign: "center" }}>
                                  {image.title}
                                </Typography.p>
                              </div>
                            )
                          )}
                      </Slider>
                    ) : (
                      <>
                        {orderDetails.Images &&
                          orderDetails.Images.length > 0 &&
                          orderDetails.Images.map(
                            (image: any, index: number) => (
                              <div
                                style={{
                                  width: "300px",
                                  margin: "auto",
                                }}
                                key={index}
                              >
                                <img
                                  src={image.image_name}
                                  style={{
                                    width: "100%",
                                    height: "auto",
                                    objectFit: "contain",
                                  }}
                                  onClick={() =>
                                    handleOpenImage(image.image_name)
                                  }
                                />
                                <Typography.p style={{ textAlign: "center" }}>
                                  {image.title}
                                </Typography.p>
                              </div>
                            )
                          )}
                      </>
                    )}
                  </div>
                </GridLayoutItem>
              </GridLayout>
            </div>
          )}
        </AppDialog>
      )}
    </>
  );
};

interface SelectDialogProps {
  formRenderProps: FormRenderProps;
}
const SelectOrderDialog = ({ formRenderProps }: SelectDialogProps) => {
  // const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const dialogName = useAppSelector((state) => state.dialog?.dialogName);
  const GhatIssueOrderList = useAppSelector(
    (state) => state.ghatreceipt.GhatIssueOrderList
  );
  const loading = useAppSelector((state) => state.ghatreceipt?.loading);
  const gridRef = useRef<any>(null);
  interface PageState {
    skip: number;
    take: number;
  }

  const initialDataState: PageState = { skip: 0, take: 10 };

  const currentPage = useAppSelector((state) => state.pagination.currentPage);
  const pageLimit = useAppSelector((state) => state.pagination.pageLimit);
  const [page, setPage] = React.useState<PageState>(initialDataState);

  const [filter, setFilter] = React.useState<
    CompositeFilterDescriptor | undefined
  >(undefined);

  const pageChange = (event: GridPageChangeEvent) => {
    const newPage = event.page.skip / event.page.take;
    dispatch(setPageLimit(event.page.take));
    dispatch(setCurrentPage(newPage));
  };

  const MyImageCommandCell = (props: GridCellProps) => {
    const handlClickItem = (image: string) => {
      if (image && typeof image === "string") {
        window.open(image);
      }
    };
    if (props.dataItem?.image_name === null) {
      return (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            height: "50px",
          }}
        >
          <MdOutlineImageNotSupported style={{ fontSize: "30px" }} />
          <p style={{ margin: 0, fontSize: "12px" }}>No Image!</p>
        </div>
      );
    }
    return (
      <td className="action-td main-image">
        <div
          style={{
            position: "relative",
            border: "1px dotted lightgrey",
            borderRadius: "5px",
            overflow: "hidden",
            height: "50px",
          }}
        >
          <img
            className="product-image"
            src={props.dataItem?.image_name}
            alt=""
            style={{
              height: "100%",
              width: "100%",
              objectFit: "cover",
              borderRadius: "5px",
            }}
          />
          <div className="image-view">
            <FaRegEye
              style={{
                color: "white",
                height: 24,
                width: 24,
                cursor: "pointer",
              }}
              onClick={() => handlClickItem(props.dataItem?.image_name)}
            />
          </div>
        </div>
      </td>
    );
  };

  const MyEditCommandCell = (props: GridCellProps) => {
    const handleEditItem = (order_no: number) => {
      // console.log(order_no);
      formRenderProps.onChange("order_no", { value: order_no });
      const getOrderNowiseData = async () => {
        if (!order_no || formRenderProps.modified !== true) {
          const updateField = (field: any, value: any) =>
            formRenderProps?.onChange(field, { value });
          updateField("item_name", "");
          updateField("item_id", null);
          updateField("purity", "");
          updateField("receiver_weight", null);
          updateField("item_description", "");
          updateField("wastage", null);
          updateField("karigar_id", null);
          return;
        }

        try {
          const { payload: res, meta } = await dispatch(
            findByOrderNoGhatReceipt(order_no)
          );
          if (meta.requestStatus === "fulfilled" && res) {
            const updateField = (field: any, value: any) =>
              formRenderProps?.onChange(field, { value });
            updateField("item_name", res?.item_name);
            updateField("item_id", res?.item_id);
            updateField("purity", res?.purity);
            updateField("receiver_weight", res?.weight ? +res?.weight : "");
            updateField("remark", res?.item_description);
            updateField("wastage", +res?.wastage);
            updateField("karigar_id", res?.karigar_id);
            const { payload: response, meta } = await dispatch(
              getImageById(res?.item_id)
            );
            if (meta.requestStatus === "fulfilled" && response) {
              const Images = JSON.parse(JSON.stringify(response));
              updateField("Images", Images);
            }
          } else {
            const updateField = (field: any, value: any) =>
              formRenderProps?.onChange(field, { value });
            updateField("item_name", "");
            updateField("item_id", null);
            updateField("purity", "");
            updateField("receiver_weight", null);
            updateField("item_description", "");
            updateField("wastage", null);
            updateField("karigar_id", null);
            updateField("order_no", null);
          }
        } catch (error) {
          console.error(error);
        }
      };

      getOrderNowiseData();
      dispatch(closeDialog());
    };

    return (
      <td className="action-td">
        <Button
          type="button"
          fillMode="solid"
          themeColor={"primary"}
          size="small"
          title="Select Order"
          onClick={() => handleEditItem(props.dataItem?.order_no)}
        >
          Select
        </Button>
      </td>
    );
  };
  const handleFilterChange = (e: GridFilterChangeEvent) => {
    setFilter(e.filter);
    setPage({ skip: 0, take: page.take });
  };

  const HeaderCustomCell = (props: GridCustomHeaderCellProps) => (
    <HeaderThElement
      columnId={props.thProps?.columnId || ""}
      {...props.thProps}
      className={`table-header ${props.index === 5 ? "locked-header" : ""}`}
    >
      {props.children}
    </HeaderThElement>
  );
  const CustomCell = (props: any) => {
    return (
      <td {...props.tdProps} colSpan={1} className={"table-cell"}>
        {props.children}
      </td>
    );
  };
  const MyDataCustomCell = (props: GridCustomCellProps) => (
    <CustomCell {...props} />
  );

  return (
    <>
      {dialogName === "SelectOrderDialog" && (
        <AppDialog>
          <div style={{ width: "80vw" }}>
            {loading ? (
              <LoadingPanel gridRef={gridRef} />
            ) : (
              <GridLayout
                // style={{
                //   marginLeft: 30,
                // }}
                gap={{ rows: 10, cols: 10 }}
              >
                <GridLayoutItem
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                  }}
                >
                  <Typography.h4 style={{ margin: "0" }}>
                    {"Select Order"}
                  </Typography.h4>

                  <ImCross
                    style={{
                      color: "white",
                      background: "red",
                      borderRadius: "50%",
                      padding: "5px",
                      fontSize: "24px",
                    }}
                    onClick={() => dispatch(closeDialog())}
                  />
                </GridLayoutItem>
                <GridLayoutItem>
                  {GhatIssueOrderList && GhatIssueOrderList.length ? (
                    <KendoGrid
                      className="selection-dialog"
                      style={{ maxHeight: "calc(100vh - 152px)" }}
                      filterable={true}
                      filter={filter}
                      data={
                        filter
                          ? filterBy(GhatIssueOrderList, filter)
                          : GhatIssueOrderList
                      }
                      total={GhatIssueOrderList.length}
                      onFilterChange={handleFilterChange}
                      skip={currentPage * pageLimit}
                      take={pageLimit}
                      pageable={{
                        buttonCount: 5,
                        pageSizes: [10, 50, 100, 300, 500],
                        pageSizeValue: pageLimit,
                        type: "input",
                      }}
                      onPageChange={pageChange}
                      cells={{
                        headerCell: HeaderCustomCell,
                        data: MyDataCustomCell,
                      }}
                    >
                      <Column
                        field="SelectOrderDialog"
                        title="image_name"
                        width={100}
                        cell={MyImageCommandCell}
                        filterable={false}
                        // locked={true}
                      />
                      <Column
                        field="order_no"
                        title="Order No"
                        width={"100px"}
                        cell={(props: any) => (
                          <td>
                            <div style={{ color: "green" }}>
                              <strong>{props.dataItem?.order_no}</strong>
                            </div>
                          </td>
                        )}
                      />
                      <Column field="party_name" title="Party Name" />
                      <Column field="item_name" title="Product Name" />
                      <Column field="order_date" title="Order Date" />
                      <Column field="delivery_date" title="Delivery Date" />
                      <Column
                        field="order_no"
                        title="Actions"
                        cell={MyEditCommandCell}
                        width={"150px"}
                        filterable={false}
                        locked={true}
                      />
                    </KendoGrid>
                  ) : (
                    <AlertBox />
                  )}
                </GridLayoutItem>
                <GridLayoutItem
                  style={{
                    textAlign: "center",
                    marginTop: 20,
                  }}
                ></GridLayoutItem>
              </GridLayout>
            )}
          </div>
        </AppDialog>
      )}
    </>
  );
};
