import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  IProductsubCategory,
  IProductsubCategoryInitialState,
} from "./productsubcategoryModel";
import {
  createProductSubCategory,
  deleteProductSubCategory,
  findAllActiveProductSubCategory,
  getAllProductSubCategory,
  getProductSubCategoryById,
  ProductSubCategoryActiveInactive,
  updateProductSubCategory,
} from "./services/productsubcategory.services";

const initialState: IProductsubCategoryInitialState = {
  loading: false,
  formLoading: false,
  error: "",
  ProductSubCategoryList: [],
  ProductSubCategoryID: null,
  ProductSubCategoryDetail: {
    item_subcategory_name: "",
    item_category_id: null,
    short_code: "",
    isactive: 1,
  },
};

export const productsubcategorySlice = createSlice({
  name: "productsubcategory",
  initialState,
  reducers: {
    clearProductSubCategoryDetails: (state) => {
      state.ProductSubCategoryDetail = initialState.ProductSubCategoryDetail;
    },
    setProductSubCategoryID: (state, action) => {
      state.ProductSubCategoryID = action.payload;
    },
    clearProductSubCategoryID: (state) => {
      state.ProductSubCategoryID = null;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getAllProductSubCategory.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(
      getAllProductSubCategory.fulfilled,
      (state, action: PayloadAction<IProductsubCategory[]>) => {
        state.loading = false;
        state.ProductSubCategoryList = action.payload;
      }
    );
    builder.addCase(getAllProductSubCategory.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message || "Something went wrong";
      state.ProductSubCategoryList = [];
    });

    builder.addCase(findAllActiveProductSubCategory.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(
      findAllActiveProductSubCategory.fulfilled,
      (state, action: PayloadAction<IProductsubCategory[]>) => {
        state.loading = false;
        state.ProductSubCategoryList = action.payload || [];
      }
    );
    builder.addCase(
      findAllActiveProductSubCategory.rejected,
      (state, action) => {
        state.loading = false;
        state.error = action.error.message || "Something went wrong";
        state.ProductSubCategoryList = [];
      }
    );

    builder.addCase(createProductSubCategory.pending, (state) => {
      state.formLoading = false;
      state.error = "";
    });
    builder.addCase(createProductSubCategory.fulfilled, (state) => {
      state.formLoading = false;
    });
    builder.addCase(createProductSubCategory.rejected, (state, action) => {
      state.formLoading = false;
      state.error = action.error.message || "Something went wrong";
    });


    builder.addCase(updateProductSubCategory.pending, (state) => {
      state.formLoading = true;
      state.error = "";
    });
    builder.addCase(updateProductSubCategory.fulfilled, (state) => {
      state.formLoading = false;
    });
    builder.addCase(updateProductSubCategory.rejected, (state, action) => {
      state.formLoading = false;
      state.error = action.error.message || "Something went wrong";
    });

    builder.addCase(deleteProductSubCategory.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(deleteProductSubCategory.fulfilled, (state) => {
      state.loading = false;
    });
    builder.addCase(deleteProductSubCategory.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message || "Something went wrong";
    });

    builder.addCase(ProductSubCategoryActiveInactive.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(ProductSubCategoryActiveInactive.fulfilled, (state) => {
      state.loading = false;
    });
    builder.addCase(
      ProductSubCategoryActiveInactive.rejected,
      (state, action) => {
        state.loading = false;
        state.error = action.error.message || "Something went wrong";
      }
    );

    builder.addCase(getProductSubCategoryById.pending, (state) => {
      state.formLoading = true;
      state.error = "";
    });
    builder.addCase(
      getProductSubCategoryById.fulfilled,
      (state, action: any) => {
        state.formLoading = false;
        state.ProductSubCategoryDetail = action.payload;
      }
    );
    builder.addCase(getProductSubCategoryById.rejected, (state, action) => {
      state.formLoading = false;
      state.ProductSubCategoryDetail = initialState.ProductSubCategoryDetail;
      state.error = action.error.message || "Something went wrong";
    });
  },
});

export const {
  clearProductSubCategoryDetails,
  setProductSubCategoryID,
  clearProductSubCategoryID,
} = productsubcategorySlice.actions;
export default productsubcategorySlice.reducer;
