import React, { useEffect, useRef, useState } from "react";
import AppDialog, { DialogCloseButton } from "../../components/dialog/Dialog";
import { GridLayout, GridLayoutItem } from "@progress/kendo-react-layout";
import { Typography } from "@progress/kendo-react-common";
import { Button } from "@progress/kendo-react-buttons";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import {
  KarigarActiveInactive,
  deleteKarigar,
  getAllKarigar,
} from "./services/karigar.services";
import { closeDialog, openDialog } from "../../components/dialog/dialogSlice";
import { clearKarigarDetail, setKarigarID } from "./karigarSlice";
import { useNavigate } from "react-router-dom";
import {
  CompositeFilterDescriptor,
  filterBy,
} from "@progress/kendo-data-query";
import {
  GridCellProps,
  GridCustomCellProps,
  GridCustomHeaderCellProps,
  GridFilterChangeEvent,
  GridPageChangeEvent,
  Grid as KendoGrid,
  GridColumn as Column,
} from "@progress/kendo-react-grid";
import {
  HeaderThElement,
  PagerTargetEvent,
} from "@progress/kendo-react-data-tools";
import IconButton from "../../components/common/IconButton";
import { FiEdit } from "react-icons/fi";
import { MdDelete } from "react-icons/md";
import { Tooltip } from "@progress/kendo-react-tooltip";
import { Switch } from "@progress/kendo-react-inputs";
import { LoadingPanel } from "../../components/layout/Loading";
import AlertBox from "../../components/common/AlertBox";

const Karigar: React.FC = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(getAllKarigar());
    return () => {
      dispatch(closeDialog());
      dispatch(clearKarigarDetail());
    };
  }, []);

  const handleKarigarCreate = () => {
    navigate("/karigar/create");
  };
  return (
    <>
      <DeleteKarigarDialog />
      <GridLayout cols={[{ width: "100%" }]}>
        <GridLayoutItem
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            padding: "4px 20px 20px 0",
          }}
        >
          <Typography.h4 style={{ marginBottom: 0, marginLeft: "3px" }}>
            Karigar List
          </Typography.h4>
          <Button
            onClick={handleKarigarCreate}
            fillMode={"solid"}
            themeColor={"primary"}
          >
            Create Karigar
          </Button>
        </GridLayoutItem>
        <GridLayoutItem>
          <KarigarGridComponent />
        </GridLayoutItem>
      </GridLayout>
    </>
  );
};

const DeleteKarigarDialog: React.FC = () => {
  const dispatch = useAppDispatch();
  const KarigarID = useAppSelector((state) => state.karigar.KarigarID);
  const dialogName = useAppSelector((state) => state.dialog.dialogName);

  const handleDeleteAction = async (ID: number | null) => {
    if (ID) {
      const response = await dispatch(deleteKarigar(ID));
      if (response.payload?.Data.Status === 200) {
        dispatch(getAllKarigar());
        dispatch(closeDialog());
        dispatch(clearKarigarDetail());
      } else {
        dispatch(getAllKarigar());
        dispatch(closeDialog());
        dispatch(clearKarigarDetail());
      }
    }
  };

  return (
    <>
      {dialogName === "deleteKarigar" && (
        <AppDialog>
          <>
            <Typography.h5>{"Delete Karigar?"}</Typography.h5>
            <GridLayout>
              <GridLayoutItem>
                <Typography.p>
                  Are you sure you want to delete this Karigar?
                </Typography.p>
              </GridLayoutItem>
              <GridLayoutItem
                style={{
                  display: "flex",
                  justifyContent: "end",
                }}
              >
                <DialogCloseButton themeColor="error" />
                <Button
                  fillMode={"solid"}
                  themeColor={"error"}
                  onClick={() => {
                    handleDeleteAction(KarigarID);
                  }}
                  type="button"
                >
                  Delete
                </Button>
              </GridLayoutItem>
            </GridLayout>
          </>
        </AppDialog>
      )}
    </>
  );
};

interface PageState {
  skip: number;
  take: number;
}

const initialDataState: PageState = { skip: 0, take: 10 };

const KarigarGridComponent: React.FC = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const KarigarList = useAppSelector((state) => state.karigar.KarigarList);
  const loading = useAppSelector((state) => state.karigar.loading);
  const [uiLoading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    setLoading(loading);
  }, [loading]);

  const [filter, setFilter] = React.useState<
    CompositeFilterDescriptor | undefined
  >(undefined);
  const [page, setPage] = React.useState<PageState>(initialDataState);
  const [pageSizeValue, setPageSizeValue] = React.useState<
    number | string | undefined
  >();
  const pageChange = (event: GridPageChangeEvent) => {
    const targetEvent = event.targetEvent as PagerTargetEvent;
    const take =
      targetEvent.value === "All" ? KarigarList.length : event.page.take;

    if (targetEvent.value) {
      setPageSizeValue(targetEvent.value);
    }
    setPage({
      ...event.page,
      take,
    });
  };

  const MyEditCommandCell = (props: GridCellProps) => {
    const handleEditItem = (ID: number) => {
      navigate("/karigar/edit", {
        state: { karigar_guid:ID },
      });
    };

    const handleOpenDeleteDialog = (ID: number) => {
      dispatch(openDialog("deleteKarigar"));
      dispatch(setKarigarID(ID));
    };

    return (
      <td className="action-td">
        <IconButton
          type="button"
          fillMode="flat"
          size="small"
          title="Edit"
          onClick={() => handleEditItem(props?.dataItem?.karigar_guid)}
        >
          <FiEdit className="absolute-position" style={{ fontSize: "20px" }} />
        </IconButton>
        <IconButton
          type="button"
          fillMode="flat"
          size="small"
          title="Delete"
          onClick={() => handleOpenDeleteDialog(props?.dataItem?.karigar_guid)}
        >
          <MdDelete
            className="absolute-position"
            style={{ fontSize: "24px" }}
          />
        </IconButton>
      </td>
    );
  };
  const handleFilterChange = (e: GridFilterChangeEvent) => {
    setFilter(e.filter);
    setPage({ skip: 0, take: page.take });
  };

  const HeaderCustomCell = (props: GridCustomHeaderCellProps) => (
    <HeaderThElement
      columnId={props.thProps?.columnId || ""}
      {...props.thProps}
      className={`table-header ${props.index === 5 ? "locked-header" : ""}`}
    >
      {props.children}
    </HeaderThElement>
  );
  const CustomCell = (props: any) => {
    return (
      <td {...props.tdProps} colSpan={1} className={"table-cell"}>
        {props.children}
      </td>
    );
  };
  const MyDataCustomCell = (props: GridCustomCellProps) => (
    <CustomCell {...props} />
  );
  const MyActiveInActtiveCommandCell = (props: GridCellProps) => {
    const [IsActive, setIsActive] = useState<boolean>(props.dataItem.isactive);

    const handleActiveInActive = () => {
      setIsActive(!IsActive);
      const data = {
        karigar_guid: props?.dataItem?.karigar_guid,
        IsActive: !IsActive,
      };
      dispatch(KarigarActiveInactive(data)).then((response: any) => {
        if (response.payload?.data?.Status === 200) {
          dispatch(getAllKarigar());
        } else {
          dispatch(getAllKarigar());
        }
      });
    };

    return (
      <td style={{ textAlign: "center" }}>
        <Tooltip anchorElement="target" position="top" parentTitle={true}>
          <div title={`Set ${IsActive ? "Inactive" : "Active"}.`}>
            <Switch
              name="IsActive"
              checked={IsActive}
              onChange={handleActiveInActive}
              size="small"
            />
          </div>
        </Tooltip>
      </td>
    );
  };

  const gridRef = useRef<any>(null);
  if (uiLoading) return <LoadingPanel gridRef={gridRef} />;

  return (
    <>
      {KarigarList && KarigarList.length ? (
        <KendoGrid
          style={{ maxHeight: "calc(100vh - 152px)" }}
          filterable={true}
          filter={filter}
          data={
            filter
              ? filterBy(KarigarList, filter).slice(
                  page.skip,
                  page.take + page.skip
                )
              : KarigarList.slice(page.skip, page.take + page.skip)
          }
          onFilterChange={handleFilterChange}
          skip={page.skip}
          take={page.take}
          total={KarigarList.length}
          pageable={{
            buttonCount: 5,
            pageSizes: [5, 10, 15, "All"],
            pageSizeValue: pageSizeValue,
            type: "input",
          }}
          onPageChange={pageChange}
          cells={{
            headerCell: HeaderCustomCell,
            data: MyDataCustomCell,
          }}
        >
          <Column
            field="karigar_name"
            title="Karigar Name"
            cell={(props: any) => (
              <td>
                <div
                  className="hoverableName"
                  onClick={() =>
                    navigate("/karigar/edit", {
                      state: { karigar_guid: props?.dataItem?.karigar_guid },
                    })
                  }
                >
                  {props?.dataItem?.karigar_name}
                </div>
              </td>
            )}
          />
          <Column field="karigar_nick_name" title="Karigar Nick Name" />
          <Column field="karigar_code" title="Karigar Code" />
          <Column field="process_name" title="Process Name" />
          <Column field="mobile" title="Mobile No" />
          <Column field="email" title="Email" />
          <Column field="wastage" title="Wastage(%)" />
          <Column
            field="karigar_guid"
            title="Status"
            cell={MyActiveInActtiveCommandCell}
            width={"90px"}
            filterable={false}
            locked={true}
          />
          <Column
            field="karigar_guid"
            title="Actions"
            cell={MyEditCommandCell}
            width={"110px"}
            filterable={false}
            locked={true}
          />
        </KendoGrid>
      ) : (
        <AlertBox />
      )}
    </>
  );
};

export default Karigar;
