import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { IGhat, IGhatInitialState } from "./ghatModel";
import {
  createGhat,
  deleteGhadiaIssueItems,
  deleteGhat,
  deleteGhatItems,
  findAllGhat,
  getGhatById,
  updateGhat,
} from "./services/ghat.services";

const initialState: IGhatInitialState = {
  loading: false,
  formLoading: false,
  error: "",
  GhatList: [],
  GhatID: null,
  orderItemIndex: null,
  GhatDetail: {
    issue_date: "",
    karigar_id: null,
    approx_delivery_date: "",
    weight_total: null,
    phine_total: null,
    amount_total: null,
    remarks: "",
    ghat_items: [
      {
        order_no: null,
        item_id: null,
        item_description: "",
        item_category_id: null,
        weight: null,
        purity: null,
      },
    ],
    ghat_item_issue: [
      {
        given_weight: null,
        given_purity: 100,
        phine: null,
        amount: null,
      },
    ],
    isactive: 0,
  },
  GhatItemsDeleteIndex: -1,
  GhatItemsIssueDeleteIndex: -1,
};

const orderSlice = createSlice({
  name: "ghat",
  initialState,
  reducers: {
    clearGhatDetail: (state) => {
      state.GhatDetail = initialState.GhatDetail;
    },
    setGhatID: (state, action) => {
      state.GhatID = action.payload;
    },
    clearGhatID: (state) => {
      state.GhatID = null;
    },
    setOrderItemDetailsIndex: (state, action) => {
      state.orderItemIndex = action.payload;
    },
    setGhatItemDeleteIndex: (state, action) => {
      state.GhatItemsDeleteIndex = action.payload;
    },
    setGhatItemIssueDeleteIndex: (state, action) => {
      state.GhatItemsIssueDeleteIndex = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(findAllGhat.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(
      findAllGhat.fulfilled,
      (state, action: PayloadAction<IGhat[]>) => {
        state.loading = false;
        state.GhatList = action.payload || [];
      }
    );
    builder.addCase(findAllGhat.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message || "Something went wrong";
      state.GhatList = [];
    });

    builder.addCase(createGhat.pending, (state) => {
      state.formLoading = false;
      state.error = "";
    });
    builder.addCase(createGhat.fulfilled, (state) => {
      state.formLoading = false;
    });
    builder.addCase(createGhat.rejected, (state, action) => {
      state.formLoading = false;
      state.error = action.error.message || "Something went wrong";
    });

    builder.addCase(updateGhat.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(updateGhat.fulfilled, (state) => {
      state.loading = false;
    });
    builder.addCase(updateGhat.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message || "Something went wrong";
    });

    builder.addCase(deleteGhat.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(deleteGhat.fulfilled, (state) => {
      state.loading = false;
    });
    builder.addCase(deleteGhat.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message || "Something went wrong";
    });
    builder.addCase(getGhatById.pending, (state) => {
      state.formLoading = true;
      state.error = "";
    });
    builder.addCase(getGhatById.fulfilled, (state, action: any) => {
      state.formLoading = false;
      state.GhatDetail = action.payload;
    });
    builder.addCase(getGhatById.rejected, (state, action) => {
      state.formLoading = false;
      state.GhatDetail = initialState.GhatDetail;
      state.error = action.error.message || "Something went wrong";
    });
    builder.addCase(deleteGhatItems.pending, (state) => {
      state.loading = false;
      state.error = "";
    });
    builder.addCase(deleteGhatItems.fulfilled, (state) => {
      state.loading = false;
    });
    builder.addCase(deleteGhatItems.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message || "Something went wrong";
    });
    builder.addCase(deleteGhadiaIssueItems.pending, (state) => {
      state.loading = false;
      state.error = "";
    });
    builder.addCase(deleteGhadiaIssueItems.fulfilled, (state) => {
      state.loading = false;
    });
    builder.addCase(deleteGhadiaIssueItems.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message || "Something went wrong";
    });
  },
});

export const {
  clearGhatDetail,
  clearGhatID,
  setGhatID,
  setGhatItemDeleteIndex,
  setGhatItemIssueDeleteIndex,
  setOrderItemDetailsIndex,
} = orderSlice.actions;
export default orderSlice.reducer;
