import {
  Field,
  FieldArray,
  Form,
  FormElement,
  FormRenderProps,
} from "@progress/kendo-react-form";
import React, { useEffect, useRef, useState } from "react";
import ShadowCard from "../../components/common/ShadowCard";
import { GridLayout, GridLayoutItem } from "@progress/kendo-react-layout";
import { Typography } from "@progress/kendo-react-common";
import FormTextField from "../../components/formFields/FormTextField";
import {
  mobileNoValidator,
  panWOValueValidator,
  requiredValidator,
} from "../../components/formFields/CommonValidator";
import FormSelectionField from "../../components/formFields/FormSelectionField";
import { Button } from "@progress/kendo-react-buttons";
import ButtonWithLoading from "../../components/common/ButtonWithLoading";
import { useLocation, useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { RootState } from "../../app/store";
import {
  createKarigar,
  findAllActiveKarigar,
  getKarigarById,
  updateKarigar,
} from "./services/karigar.services";
import { clearKarigarDetail } from "./karigarSlice";
import { LoadingPanel } from "../../components/layout/Loading";
import { CREDIT_DEBIT, STATUSARRAY } from "../../_contstants/common";
import { findAllActiveProcess } from "../process/services/process.services";
import FormRadioGroup from "../../components/formFields/FormRadioGroup";
import KarigarImageArray from "./KarigarImageArray";
import FormRichTextField from "../../components/formFields/FormRichTextField";

const CreateKarigar: React.FC = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const location = useLocation();
  const KarigarDetail = useAppSelector(
    (state: RootState) => state.karigar.KarigarDetail
  );
  const formLoading = useAppSelector((state) => state?.karigar?.formLoading);
  const loading = useAppSelector((state) => state?.karigar?.loading);
  const ProcessList = useAppSelector((state) => state?.Process?.ProcessList);
  const [formKey, setFormKey] = useState(1);
  const karigar_guid = location?.state?.karigar_guid;
  const gridRef = useRef<any>(null);

  useEffect(() => {
    if (karigar_guid) {
      dispatch(getKarigarById(karigar_guid));
    }
  }, [karigar_guid]);

  useEffect(() => {
    dispatch(findAllActiveKarigar());
    dispatch(findAllActiveProcess());
    return () => {
      dispatch(clearKarigarDetail());
    };
  }, []);

  const handleSubmit = async (values: any) => {
    try {
      if (!karigar_guid) {
        const insertPayload = {
          karigar_name: values.karigar_name,
          karigar_nick_name: values.karigar_nick_name,
          mobile: values.mobile,
          address: values.address,
          email: values.email,
          pan_no: values.pan_no,
          opening_balance: values.opening_balance
            ? +values.opening_balance
            : null,
          opening_balance_credit_debit: values.opening_balance_credit_debit,
          opening_gold_stock: values.opening_gold_stock
            ? +values.opening_gold_stock
            : null,
          opening_gold_stock_credit_debit:
            values.opening_gold_stock_credit_debit,
          process_id: values.process_id,
          provide_bank_details: values.provide_bank_details,
          bank_account_name: values.bank_account_name,
          account_no: values.account_no,
          bank: values.bank,
          branch: values.branch,
          ifsc: values.ifsc,
          remarks: values.remarks,
          wastage: values.wastage ? +values.wastage : null,
          isactive: values.isactive === 1,
        };
        const response = await dispatch(createKarigar(insertPayload));
        if (response?.meta?.requestStatus === "fulfilled") {
          navigate("/karigar/edit", {
            state: {
              karigar_guid: response?.payload?.karigar_guid,
            },
          });
        }
      } else {
        const editPayload = {
          id: values.id,
          karigar_guid: values.karigar_guid,
          karigar_code: values.karigar_code,
          karigar_name: values.karigar_name,
          karigar_nick_name: values.karigar_nick_name,
          mobile: values.mobile,
          address: values.address,
          email: values.email,
          pan_no: values.pan_no,
          opening_balance: values.opening_balance
            ? +values.opening_balance
            : null,
          opening_balance_credit_debit: values.opening_balance_credit_debit,
          opening_gold_stock: values.opening_gold_stock,
          opening_gold_stock_credit_debit:
            values.opening_gold_stock_credit_debit,
          process_id: values.process_id,
          provide_bank_details: values.provide_bank_details,
          bank_account_name: values.bank_account_name,
          account_no: values.account_no,
          bank: values.bank,
          branch: values.branch,
          ifsc: values.ifsc,
          remarks: values.remarks,
          wastage: values.wastage,
          isactive: values.isactive === 1,
        };
        const response = await dispatch(updateKarigar(editPayload));
        if (response?.meta?.requestStatus === "fulfilled") {
          dispatch(getKarigarById(karigar_guid));
        }
      }
    } catch (error) {
      console.error("Error in handleSubmit:", error);
      throw error;
    }
  };
  const handleFormClose = () => {
    navigate("/karigar");
  };

  const handleBack = () => {
    navigate("/karigar");
  };

  const PROVIDE_BANK_DETAILS = [
    { label: "Yes", value: true },
    { label: "No", value: false },
  ];
  useEffect(() => {
    setFormKey(formKey + 1);
  }, [KarigarDetail]);

  if (loading) return <LoadingPanel gridRef={gridRef} />;
  return (
    <>
      <Form
        onSubmit={handleSubmit}
        initialValues={KarigarDetail}
        key={formKey}
        render={(formRenderProps: FormRenderProps) => (
          <FormElement>
            <ShadowCard style={{ padding: 12 }}>
              <GridLayout
                style={{ marginRight: 20 }}
                gap={{ rows: 0, cols: 10 }}
                cols={[
                  { width: "33.33%" },
                  { width: "33.33%" },
                  { width: "33.33%" },
                ]}
              >
                <GridLayoutItem colSpan={3}>
                  <Typography.h4>
                    {karigar_guid ? "Update Karigar" : "Create Karigar"}
                  </Typography.h4>
                </GridLayoutItem>
                <GridLayoutItem colSpan={3}>
                  {karigar_guid ? (
                    <Typography.h5>
                      Karigar Code :{" "}
                      <span style={{ color: "green" }}>
                        {KarigarDetail.karigar_code}
                      </span>
                    </Typography.h5>
                  ) : (
                    ""
                  )}
                </GridLayoutItem>
                <GridLayoutItem>
                  <Field
                    name="karigar_name"
                    label="Karigar Name"
                    placeholder={"Karigar Name"}
                    component={FormTextField}
                    validator={requiredValidator}
                  />
                </GridLayoutItem>
                <GridLayoutItem>
                  <Field
                    name="karigar_nick_name"
                    label="Karigar Nick Name"
                    placeholder="Karigar Nick Name"
                    component={FormTextField}
                    validator={requiredValidator}
                  />
                </GridLayoutItem>
                <GridLayoutItem>
                  <Field
                    name="mobile"
                    type={"number"}
                    max={10}
                    min={10}
                    label="Mobile"
                    placeholder="91xxxxxxx"
                    component={FormTextField}
                    validator={mobileNoValidator}
                  />
                </GridLayoutItem>
                <GridLayoutItem>
                  <Field
                    name="email"
                    label="Email"
                    component={FormTextField}
                    placeholder="abc@gmail.com"
                  />
                </GridLayoutItem>
                <GridLayoutItem>
                  <Field
                    name="address"
                    label="Address"
                    placeholder="Address"
                    component={FormTextField}
                    validator={requiredValidator}
                  />
                </GridLayoutItem>
                <GridLayoutItem>
                  <Field
                    name="pan_no"
                    label="PAN No"
                    placeholder="PAN No"
                    component={FormTextField}
                    validator={panWOValueValidator}
                  />
                </GridLayoutItem>
                <GridLayoutItem>
                  <Field
                    name="process_id"
                    label="Process Name"
                    placeholder="Process Name"
                    component={FormSelectionField}
                    validator={requiredValidator}
                    options={ProcessList?.map((process: any) => {
                      return {
                        value: process.ID,
                        label: process.process_name,
                      };
                    })}
                  />
                </GridLayoutItem>
                <GridLayoutItem>
                  <Field
                    name="wastage"
                    label="Wastage"
                    placeholder="Wastage"
                    type="number"
                    // min={0}
                    defaultValue={0}
                    component={FormTextField}
                  />
                </GridLayoutItem>
                <GridLayoutItem
                  style={{ width: "100%", display: "flex", gap: 10 }}
                >
                  <Field
                    wrapperClassName="w-100"
                    name="opening_balance"
                    type="number"
                    // min={0}
                    placeholder="Phine Opening Balance"
                    label="Phine Opening Balance"
                    component={FormTextField}
                    validator={requiredValidator}
                  />
                  <Field
                    wrapperClassName="w-100"
                    name="opening_balance_credit_debit"
                    label="Cr/Dr"
                    defaultValue="Credit"
                    component={FormSelectionField}
                    options={CREDIT_DEBIT?.map((cr_dr: any) => {
                      return {
                        value: cr_dr.value,
                        label: cr_dr.label,
                      };
                    })}
                  />
                </GridLayoutItem>
                <GridLayoutItem
                  style={{ width: "100%", display: "flex", gap: 10 }}
                >
                  <Field
                    wrapperClassName="w-100"
                    name="opening_gold_stock"
                    type="number"
                    label="Rs.Opening Balance"
                    // min={0}
                    placeholder="Rs.Opening Balance"
                    component={FormTextField}
                    validator={requiredValidator}
                  />
                  <Field
                    wrapperClassName="w-100"
                    name="opening_gold_stock_credit_debit"
                    label="Cr/Dr"
                    defaultValue="Credit"
                    component={FormSelectionField}
                    options={CREDIT_DEBIT?.map((cr_dr: any) => {
                      return {
                        value: cr_dr.value,
                        label: cr_dr.label,
                      };
                    })}
                  />
                </GridLayoutItem>
                <GridLayoutItem colSpan={3}>
                  <Field
                    label="Provide Bank Details"
                    component={FormRadioGroup}
                    name={`provide_bank_details`}
                    layout="horizontal"
                    options={PROVIDE_BANK_DETAILS}
                    
                  />
                </GridLayoutItem>
                {formRenderProps.valueGetter("provide_bank_details") ===
                  true && (
                  <>
                    <GridLayoutItem>
                      <Field
                        name="bank_account_name"
                        label="Bank Account Name"
                        placeholder="Bank Account Name"
                        component={FormTextField}
                      />
                    </GridLayoutItem>
                    <GridLayoutItem>
                      <Field
                        name="bank"
                        label="Bank Name"
                        placeholder="Bank Name"
                        component={FormTextField}
                      />
                    </GridLayoutItem>
                    <GridLayoutItem>
                      <Field
                        name="account_no"
                        label="Account No"
                        placeholder="Account No"
                        component={FormTextField}
                      />
                    </GridLayoutItem>
                    <GridLayoutItem>
                      <Field
                        wrapperClassName="w-100"
                        name="branch"
                        label="Branch"
                        placeholder="Branch"
                        component={FormTextField}
                      />
                    </GridLayoutItem>
                    <GridLayoutItem>
                      <Field
                        wrapperClassName="w-100"
                        name="ifsc"
                        label="IFSC"
                        placeholder="IFSC"
                        component={FormTextField}
                      />
                    </GridLayoutItem>
                  </>
                )}
                <GridLayoutItem>
                  <Field
                    id={"IsActive"}
                    name={"isactive"}
                    label={"Status"}
                    component={FormSelectionField}
                    options={STATUSARRAY?.map((status: any) => {
                      return {
                        value: status.value,
                        label: status.label,
                      };
                    })}
                  />
                </GridLayoutItem>
                <GridLayoutItem colSpan={2}>
                  <Field
                    name="remarks"
                    label="Remarks"
                    placeholder="Remarks"
                    component={FormRichTextField}
                  />
                </GridLayoutItem>
                <GridLayoutItem
                  colSpan={3}
                  style={{
                    textAlign: "end",
                    marginTop: "20px",
                    width: "100%",
                  }}
                >
                  <Button
                    type="button"
                    themeColor={"primary"}
                    style={{ marginRight: 4 }}
                    onClick={handleBack}
                  >
                    Go to Karigar List
                  </Button>
                  <Button
                    type="button"
                    fillMode={"outline"}
                    themeColor={"primary"}
                    style={{ marginRight: 4 }}
                    onClick={handleFormClose}
                  >
                    Cancel
                  </Button>
                  <ButtonWithLoading
                    label={!karigar_guid ? "Create" : "Update"}
                    type="submit"
                    disabled={!formRenderProps.allowSubmit || loading}
                    loading={loading}
                  />
                </GridLayoutItem>
              </GridLayout>
            </ShadowCard>
            {formLoading ? (
              <LoadingPanel gridRef={gridRef} />
            ) : (
              karigar_guid && (
                <ShadowCard style={{ marginTop: 10 }}>
                  <GridLayoutItem colSpan={6}>
                    <Typography.h4>Documents Upload</Typography.h4>
                  </GridLayoutItem>
                  <FieldArray
                    formRenderProps={formRenderProps}
                    component={KarigarImageArray}
                    name="karigar_images"
                  />
                </ShadowCard>
              )
            )}
          </FormElement>
        )}
      />
    </>
  );
};

export default CreateKarigar;
