import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IProcess, IProcessInitialState } from "./processModel";
import {
  createProcess,
  deleteProcess,
  findAllActiveProcess,
  getAllProcess,
  getProcessById,
  ProcessActiveInactive,
  updateProcess,
} from "./services/process.services";

const initialState: IProcessInitialState = {
  loading: false,
  formLoading: false,
  error: "",
  ProcessList: [],
  ProcessID: null,
  ProcessDetail: {
    process_guid: null,
    process_sr_number: null,
    process_name: "",
    isactive: 1,
  },
};

export const processSlice = createSlice({
  name: "process",
  initialState,
  reducers: {
    clearProcessDetails: (state) => {
      state.ProcessDetail = initialState.ProcessDetail;
    },
    setProcessID: (state, action) => {
      state.ProcessID = action.payload;
    },
    clearProcessID: (state) => {
      state.ProcessID = null;
    },
  },
  extraReducers: (builder) => {
    /* For getAll ItemProcess */

    builder.addCase(getAllProcess.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(
      getAllProcess.fulfilled,
      (state, action: PayloadAction<IProcess[]>) => {
        state.loading = false;
        state.ProcessList = action.payload;
      }
    );
    builder.addCase(getAllProcess.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message || "Something went wrong";
      state.ProcessList = [];
    });

    /* For FindAllActiveItemProcess */

    builder.addCase(findAllActiveProcess.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(
      findAllActiveProcess.fulfilled,
      (state, action: PayloadAction<IProcess[]>) => {
        state.loading = false;
        state.ProcessList = action.payload || [];
      }
    );
    builder.addCase(findAllActiveProcess.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message || "Something went wrong";
      state.ProcessList = [];
    });

    /* For createItemProcess */

    builder.addCase(createProcess.pending, (state) => {
      state.formLoading = false;
      state.error = "";
    });
    builder.addCase(createProcess.fulfilled, (state) => {
      state.formLoading = false;
    });
    builder.addCase(createProcess.rejected, (state, action) => {
      state.formLoading = false;
      state.error = action.error.message || "Something went wrong";
    });

    /* For updateItemProcess */

    builder.addCase(updateProcess.pending, (state) => {
      state.formLoading = true;
      state.error = "";
    });
    builder.addCase(updateProcess.fulfilled, (state) => {
      state.formLoading = false;
    });
    builder.addCase(updateProcess.rejected, (state, action) => {
      state.formLoading = false;
      state.error = action.error.message || "Something went wrong";
    });

    /* For DeleteItemProcess */

    builder.addCase(deleteProcess.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(deleteProcess.fulfilled, (state) => {
      state.loading = false;
    });
    builder.addCase(deleteProcess.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message || "Something went wrong";
    });

    /* For ActiveInactiveItemProcess */

    builder.addCase(ProcessActiveInactive.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(ProcessActiveInactive.fulfilled, (state) => {
      state.loading = false;
    });
    builder.addCase(ProcessActiveInactive.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message || "Something went wrong";
    });

    /* For ItemProcessById */

    builder.addCase(getProcessById.pending, (state) => {
      state.formLoading = true;
      state.error = "";
    });
    builder.addCase(getProcessById.fulfilled, (state, action: any) => {
      state.formLoading = false;
      state.ProcessDetail = action.payload;
    });
    builder.addCase(getProcessById.rejected, (state, action) => {
      state.formLoading = false;
      state.ProcessDetail = initialState.ProcessDetail;
      state.error = action.error.message || "Something went wrong";
    });
  },
});

export const { clearProcessDetails, setProcessID, clearProcessID } =
  processSlice.actions;
export default processSlice.reducer;
