import React, { useEffect, useRef, useState } from "react";
import { LoadingPanel } from "../../components/layout/Loading";
import { useLocation, useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { RootState } from "../../app/store";
import {
  createParty,
  findAllActiveParty,
  getPartyById,
  updateParty,
} from "./services/party.services";
import { clearPartyDetail } from "./partySlice";
// import { clearItemDetail } from "../item/itemSlice";
import {
  Field,
  FieldArray,
  Form,
  FormElement,
  FormRenderProps,
} from "@progress/kendo-react-form";
import ShadowCard from "../../components/common/ShadowCard";
import { GridLayout, GridLayoutItem } from "@progress/kendo-react-layout";
import { Typography } from "@progress/kendo-react-common";
import FormTextField from "../../components/formFields/FormTextField";
import {
  emailValidator,
  gstWOValueValidator,
  mobileNoValidator,
  panWOValueValidator,
  requiredValidator,
} from "../../components/formFields/CommonValidator";
import FormSelectionField from "../../components/formFields/FormSelectionField";
import { CREDIT_DEBIT, STATUSARRAY } from "../../_contstants/common";
import ButtonWithLoading from "../../components/common/ButtonWithLoading";
import { Button } from "@progress/kendo-react-buttons";
import FormRadioGroup from "../../components/formFields/FormRadioGroup";
import ContactDetailsArray from "./ContactDetailsArray";
import PartyImageArray from "./PartyImageArray";
import FormRichTextField from "../../components/formFields/FormRichTextField";
import ChargesDetailArray from "./ChargesDetailArray";

const CreateParty: React.FC = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const location = useLocation();
  const PartyDetail = useAppSelector(
    (state: RootState) => state.party.PartyDetail
  );
  const loading = useAppSelector((state) => state.party.loading);
  const formLoading = useAppSelector((state) => state.party.formLoading);
  const [formKey, setFormKey] = useState(1);

  const party_guid = location.state?.party_guid;
  const gridRef = useRef<any>(null);

  const PROVIDE_BANK_DETAILS = [
    { label: "Yes", value: true },
    { label: "No", value: false },
  ];

  useEffect(() => {
    if (party_guid) {
      dispatch(getPartyById(party_guid));
    }
  }, [party_guid]);

  useEffect(() => {
    dispatch(findAllActiveParty());
    return () => {
      dispatch(clearPartyDetail());
    };
  }, []);

  const handleSubmit = async (values: any) => {
    try {
      if (!party_guid) {
        const insertpayload = {
          party_name: values.party_name,
          party_nick_name: values.party_nick_name,
          mobile_no: values.mobile_no,
          address: values.address,
          email: values.email,
          pan_no: values.pan_no,
          gst_no: values.gst_no,
          opening_balance: values.opening_balance,
          credit_debit: values.credit_debit,
          rs_opening_balance: values.rs_opening_balance,
          rs_credit_debit: values.rs_credit_debit,
          provide_bank_details: values.provide_bank_details,
          bank_account_name: values.bank_account_name,
          ifsc_code: values.ifsc_code,
          bank_name: values.bank_name,
          branch: values.branch,
          account_no: values.account_no,
          remarks: values.remarks,
          contact_details: values.contact_details
            ? values.contact_details.map((contact_detail: any) => {
                return {
                  party_id: contact_detail.party_id,
                  person_name: contact_detail.person_name,
                  country_code: contact_detail.country_code,
                  mobile_no: contact_detail.mobile_no,
                  email: contact_detail.email,
                  department: contact_detail.department,
                };
              })
            : [],
          charges_details: values.charges_details
            ? values.charges_details.map((chargesDetail: any) => {
                return {
                  // party_id: chargesDetail.party_id ? +chargesDetail.party_id : null,
                  charge_id: chargesDetail.charge_id
                    ? +chargesDetail.charge_id
                    : null,
                  unit_id: chargesDetail.unit_id
                    ? +chargesDetail.unit_id
                    : null,
                  charges_type: chargesDetail.charges_type
                    ? +chargesDetail.charges_type
                    : null,
                  charges: chargesDetail.charges,
                };
              })
            : [],
          isactive: values.isactive === 1,
        };
        const response = await dispatch(createParty(insertpayload));
        if (response?.meta?.requestStatus === "fulfilled") {
          navigate("/party/edit", {
            state: {
              party_guid: response?.payload?.party_guid,
            },
          });
        }
      } else {
        const editpayload = {
          party_guid: party_guid,
          id: values.id,
          party_name: values.party_name,
          party_nick_name: values.party_nick_name,
          mobile_no: values.mobile_no,
          address: values.address,
          email: values.email,
          pan_no: values.pan_no,
          gst_no: values.gst_no,
          opening_balance: values.opening_balance,
          credit_debit: values.credit_debit,
          rs_opening_balance: values.rs_opening_balance,
          rs_credit_debit: values.rs_credit_debit,
          provide_bank_details: values.provide_bank_details,
          bank_account_name: values.bank_account_name,
          ifsc_code: values.ifsc_code,
          bank_name: values.bank_name,
          branch: values.branch,
          account_no: values.account_no,
          remarks: values.remarks,
          contact_details: values.contact_details
            ? values.contact_details.map((contact_detail: any) => {
                return {
                  party_id: contact_detail.party_id,
                  person_name: contact_detail.person_name,
                  country_code: contact_detail.country_code,
                  mobile_no: contact_detail.mobile_no,
                  email: contact_detail.email,
                  department: contact_detail.department,
                };
              })
            : [],
          charges_details: values.charges_details
            ? values.charges_details.map((chargesDetail: any) => {
                return {
                  party_id: chargesDetail.party_id,
                  charge_id: chargesDetail.charge_id,
                  unit_id: chargesDetail.unit_id,
                  charges_type: chargesDetail.charges_type,
                  charges: chargesDetail.charges,
                };
              })
            : [],
          isactive: values.isactive === 1,
        };
        const response = await dispatch(updateParty(editpayload));
        if (response?.meta?.requestStatus === "fulfilled") {
          dispatch(getPartyById(party_guid));
        }
        navigate("/party");
      }
    } catch (error) {
      console.error("Error in handleSubmit:", error);
      throw error;
    }
  };
  const handleFormClose = () => {
    navigate("/party");
  };
  const handleBack = () => {
    navigate("/party");
  };
  useEffect(() => {
    setFormKey(formKey + 1);
  }, [PartyDetail]);

  if (loading) return <LoadingPanel gridRef={gridRef} />;
  return (
    <>
      <Form
        onSubmit={handleSubmit}
        initialValues={PartyDetail}
        key={formKey}
        render={(formRenderProps: FormRenderProps) => (
          <FormElement>
            <ShadowCard style={{ padding: 12 }}>
              <GridLayout
                style={{ marginRight: 20 }}
                gap={{ rows: 0, cols: 10 }}
                cols={[
                  { width: "33.33%" },
                  { width: "33.33%" },
                  { width: "33.33%" },
                ]}
              >
                <GridLayoutItem colSpan={3}>
                  <Typography.h4>
                    {party_guid ? "Update Vepari" : "Create Vepari"}
                  </Typography.h4>
                </GridLayoutItem>
                <GridLayoutItem colSpan={3}>
                  {party_guid ? (
                    <Typography.h5>
                      Vepari No :{" "}
                      <span style={{ color: "green" }}>{PartyDetail.id}</span>
                    </Typography.h5>
                  ) : (
                    ""
                  )}
                </GridLayoutItem>
                <GridLayoutItem>
                  <Field
                    name="party_name"
                    label="Vepari Name"
                    placeholder="Vepari Name"
                    component={FormTextField}
                    validator={requiredValidator}
                  />
                </GridLayoutItem>
                <GridLayoutItem>
                  <Field
                    name="party_nick_name"
                    label="Vepari Nick Name"
                    placeholder="Vepari Nick Name"
                    component={FormTextField}
                    validator={requiredValidator}
                  />
                </GridLayoutItem>
                <GridLayoutItem>
                  <Field
                    name="mobile_no"
                    type={"number"}
                    placeholder="Phone No"
                    max={10}
                    label="Phone No"
                    component={FormTextField}
                    validator={mobileNoValidator}
                  />
                </GridLayoutItem>
                <GridLayoutItem>
                  <Field
                    name="address"
                    label="Address"
                    placeholder="Address"
                    component={FormTextField}
                  />
                </GridLayoutItem>
                <GridLayoutItem>
                  <Field
                    name="email"
                    label="Email"
                    placeholder="Email"
                    component={FormTextField}
                    validator={emailValidator}
                  />
                </GridLayoutItem>
                <GridLayoutItem>
                  <Field
                    name="pan_no"
                    label="PAN No"
                    placeholder="PAN No"
                    component={FormTextField}
                    validator={panWOValueValidator}
                  />
                </GridLayoutItem>
                <GridLayoutItem>
                  <Field
                    name="gst_no"
                    label="GST No"
                    placeholder="GST No"
                    component={FormTextField}
                    validator={gstWOValueValidator}
                  />
                </GridLayoutItem>
                <GridLayoutItem
                  style={{ width: "100%", display: "flex", gap: 10 }}
                >
                  <Field
                    wrapperClassName="w-100"
                    name="opening_balance"
                    label="Phine Opening Balance"
                    type={"number"}
                    placeholder="000.00"
                    // min={0}
                    component={FormTextField}
                    validator={requiredValidator}
                  />
                  <Field
                    wrapperClassName="w-90"
                    name="credit_debit"
                    label="Cr/Dr"
                    defaultValue="Credit"
                    component={FormSelectionField}
                    options={CREDIT_DEBIT?.map((cr_dr: any) => {
                      return {
                        value: cr_dr.value,
                        label: cr_dr.label,
                      };
                    })}
                  />
                </GridLayoutItem>
                <GridLayoutItem
                  style={{ width: "100%", display: "flex", gap: 10 }}
                >
                  <Field
                    wrapperClassName="w-100"
                    name="rs_opening_balance"
                    label="Rs.Opening Balance"
                    placeholder="000.00"
                    type={"number"}
                    // min={0}
                    component={FormTextField}
                    validator={requiredValidator}
                  />
                  <Field
                    wrapperClassName="w-90"
                    name="rs_credit_debit"
                    label="Cr/Dr"
                    defaultValue="Credit"
                    component={FormSelectionField}
                    options={CREDIT_DEBIT?.map((cr_dr: any) => {
                      return {
                        value: cr_dr.value,
                        label: cr_dr.label,
                      };
                    })}
                  />
                </GridLayoutItem>
                <GridLayoutItem colSpan={3}>
                  <Field
                    label="Provide Bank Details"
                    component={FormRadioGroup}
                    name={`provide_bank_details`}
                    layout="horizontal"
                    options={PROVIDE_BANK_DETAILS}
                  />
                </GridLayoutItem>
                {formRenderProps.valueGetter("provide_bank_details") ===
                  true && (
                  <>
                    <GridLayoutItem>
                      <Field
                        name="bank_account_name"
                        label="Bank Account Name"
                        component={FormTextField}
                      />
                    </GridLayoutItem>
                    <GridLayoutItem>
                      <Field
                        name="bank_name"
                        label="Bank Name"
                        placeholder="Bank Name"
                        component={FormTextField}
                      />
                    </GridLayoutItem>
                    <GridLayoutItem>
                      <Field
                        name="account_no"
                        label="Account No"
                        placeholder="Account No"
                        type={"number"}
                        component={FormTextField}
                      />
                    </GridLayoutItem>
                    <GridLayoutItem>
                      <Field
                        name="branch"
                        label="Branch"
                        placeholder="Branch"
                        component={FormTextField}
                      />
                    </GridLayoutItem>
                    <GridLayoutItem>
                      <Field
                        name="ifsc_code"
                        label="IFSC Code"
                        placeholder="IFSC Code"
                        component={FormTextField}
                      />
                    </GridLayoutItem>
                  </>
                )}

                <GridLayoutItem>
                  <Field
                    id={"IsActive"}
                    name={"isactive"}
                    component={FormSelectionField}
                    label={"Status"}
                    options={STATUSARRAY?.map((status: any) => {
                      return {
                        value: status.value,
                        label: status.label,
                      };
                    })}
                  />
                </GridLayoutItem>
                <GridLayoutItem colSpan={2}>
                  <Field
                    name="remarks"
                    label="Remarks"
                    placeholder="Remarks"
                    component={FormRichTextField}
                  />
                </GridLayoutItem>
                {/* <GridLayoutItem
                  colSpan={3}
                  style={{
                    textAlign: "end",
                    marginTop: "20px",
                    width: "100%",
                  }}
                >
                  <Button
                    type="button"
                    themeColor={"primary"}
                    style={{ marginRight: 4 }}
                    onClick={handleBack}
                  >
                    Go to Vepari List
                  </Button>
                  <Button
                    type="button"
                    fillMode={"outline"}
                    themeColor={"primary"}
                    style={{ marginRight: 4 }}
                    onClick={handleFormClose}
                  >
                    Cancel
                  </Button>
                  <ButtonWithLoading
                    label={!party_guid ? "Create" : "Update"}
                    type="submit"
                    disabled={!formRenderProps.allowSubmit || loading}
                    loading={loading}
                  />
                </GridLayoutItem> */}
              </GridLayout>
            </ShadowCard>
            <ShadowCard style={{ marginTop: 10 }}>
              <GridLayoutItem colSpan={6}>
                <Typography.h4>Contact Details List</Typography.h4>
              </GridLayoutItem>
              <FieldArray
                formRenderProps={formRenderProps}
                component={ContactDetailsArray}
                name="contact_details"
              />
            </ShadowCard>
            <ShadowCard style={{ marginTop: 10 }}>
              <GridLayoutItem colSpan={5}>
                <Typography.h4>Charges Details List</Typography.h4>
              </GridLayoutItem>
         
              <FieldArray
                formRenderProps={formRenderProps}
                component={ChargesDetailArray}
                name="charges_details"
              />
                   <GridLayoutItem
                  colSpan={3}
                  style={{
                    textAlign: "end",
                    marginTop: "20px",
                    width: "100%",
                  }}
                >
                  <Button
                    type="button"
                    themeColor={"primary"}
                    style={{ marginRight: 4 }}
                    onClick={handleBack}
                  >
                    Go to Vepari List
                  </Button>
                  <Button
                    type="button"
                    fillMode={"outline"}
                    themeColor={"primary"}
                    style={{ marginRight: 4 }}
                    onClick={handleFormClose}
                  >
                    Cancel
                  </Button>
                  <ButtonWithLoading
                    label={!party_guid ? "Create" : "Update"}
                    type="submit"
                    disabled={!formRenderProps.allowSubmit || loading}
                    loading={loading}
                  />
                </GridLayoutItem>
            </ShadowCard>
            {formLoading ? (
              <LoadingPanel gridRef={gridRef} />
            ) : (
              party_guid && (
                <ShadowCard style={{ marginTop: 10 }}>
                  <GridLayoutItem colSpan={5}>
                <Typography.h4>Documents Upload</Typography.h4>
              </GridLayoutItem>
                  <FieldArray
                    formRenderProps={formRenderProps}
                    component={PartyImageArray}
                    name="party_images"
                  />
                </ShadowCard>
              )
            )}
          </FormElement>
        )}
      />
    </>
  );
};

export default CreateParty;
