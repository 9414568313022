import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { IParty, IPartyInitialState } from "./partyModel";
import {
  InsertPartyImage,
  PartyActiveInactive,
  createParty,
  deleteParty,
  deletePartyImage,
  findAllActiveParty,
  getAllParty,
  getPartyById,
  getPartyImageById,
  updateParty,
  updatePartyImage,
} from "./services/party.services";

const initialState: IPartyInitialState = {
  loading: false,
  formLoading: false,
  error: "",
  PartyList: [],
  PartyID: null,
  PartyDetail: {
    party_name: "",
    party_nick_name: "",
    mobile_no: "",
    address: "",
    email: "",
    pan_no: "",
    gst_no: "",
    opening_balance: "0",
    credit_debit: "credit",
    rs_opening_balance: "0",
    rs_credit_debit: "credit",
    provide_bank_details: false,
    bank_account_name: "",
    ifsc_code: "",
    bank_name: "",
    branch: "",
    account_no: "",
    remarks: "",
    contact_details: [],
    charges_details: [],
    party_images: [],
    isactive: 1,
  },
  DeleteIndex: -1,
  ChargesDeleteIndex: -1,
  ContactDeleteIndex: -1
};

const partySlice = createSlice({
  name: "party",
  initialState,
  reducers: {
    clearPartyDetail: (state) => {
      state.PartyDetail = initialState.PartyDetail;
    },
    SetPartyID: (state, action) => {
      state.PartyID = action.payload;
    },
    clearPartyID: (state) => {
      state.PartyID = null;
    },
    setContactDetailDeleteIndex: (state, action) => {
      state.ContactDeleteIndex = action.payload;
    },
    setPartyImageDeleteIndex: (state, action) => {
      state.DeleteIndex = action.payload;
    },
    setChargesDeleteIndex: (state, action) => {
      state.ChargesDeleteIndex = action.payload;
    },
    // setContactDeleteIndex: (state, action) => {
    //   state.ChargesDeleteIndex = action.payload;
    // },
  },
  extraReducers: (builder) => {
    builder.addCase(getAllParty.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(
      getAllParty.fulfilled,
      (state, action: PayloadAction<IParty[]>) => {
        state.loading = false;
        state.PartyList = action.payload;
      }
    );
    builder.addCase(getAllParty.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message || "Something went wrong";
      state.PartyList = [];
    });

    builder.addCase(findAllActiveParty.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(
      findAllActiveParty.fulfilled,
      (state, action: PayloadAction<IParty[]>) => {
        state.loading = false;
        state.PartyList = action.payload || [];
      }
    );
    builder.addCase(findAllActiveParty.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message || "Something went wrong";
      state.PartyList = [];
    });

    builder.addCase(createParty.pending, (state) => {
      state.loading = false;
      state.error = "";
    });
    builder.addCase(createParty.fulfilled, (state) => {
      state.loading = false;
    });
    builder.addCase(createParty.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message || "Something went wrong";
    });

    builder.addCase(updateParty.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(updateParty.fulfilled, (state) => {
      state.loading = false;
    });
    builder.addCase(updateParty.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message || "Something went wrong";
    });

    builder.addCase(deleteParty.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(deleteParty.fulfilled, (state) => {
      state.loading = false;
    });
    builder.addCase(deleteParty.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message || "Something went wrong";
    });

    builder.addCase(PartyActiveInactive.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(PartyActiveInactive.fulfilled, (state) => {
      state.loading = false;
    });
    builder.addCase(PartyActiveInactive.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message || "Something went wrong";
    });

    builder.addCase(getPartyById.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(getPartyById.fulfilled, (state, action: any) => {
      state.loading = false;
      state.PartyDetail = action.payload;
    });
    builder.addCase(getPartyById.rejected, (state, action) => {
      state.loading = false;
      state.PartyDetail = initialState.PartyDetail;
      state.error = action.error.message || "Something went wrong";
    });

    builder.addCase(InsertPartyImage.pending, (state) => {
      state.formLoading = true;
      state.error = "";
    });
    builder.addCase(InsertPartyImage.fulfilled, (state) => {
      state.formLoading = false;
    });
    builder.addCase(InsertPartyImage.rejected, (state, action) => {
      state.formLoading = false;
      state.error = action.error.message || "Something went wrong";
    });

    builder.addCase(updatePartyImage.pending, (state) => {
      state.formLoading = true;
      state.error = "";
    });
    builder.addCase(updatePartyImage.fulfilled, (state) => {
      state.formLoading = false;
    });
    builder.addCase(updatePartyImage.rejected, (state, action) => {
      state.formLoading = false;
      state.error = action.error.message || "Something went wrong";
    });

    builder.addCase(deletePartyImage.pending, (state) => {
      state.formLoading = true;
      state.error = "";
    });
    builder.addCase(deletePartyImage.fulfilled, (state) => {
      state.formLoading = false;
    });
    builder.addCase(deletePartyImage.rejected, (state, action) => {
      state.formLoading = false;
      state.error = action.error.message || "Something went wrong";
    });

    builder.addCase(getPartyImageById.pending, (state) => {
      state.formLoading = true;
      state.error = "";
    });
    builder.addCase(getPartyImageById.fulfilled, (state) => {
      state.formLoading = false;
    });
    builder.addCase(getPartyImageById.rejected, (state, action) => {
      state.formLoading = false;
      state.error = action.error.message || "Something went wrong";
    });
  },
});

export const {
  clearPartyDetail,
  clearPartyID,
  SetPartyID,
  setContactDetailDeleteIndex,
  setPartyImageDeleteIndex,
  setChargesDeleteIndex,
  // setContactDeleteIndex
} = partySlice.actions;
export default partySlice.reducer;
