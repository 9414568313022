import { Button } from "@progress/kendo-react-buttons";
import { GridLayout, GridLayoutItem } from "@progress/kendo-react-layout";
import { Typography } from "@progress/kendo-react-common";
import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import AppDialog, { DialogCloseButton } from "../../components/dialog/Dialog";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import {
  ItemActiveInactive,
  deleteItem,
  getAllItem,
} from "./services/item.services";
import { closeDialog, openDialog } from "../../components/dialog/dialogSlice";
import { clearItemID, clearItemDetail, setItemID } from "./itemSlice";
import {
  CompositeFilterDescriptor,
  filterBy,
} from "@progress/kendo-data-query";
import {
  GridCellProps,
  GridCustomCellProps,
  GridCustomHeaderCellProps,
  GridFilterChangeEvent,
  GridPageChangeEvent,
  Grid as KendoGrid,
  GridColumn as Column,
} from "@progress/kendo-react-grid";
import {
  HeaderThElement,
  // PagerTargetEvent,
} from "@progress/kendo-react-data-tools";
import IconButton from "../../components/common/IconButton";
import { FiEdit } from "react-icons/fi";
import { MdDelete } from "react-icons/md";
import { Tooltip } from "@progress/kendo-react-tooltip";
import { Switch } from "@progress/kendo-react-inputs";
import { LoadingPanel } from "../../components/layout/Loading";
import AlertBox from "../../components/common/AlertBox";
import { setCurrentPage, setPageLimit } from "../pagination/paginationSlice";

const Item: React.FC = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  // const currentPage = useAppSelector((state) => state.pagination.currentPage);
  // const pageLimit = useAppSelector((state) => state.pagination.pageLimit);

  useEffect(() => {
    return () => {
      dispatch(setCurrentPage(0));
    };
  }, []);

  useEffect(() => {
    // const payload = {
    //   item_name: "",
    //   limit: +pageLimit,
    //   skip: +currentPage * +pageLimit,
    // };
    // dispatch(getAllItem(payload));
    return () => {
      dispatch(closeDialog());
      dispatch(clearItemDetail());
    };
  }, []);

  const handleItemCreate = () => {
    navigate("/item/create");
  };

  return (
    <>
      <DeleteItemDialog />
      <GridLayout cols={[{ width: "100%" }]}>
        <GridLayoutItem
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            padding: "4px 20px 20px 0",
          }}
        >
          <Typography.h4 style={{ marginBottom: 0, marginLeft: "3px" }}>
            Product List
          </Typography.h4>
          <Button
            onClick={handleItemCreate}
            fillMode={"solid"}
            themeColor={"primary"}
          >
            Create Product
          </Button>
        </GridLayoutItem>
        <GridLayoutItem>
          <ItemGridComponent />
        </GridLayoutItem>
      </GridLayout>
    </>
  );
};

interface PageState {
  skip: number;
  take: number;
}

const initialDataState: PageState = { skip: 0, take: 10 };

const ItemGridComponent: React.FC = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const ItemList = useAppSelector((state) => state.item.ItemList);
  const loading = useAppSelector((state) => state.item.loading);
  const currentPage = useAppSelector((state) => state.pagination.currentPage);
  const pageLimit = useAppSelector((state) => state.pagination.pageLimit);
  const [page, setPage] = React.useState<PageState>(initialDataState);
  const [uiLoading, setLoading] = useState<boolean>(false);
  // const [pageSizeValue, setPageSizeValue] = React.useState<
  //   number | string | undefined
  // >();

  useEffect(() => {
    const payload = {
      item_name: "",
      limit: +pageLimit,
      skip: +currentPage * +pageLimit,
    };
    dispatch(getAllItem(payload));
  }, [currentPage, pageLimit]);

  const pageChange = (event: GridPageChangeEvent) => {
    const newPage = event.page.skip / event.page.take;
    dispatch(setPageLimit(event.page.take));
    dispatch(setCurrentPage(newPage));
  };
  
  const [filter, setFilter] = React.useState<
  CompositeFilterDescriptor | undefined
>(undefined);

  useEffect(() => {
    setLoading(loading);
  }, [loading]);


  // const pageChange = (event: GridPageChangeEvent) => {
  //   const targetEvent = event.targetEvent as PagerTargetEvent;
  //   const take =
  //     targetEvent.value === "All" ? ItemList.length : event.page.take;

  //   if (targetEvent.value) {
  //     setPageSizeValue(targetEvent.value);
  //   }
  //   setPage({
  //     ...event.page,
  //     take,
  //   });
  // };


  const MyEditCommandCell = (props: GridCellProps) => {
    const handleEditItem = (ID: number) => {
      navigate("/item/edit", {
        state: { item_guid: ID, id: props.dataItem?.id },
      });
    };

    const handleOpenDeleteDialog = (ID: number) => {
      dispatch(openDialog("deleteItem"));
      dispatch(setItemID(ID));
    };

    return (
      <td className="action-td">
        <IconButton
          type="button"
          fillMode="flat"
          size="small"
          title="Edit"
          onClick={() => handleEditItem(props.dataItem.item_guid)}
        >
          <FiEdit className="absolute-position" style={{ fontSize: "20px" }} />
        </IconButton>
        <IconButton
          type="button"
          fillMode="flat"
          size="small"
          title="Delete"
          onClick={() => handleOpenDeleteDialog(props.dataItem.item_guid)}
        >
          <MdDelete
            className="absolute-position"
            style={{ fontSize: "24px" }}
          />
        </IconButton>
      </td>
    );
  };

  const handleFilterChange = (e: GridFilterChangeEvent) => {
    setFilter(e.filter);
    setPage({ skip: 0, take: page.take });
  };

  const HeaderCustomCell = (props: GridCustomHeaderCellProps) => (
    <HeaderThElement
      columnId={props.thProps?.columnId || ""}
      {...props.thProps}
      className={`table-header ${props.index === 5 ? "locked-header" : ""}`}
    >
      {props.children}
    </HeaderThElement>
  );
  const CustomCell = (props: any) => {
    return (
      <td {...props.tdProps} colSpan={1} className={"table-cell"}>
        {props.children}
      </td>
    );
  };
  const MyDataCustomCell = (props: GridCustomCellProps) => (
    <CustomCell {...props} />
  );
  const MyActiveInActtiveCommandCell = (props: GridCellProps) => {
    const [IsActive, setIsActive] = useState<boolean>(props.dataItem?.isactive);

    const handleActiveInActive = () => {
      setIsActive(!IsActive);
      const data = {
        item_guid: props.dataItem.item_guid,
        isactive: !IsActive,
      };
      dispatch(ItemActiveInactive(data)).then((response: any) => {
        const payload = {
          item_name: "",
          limit: +pageLimit,
          skip: +currentPage * +pageLimit,
        };
        if (response.payload?.data?.Status === 200) {
          dispatch(getAllItem(payload));
        } else {
          dispatch(getAllItem(payload));
        }
      });
    };

    return (
      <td style={{ textAlign: "center" }}>
        <Tooltip anchorElement="target" position="top" parentTitle={true}>
          <div title={`Set ${IsActive ? "Inactive" : "Active"}.`}>
            <Switch
              name="isactive"
              checked={IsActive}
              onChange={handleActiveInActive}
              size="small"
            />
          </div>
        </Tooltip>
      </td>
    );
  };

  const gridRef = useRef<any>(null);
  if (uiLoading) return <LoadingPanel gridRef={gridRef} />;

  return (
    <>
      {ItemList && ItemList.length ? (
        <KendoGrid
          style={{ maxHeight: "calc(100vh - 152px)" }}
          filterable={true}
          filter={filter}
          data={
            filter ? filterBy(ItemList, filter) : ItemList
          }
          total={
            ItemList[0]?.itemcount ||
            ItemList.length
          }
          onFilterChange={handleFilterChange}
          skip={currentPage * pageLimit}
          take={pageLimit}
          pageable={{
            buttonCount: 5,
            pageSizes: [10, 50, 100, 300, 500],
            pageSizeValue: pageLimit,
            type: "input",
          }}
          onPageChange={pageChange}
          cells={{
            headerCell: HeaderCustomCell,
            data: MyDataCustomCell,
          }}
        >
          <Column
            field="item_name"
            title="Product Name"
            cell={(props: any) => (
              <td>
                <div
                  className="hoverableName"
                  onClick={() =>
                    navigate("/item/edit", {
                      state: { item_guid: props.dataItem?.item_guid },
                    })
                  }
                >
                  {props.dataItem?.item_name}
                </div>
              </td>
            )}
          />
          <Column field="item_nick_name" title="Product Nick Name" />
          <Column field="item_id" title="Product ID" />
          <Column field="category_name" title="Product Category" />
          <Column field="item_subcategory_name" title="Product Subcategory" />
          <Column
            field="isactive"
            title="Status"
            cell={MyActiveInActtiveCommandCell}
            width={"90px"}
            filterable={false}
            locked={true}
          />
          <Column
            field="item_guid"
            title="Actions"
            cell={MyEditCommandCell}
            width={"110px"}
            filterable={false}
            locked={true}
          />
        </KendoGrid>
      ) : (
        <AlertBox />
      )}
    </>
  );
};

const DeleteItemDialog: React.FC = () => {
  const dispatch = useAppDispatch();
  const ItemID = useAppSelector((state) => state.item.ItemCategoryID);
  const dialogName = useAppSelector((state) => state.dialog.dialogName);
  const currentPage = useAppSelector((state) => state.pagination.currentPage);
  const pageLimit = useAppSelector((state) => state.pagination.pageLimit);

  const handleDeleteAction = async (ID: number | null) => {
    if (ID) {
      const response = await dispatch(deleteItem(ID));
      dispatch(setCurrentPage(0));
      const payload = {
        item_name: "",
        limit: +pageLimit,
        skip: +currentPage * +pageLimit,
      };
      if (response.payload?.Data.Status === 200) {
        dispatch(getAllItem(payload));
        dispatch(closeDialog());
        dispatch(clearItemID());
      } else {
        dispatch(getAllItem(payload));
        dispatch(closeDialog());
        dispatch(clearItemID());
      }
    }
  };

  return (
    <>
      {dialogName === "deleteItem" && (
        <AppDialog>
          <>
            <Typography.h5>{"Delete Product ?"}</Typography.h5>
            <GridLayout>
              <GridLayoutItem>
                <Typography.p>
                  Are you sure you want to delete this Product?
                </Typography.p>
              </GridLayoutItem>
              <GridLayoutItem
                style={{
                  display: "flex",
                  justifyContent: "end",
                }}
              >
                <DialogCloseButton themeColor="error" />
                <Button
                  fillMode={"solid"}
                  themeColor={"error"}
                  onClick={() => {
                    handleDeleteAction(ItemID);
                  }}
                  type="button"
                >
                  Delete
                </Button>
              </GridLayoutItem>
            </GridLayout>
          </>
        </AppDialog>
      )}
    </>
  );
};

export default Item;
