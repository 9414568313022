import { Button } from "@progress/kendo-react-buttons";
import { Card, GridLayout, GridLayoutItem } from "@progress/kendo-react-layout";
import React, { useEffect } from "react";
// import IconButton from "../../components/common/IconButton";
// import { MdDelete } from "react-icons/md";
import { Field, FieldArrayRenderProps } from "@progress/kendo-react-form";
import { requiredValidator } from "../../components/formFields/CommonValidator";
import FormTextField from "../../components/formFields/FormTextField";
import { closeDialog, openDialog } from "../../components/dialog/dialogSlice";
import AppDialog, { DialogCloseButton } from "../../components/dialog/Dialog";
import { Typography } from "@progress/kendo-react-common";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { setChargesDeleteIndex } from "./partySlice";
import FormSelectionField from "../../components/formFields/FormSelectionField";
import { findAllActiveCharges } from "../charges/services/charges.services";
import { findAllActiveUnit } from "../unit/services/unit.services";

const ChargesDetailArray = (fieldArrayRenderProps: FieldArrayRenderProps) => {
  const dispatch = useAppDispatch();
  const ChargesDetailDeleteIndex = useAppSelector(
    (state) => state.party.ChargesDeleteIndex
  );
  const dialogName = useAppSelector((state) => state.dialog.dialogName);
  const ChargesList = useAppSelector((state) => state.charges.ChargesList);
  const UnitList = useAppSelector((state) => state.unit.UnitList);
  const handleDeleteAction = React.useCallback(
    (index: number) => {
      fieldArrayRenderProps.onRemove({ index: index });
      dispatch(closeDialog());
      dispatch(setChargesDeleteIndex(-1));
    },
    [fieldArrayRenderProps]
  );

  useEffect(() => {
    dispatch(findAllActiveCharges());
    dispatch(findAllActiveUnit());
  }, []);

  const CHARGES_TYPE = [
    { label: "Rs.", value: 1 },
    { label: "Percentage(%)", value: 2 },
  ];

  const pushElementInarray = React.useCallback(() => {
    fieldArrayRenderProps?.onPush({
      value: {
        party_id: null,
        charge_id: null,
        unit_id: null,
        charges_type: null,
        charges: "",
      },
    });
  }, [fieldArrayRenderProps]);

  const handleOpenDeleteDialog = (index: any) => {
    dispatch(openDialog("chargesDetailDeleteDialog"));
    dispatch(setChargesDeleteIndex(index));
  };

  return (
    <GridLayout cols={[{ width: "100%" }]}>
      {/* {fieldArrayRenderProps?.value?.length <= 0 ? pushElementInarray() : null} */}
      {dialogName === "chargesDetailDeleteDialog" && (
        <AppDialog>
          <>
            <Typography.h5>{"Delete Charges Detail Data"}</Typography.h5>
            <GridLayout>
              <GridLayoutItem>
                <Typography.p>
                  Are you sure you want to delete this?
                </Typography.p>
              </GridLayoutItem>
              <GridLayoutItem
                style={{
                  display: "flex",
                  justifyContent: "end",
                }}
              >
                <DialogCloseButton themeColor="error" />
                <Button
                  fillMode={"solid"}
                  themeColor={"error"}
                  onClick={() => handleDeleteAction(ChargesDetailDeleteIndex)}
                  type="button"
                >
                  Delete
                </Button>
              </GridLayoutItem>
            </GridLayout>
          </>
        </AppDialog>
      )}
      {fieldArrayRenderProps.value &&
        fieldArrayRenderProps.value.length > 0 &&
        fieldArrayRenderProps.value.map((process_data: any, index: number) => (
          <GridLayoutItem key={index}>
            <Card className="fieldarray-card">
              <GridLayout
                style={{ marginRight: 45 }}
                gap={{ rows: 0, cols: 10 }}
                cols={[
                  { width: "23.8%" },
                  { width: "23.8%" },
                  { width: "23.8%" },
                  { width: "23.8%" },
                  { width: "5%" },
                ]}
              >
                <GridLayoutItem>
                  <Field
                    name={`charges_details.${index}.charge_id`}
                    label="Charges Name"
                    placeholder="Charges Name"
                    component={FormSelectionField}
                    validator={requiredValidator}
                    options={ChargesList?.map((charges: any) => {
                      return {
                        value: charges.id,
                        label: charges.charges_name,
                      };
                    })}
                  />
                </GridLayoutItem>
                <GridLayoutItem>
                  <Field
                    name={`charges_details.${index}.unit_id`}
                    label="Unit"
                    placeholder="Unit"
                    component={FormSelectionField}
                    options={UnitList?.map((unit: any) => {
                      return {
                        value: unit.id,
                        label: unit.unit_name,
                      };
                    })}
                  />
                </GridLayoutItem>
                <GridLayoutItem>
                  <Field
                    name={`charges_details.${index}.charges_type`}
                    label="Charges Type"
                    placeholder="Charges Type"
                    component={FormSelectionField}
                    validator={requiredValidator}
                    options={CHARGES_TYPE?.map((chargestype: any) => {
                      return {
                        value: chargestype.value,
                        label: chargestype.label,
                      };
                    })}
                  />
                </GridLayoutItem>
                <GridLayoutItem>
                  <Field
                    name={`charges_details.${index}.charges`}
                    label="Charges"
                    placeholder="Charges"
                    component={FormTextField}
                  />
                </GridLayoutItem>
                <GridLayoutItem
                  style={{
                    marginTop: 42,
                  }}
                >
                  {/* <IconButton
                    onClick={() => handleOpenDeleteDialog(index)}
                    disabled={fieldArrayRenderProps.value.length === 1}
                    themeColor={"error"}
                    size={"small"}
                    fillMode={"solid"}
                    type="button"
                    style={{ height: 24, width: 24 }}
                  >
                    <MdDelete
                      className="absolute-position"
                      style={{ fontSize: "16px" }}
                    />
                  </IconButton> */}
                  <Button
                    style={{ padding: 8, marginLeft: 0 }}
                    onClick={() => handleOpenDeleteDialog(index)}
                    // disabled={fieldArrayRenderProps.value.length === 1}
                    fillMode={"solid"}
                    themeColor={"error"}
                    type="button"
                    size={"small"}
                  >
                    Delete
                  </Button>
                </GridLayoutItem>
              </GridLayout>
            </Card>
          </GridLayoutItem>
        ))}
      <GridLayoutItem style={{ marginBottom: 10, textAlign: "left" }}>
        <Button
          fillMode={"solid"}
          themeColor={"primary"}
          onClick={pushElementInarray}
          type="button"
        >
          Add Charges Detail
        </Button>
      </GridLayoutItem>
    </GridLayout>
  );
};

export default ChargesDetailArray;
