import { createAsyncThunk } from "@reduxjs/toolkit";
import { API_URL } from "../../../app/config";
import { axiosApiInstance } from "../../../app/axios";
import { ErrorToast, SuccessToast } from "../../../components/toast/Toasts";
import { IPriority } from "../priorityModel";

export const getAllPriority = createAsyncThunk(
  "Priority/FindAllPriority",
  async () => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/Priority/FindAllPriority`,
        {
          priority: "",
        }
      );
      return response.data?.Data;
    } catch (error: any) {
      console.error("Error Fetching Priority:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const createPriority = createAsyncThunk(
  "Priority/InsertPriority",
  async (priority: any) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/Priority/InsertPriority`,
        priority
      );
      SuccessToast(response.data?.Details || "Success");
      return response.data;
    } catch (error: any) {
      ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error Fetching Priority:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const updatePriority = createAsyncThunk(
  "Priority/UpdatePriority",
  async (priority: any) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/Priority/UpdatePriority`,
        priority
      );
      SuccessToast(response.data?.Details || "Success");
      return response.data;
    } catch (error: any) {
      ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error Fetching Priority:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const getPriorityById = createAsyncThunk(
  "Priority/FindByIDPriority",
  async (ID: any) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/Priority/FindByIDPriority`,
        {
          priority_guid: ID,
        }
      );
      // SuccessToast(response.data?.Details || "Success")
      const result = response.data?.Data as any;
      return {
        priority_guid: result.priority_guid,
        priority: result.priority,
        isactive: result.isactive === true ? 1 : 2,
      } as IPriority;
    } catch (error: any) {
      ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error Fetching Priority:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const deletePriority = createAsyncThunk(
  "/Priority/DeletePriority",
  async (ID: number) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/Priority/DeletePriority`,
        {
          priority_guid: ID,
        }
      );
      SuccessToast(response.data?.Details || "Success");
      return response.data;
    } catch (error: any) {
      ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error Deleting Priority:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const findAllActivePriority = createAsyncThunk(
  "Priority/FindAllActivePriority",
  async () => {
    try {
      const response = await axiosApiInstance.get(
        `${API_URL}/Priority/FindAllActivePriority`
      );
      // SuccessToast(response.data?.Details || "Success")
      return response.data.Data;
    } catch (error: any) {
      console.error("Error Deleting Priority:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const PriorityActiveInactive = createAsyncThunk(
  "Priority/ActiveInActivePriority",
  async (formData: any) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/Priority/ActiveInActivePriority`,
        formData
      );
      SuccessToast(response.data?.Details || "Success");
      return response.data;
    } catch (error: any) {
      ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error active-inactive Priority:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);
