import { createAsyncThunk } from "@reduxjs/toolkit";
import { API_URL } from "../../../app/config";
import { axiosApiInstance } from "../../../app/axios";
import { ErrorToast, SuccessToast } from "../../../components/toast/Toasts";
import { ICharges } from "../chargesModel";

export const getAllCharges = createAsyncThunk(
  "Charges/FindAllCharges",
  async () => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/Charges/FindAllCharges`,
        {
          Charges: "",
        }
      );
      return response.data?.Data;
    } catch (error: any) {
      console.error("Error Fetching Charges:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const createCharges = createAsyncThunk(
  "Charges/InsertCharges",
  async (Charges: any) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/Charges/InsertCharges`,
        Charges
      );
      SuccessToast(response.data?.Details || "Success");
      return response.data;
    } catch (error: any) {
      ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error Fetching Charges:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const updateCharges = createAsyncThunk(
  "Charges/UpdateCharges",
  async (Charges: any) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/Charges/UpdateCharges`,
        Charges
      );
      SuccessToast(response.data?.Details || "Success");
      return response.data;
    } catch (error: any) {
      ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error Fetching Charges:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const getChargesById = createAsyncThunk(
  "Charges/FindByIDCharges",
  async (ID: any) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/Charges/FindByIDCharges`,
        {
          charges_guid: ID,
        }
      );
      // SuccessToast(response.data?.Details || "Success")
      const result = response.data?.Data as any;
      return {
        charges_guid: result.charges_guid,
        charges_name:result.charges_name,
        isactive: result.isactive === true ? 1 : 2,
      } as ICharges;
    } catch (error: any) {
      ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error Fetching Charges:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const deleteCharges = createAsyncThunk(
  "Charges/DeleteCharges",
  async (ID: number) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/Charges/DeleteCharges`,
        {
          charges_guid: ID,
        }
      );
      SuccessToast(response.data?.Details || "Success");
      return response.data;
    } catch (error: any) {
      ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error Deleting Charges:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const findAllActiveCharges = createAsyncThunk(
  "Charges/FindAllActiveCharges",
  async () => {
    try {
      const response = await axiosApiInstance.get(
        `${API_URL}/Charges/FindAllActiveCharges`
      );
      // SuccessToast(response.data?.Details || "Success")
      return response.data.Data;
    } catch (error: any) {
      console.error("Error Deleting Charges:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const ChargesActiveInactive = createAsyncThunk(
  "Charges/ActiveInActiveCharges",
  async (formData: any) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/Charges/ActiveInActiveCharges`,
        formData
      );
      SuccessToast(response.data?.Details || "Success");
      return response.data;
    } catch (error: any) {
      ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error active-inactive Charges:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);
