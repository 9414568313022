import React, { useEffect, useRef } from "react";
import {
  Field,
  Form,
  FormElement,
  FormRenderProps,
} from "@progress/kendo-react-form";
import { GridLayout, GridLayoutItem } from "@progress/kendo-react-layout";
import FormTextField from "../../components/formFields/FormTextField";
import { requiredValidator } from "../../components/formFields/CommonValidator";
import FormSelectionField from "../../components/formFields/FormSelectionField";
import { Button } from "@progress/kendo-react-buttons";
import ButtonWithLoading from "../../components/common/ButtonWithLoading";
import { LoadingPanel } from "../../components/layout/Loading";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { STATUSARRAY } from "../../_contstants/common";
import { clearUnitDetails } from "./unitSlice";
import {
  createUnit,
  getAllUnit,
  getUnitById,
  updateUnit,
} from "./services/unit.services";
import { closed } from "../../components/drawer/drawerSlice";

const CreateUnit: React.FC = () => {
  const formLoading = useAppSelector((state) => state.unit.formLoading);
  const UnitDetail = useAppSelector((state) => state.unit.UnitDetail);
  const unit_guid = useAppSelector((state) => state.drawer.data);
  const dispatch = useAppDispatch();
  const gridRef = useRef<any>(null);

  useEffect(() => {
    return () => {
      dispatch(clearUnitDetails());
      dispatch(closed());
    };
  }, []);

  useEffect(() => {
    if (unit_guid) {
      dispatch(getUnitById(unit_guid));
    }
  }, [unit_guid]);

  const handleSubmit = async (values: any) => {
    try {
      if (!unit_guid) {
        const payload = {
          unit_name: values.unit_name,
          short_code: values.short_code,
          isactive: values.isactive === 1,
        };
        const response = await dispatch(createUnit(payload));
        if (response?.meta?.requestStatus === "fulfilled") {
          dispatch(closed());
          dispatch(clearUnitDetails());
          dispatch(getAllUnit());
        }
      } else {
        const payload = {
          unit_guid: unit_guid,
          unit_name: values.unit_name,
          short_code: values.short_code,
          isactive: values.isactive === 1,
        };
        const response = await dispatch(updateUnit(payload));
        if (response?.meta?.requestStatus === "fulfilled") {
          dispatch(closed());
          dispatch(clearUnitDetails());
          dispatch(getAllUnit());
        }
      }
    } catch (error) {
      console.error("Error in handleSubmit:", error);
      throw error;
    }
  };

  const handleFormClose = () => {
    dispatch(closed());
  };

  if (formLoading) return <LoadingPanel gridRef={gridRef} />;
  return (
    <Form
      onSubmit={handleSubmit}
      initialValues={UnitDetail}
      render={(formRenderProps: FormRenderProps) => (
        <FormElement style={{ width: "100%" }}>
          <GridLayout
            style={{
              display: "flex",
              flexWrap: "wrap",
            }}
            gap={{ rows: 10 }}
            cols={[{ width: "100%" }]}
          >
            <GridLayoutItem style={{ width: "100%" }}>
              <Field
                name="unit_name"
                label="Unit Name"
                placeholder="Unit Name"
                component={FormTextField}
                validator={requiredValidator}
              />
            </GridLayoutItem>
            <GridLayoutItem style={{ width: "100%" }}>
              <Field
                name="short_code"
                label="Short Code"
                placeholder="Short Code"
                component={FormTextField}
                validator={requiredValidator}
              />
            </GridLayoutItem>
            <GridLayoutItem style={{ width: "100%" }}>
              <Field
                id={"isactive"}
                name={"isactive"}
                label={"Status"}
                component={FormSelectionField}
                validator={requiredValidator}
                options={STATUSARRAY?.map((status: any) => {
                  return {
                    value: status.value,
                    label: status.label,
                  };
                })}
              />
            </GridLayoutItem>
            <GridLayoutItem
              colSpan={3}
              style={{
                textAlign: "end",
                marginTop: "10px",
                width: "100%",
              }}
            >
              <Button
                type="button"
                fillMode={"outline"}
                themeColor={"primary"}
                style={{ marginRight: 4 }}
                onClick={handleFormClose}
              >
                Cancel
              </Button>
              <ButtonWithLoading
                label={unit_guid ? "Update" : "Create"}
                type="submit"
                disabled={!formRenderProps.allowSubmit || formLoading}
                loading={formLoading}
              />
            </GridLayoutItem>
          </GridLayout>
        </FormElement>
      )}
    />
  );
};

export default CreateUnit;
