import { createAsyncThunk } from "@reduxjs/toolkit";
import { API_URL } from "../../../app/config";
import { axiosApiInstance } from "../../../app/axios";
import { ErrorToast, SuccessToast } from "../../../components/toast/Toasts";
import { IPurity } from "../purityModel";

export const getAllPurity = createAsyncThunk(
  "Purity/FindAllPurity",
  async () => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/Purity/FindAllPurity`,
        {
          purity: "",
        }
      );
      return response.data?.Data;
    } catch (error: any) {
      console.error("Error Fetching Purity:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const createPurity = createAsyncThunk(
  "Purity/InsertPurity",
  async (purity: any) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/Purity/InsertPurity`,
        purity
      );
      SuccessToast(response.data?.Details || "Success");
      return response.data;
    } catch (error: any) {
      ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error Fetching Purity:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const updatePurity = createAsyncThunk(
  "Purity/UpdatePurity",
  async (purity: any) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/Purity/UpdatePurity`,
        purity
      );
      SuccessToast(response.data?.Details || "Success");
      return response.data;
    } catch (error: any) {
      ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error Fetching Purity:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const getPurityById = createAsyncThunk(
  "Purity/FindByIDPurity",
  async (ID: any) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/Purity/FindByIDPurity`,
        {
          purity_guid: ID,
        }
      );
      // SuccessToast(response.data?.Details || "Success")
      const result = response.data?.Data as any;
      return {
        purity_guid: result.purity_guid,
        purity_name: result.purity_name,
        isactive: result.isactive === true ? 1 : 2,
      } as IPurity;
    } catch (error: any) {
      ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error Fetching Purity:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const deletePurity = createAsyncThunk(
  "Purity/DeletePurity",
  async (ID: number) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/Purity/DeletePurity`,
        {
          purity_guid: ID,
        }
      );
      SuccessToast(response.data?.Details || "Success");
      return response.data;
    } catch (error: any) {
      ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error Deleting Purity:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const findAllActivePurity = createAsyncThunk(
  "Purity/FindAllActivePurity",
  async () => {
    try {
      const response = await axiosApiInstance.get(
        `${API_URL}/Purity/FindAllActivePurity`
      );
      // SuccessToast(response.data?.Details || "Success")
      return response.data.Data;
    } catch (error: any) {
      console.error("Error Deleting Purity:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const PurityActiveInactive = createAsyncThunk(
  "Purity/ActiveInActivePurity",
  async (formData: any) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/Purity/ActiveInActivePurity`,
        formData
      );
      SuccessToast(response.data?.Details || "Success");
      return response.data;
    } catch (error: any) {
      ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error active-inactive Purity:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);
