import { Button } from "@progress/kendo-react-buttons";
import { Card, GridLayout, GridLayoutItem } from "@progress/kendo-react-layout";
import React from "react";
import { Field, FieldArrayRenderProps } from "@progress/kendo-react-form";
import FormTextField from "../../components/formFields/FormTextField";
import { closeDialog, openDialog } from "../../components/dialog/dialogSlice";
import AppDialog from "../../components/dialog/Dialog";
import { Typography } from "@progress/kendo-react-common";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import FormImageUpload from "../../components/formFields/FormImageUpload";
import { ErrorToast, SuccessToast } from "../../components/toast/Toasts";
import { Ripple } from "@progress/kendo-react-ripple";
import {
  InsertPartyImage,
  deletePartyImage,
  getPartyImageById,
  updatePartyImage,
} from "./services/party.services";
import { setPartyImageDeleteIndex } from "./partySlice";
import { requiredValidator } from "../../components/formFields/CommonValidator";

const PartyImageArray = (fieldArrayRenderProps: FieldArrayRenderProps) => {
  const dispatch = useAppDispatch();
  const deletePartyImageArrayIndex = useAppSelector(
    (state) => state.party.DeleteIndex
  );
  const PartyDetail = useAppSelector((state) => state.party.PartyDetail);
  const dialogName = useAppSelector((state) => state.dialog.dialogName);

  const pushElementInarray = React.useCallback(() => {
    fieldArrayRenderProps.onPush({
      value: {
        title: "",
        image_name: "",
      },
    });
  }, [fieldArrayRenderProps]);

  const handleImageInsert = async (index: number) => {
    const image_name: any[] = fieldArrayRenderProps?.value[index]?.image_name;
    const title = fieldArrayRenderProps?.value[index]?.title;
    const image_guid = fieldArrayRenderProps?.value[index]?.image_guid;
    if (
      !image_guid &&
      fieldArrayRenderProps?.value[index]?.title &&
      typeof image_name !== "string"
    ) {
      const ImageData = new FormData();
      const binaryPhoto = image_name[0]?.getRawFile();
      ImageData.append("image_name", binaryPhoto);
      try {
        const response = await dispatch(
          InsertPartyImage({
            party_id: PartyDetail?.id,
            title: title,
            ImageData: ImageData,
          })
        );
        if (response?.meta?.requestStatus === "fulfilled") {
          const res = await dispatch(getPartyImageById(PartyDetail?.id));
          if (res?.meta?.requestStatus === "fulfilled") {
            fieldArrayRenderProps?.formRenderProps?.onChange("party_images", {
              value: JSON.parse(JSON.stringify(res.payload)),
            });
          }
        }
      } catch (error: any) {
        ErrorToast(error?.response?.data?.Details || "Something went wrong");
        console.error("Error deleting Image:", error);
        return Promise.reject({
          message: error?.response?.data?.Details || "Something went wrong",
        });
      }
    }
  };

  const handleImageUpdate = async (index: number) => {
    const image_name: any[] = fieldArrayRenderProps?.value[index]?.image_name;
    const title = fieldArrayRenderProps?.value[index]?.title;
    const image_guid = fieldArrayRenderProps?.value[index]?.image_guid;
    if (
      image_guid &&
      fieldArrayRenderProps?.value[index]?.title &&
      typeof image_name !== "string"
    ) {
      const ImageData = new FormData();
      const binaryPhoto = image_name[0].getRawFile();
      ImageData.append("image_name", binaryPhoto);
      try {
        const response = await dispatch(
          updatePartyImage({
            image_guid: image_guid,
            party_id: PartyDetail?.id,
            title: title,
            ImageData: ImageData,
          })
        );
        if (response?.meta?.requestStatus === "fulfilled") {
          const res = await dispatch(getPartyImageById(PartyDetail?.id));
          if (res?.meta?.requestStatus === "fulfilled") {
            fieldArrayRenderProps?.formRenderProps?.onChange("party_images", {
              value: JSON.parse(JSON.stringify(res.payload)),
            });
          }
        }
      } catch (error: any) {
        ErrorToast(error?.response?.data?.Details || "Something went wrong");
        console.error("Error deleting Image:", error);
        return Promise.reject({
          message: error?.response?.data?.Details || "Something went wrong",
        });
      }
    }
  };

  const handleOpenDeleteDialog = (index: number) => {
    dispatch(openDialog("PartyImageDeleteDialog"));
    dispatch(setPartyImageDeleteIndex(index));
  };

  const handleOpenImage = (url: string) => {
    if (url && typeof url === "string") {
      window.open(url);
    }
  };
  const handleDeleteAction = React.useCallback(
    async (index: number) => {
      if (fieldArrayRenderProps?.value[index]?.image_guid) {
        try {
          const response = await dispatch(
            deletePartyImage(fieldArrayRenderProps?.value[index]?.image_guid)
          );
          if (response?.meta?.requestStatus === "fulfilled") {
            fieldArrayRenderProps?.onRemove({ index: index });
            dispatch(closeDialog());
            dispatch(setPartyImageDeleteIndex(-1));
            const res = await dispatch(getPartyImageById(PartyDetail?.id));
            if (res?.meta?.requestStatus === "fulfilled") {
              fieldArrayRenderProps?.formRenderProps?.onChange("party_images", {
                value: JSON.parse(JSON.stringify(res.payload)),
              });
            }
            SuccessToast(response.payload.message || "Success");
          }
        } catch (error: any) {
          ErrorToast(error?.response?.data?.Details || "Something went wrong");
          console.error("Error deleting Image:", error);
          return Promise.reject({
            message: error?.response?.data?.Details || "Something went wrong",
          });
        }
      } else {
        fieldArrayRenderProps.onRemove({ index: index });
        dispatch(closeDialog());
        dispatch(setPartyImageDeleteIndex(-1));
      }
    },
    [fieldArrayRenderProps]
  );

  return (
    <GridLayout cols={[{ width: "100%" }]}>
      {fieldArrayRenderProps?.value?.length < 1 ? pushElementInarray() : null}
      { dialogName === "PartyImageDeleteDialog" &&
        <AppDialog>
          <>
            <Typography.h5>{"Delete Vepari Image"}</Typography.h5>
            <GridLayout>
              <GridLayoutItem>
                <Typography.p>
                  Are you sure you want to delete this?
                </Typography.p>
              </GridLayoutItem>
              <GridLayoutItem
                style={{
                  display: "flex",
                  justifyContent: "end",
                }}
              >
                <Ripple>
                  <Button
                    style={{ marginRight: 4 }}
                    fillMode={"outline"}
                    themeColor={"error"}
                    onClick={() => {
                      dispatch(closeDialog());
                      dispatch(setPartyImageDeleteIndex(-1));
                    }}
                  >
                    Cancel
                  </Button>
                </Ripple>
                <Button
                  fillMode={"solid"}
                  themeColor={"error"}
                  onClick={() => handleDeleteAction(deletePartyImageArrayIndex)}
                  type="button"
                >
                  Delete
                </Button>
              </GridLayoutItem>
            </GridLayout>
          </>
        </AppDialog>
      }
      {fieldArrayRenderProps.value &&
        fieldArrayRenderProps.value.length > 0 &&
        fieldArrayRenderProps.value.map((party_images: any, index: number) => (
          <GridLayoutItem key={index}>
            <Card className="fieldarray-card-imageArray">
              <GridLayout
                style={{ marginRight: 20 }}
                gap={{ rows: 0, cols: 10 }}
                cols={[{ width: "33%" }, { width: "33%" }, { width: "33%" }]}
              >
                <GridLayoutItem>
                  <Field
                    name={`party_images.${index}.title`}
                    label="Title"
                    placeholder="Title"
                    validator={requiredValidator}
                    component={FormTextField}
                  />
                </GridLayoutItem>
                <GridLayoutItem>
                  <Field
                    name={`party_images.${index}.image_name`}
                    label="Image"
                    type="file"
                    accept="image/*"
                    component={FormImageUpload}
                  />
                </GridLayoutItem>
                <GridLayoutItem style={{ marginTop: 47 }}>
                  <Button
                    style={{ padding: 10 }}
                    type="button"
                    fillMode={"solid"}
                    themeColor={"primary"}
                    disabled={
                      typeof fieldArrayRenderProps?.value[index]?.image_name !==
                        "object" ||
                      fieldArrayRenderProps?.value[index]?.image_name?.length <
                        1
                    }
                    onClick={() =>
                      fieldArrayRenderProps?.value[index]?.image_guid
                        ? handleImageUpdate(index)
                        : handleImageInsert(index)
                    }
                  >
                    {!fieldArrayRenderProps.value[index]?.image_guid
                      ? "Save"
                      : "Update"}
                  </Button>
                  <Button
                    style={{ padding: 10, marginLeft: 10 }}
                    onClick={() => handleOpenDeleteDialog(index)}
                    disabled={fieldArrayRenderProps.value.length === 1}
                    fillMode={"solid"}
                    themeColor={"error"}
                    type="button"
                    size={"small"}
                  >
                    Delete
                  </Button>
                  <Button
                    style={{ padding: 10, marginLeft: 10 }}
                    onClick={() =>
                      handleOpenImage(
                        fieldArrayRenderProps.value[index]?.image_name
                      )
                    }
                    disabled={!fieldArrayRenderProps.value[index]?.image_guid}
                    fillMode={"solid"}
                    themeColor={"primary"}
                    type="button"
                    size={"small"}
                  >
                    View
                  </Button>
                </GridLayoutItem>
              </GridLayout>
            </Card>
          </GridLayoutItem>
        ))}
      <GridLayoutItem style={{ marginBottom: 10, textAlign: "left" }}>
        <Button
          fillMode={"solid"}
          themeColor={"primary"}
          onClick={pushElementInarray}
          type="button"
          disabled={
            !fieldArrayRenderProps.value[
              fieldArrayRenderProps?.value?.length - 1
            ]?.image_guid ||
            fieldArrayRenderProps.value?.filter(
              (e: any) => typeof e.image_name === "object"
            )?.length > 0
          }
        >
          Add Image
        </Button>
      </GridLayoutItem>
    </GridLayout>
  );
};

export default PartyImageArray;
