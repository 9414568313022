import { Button } from "@progress/kendo-react-buttons";
import { Card, GridLayout, GridLayoutItem } from "@progress/kendo-react-layout";
import React from "react";
import IconButton from "../../components/common/IconButton";
import { MdDelete } from "react-icons/md";
import { Field, FieldArrayRenderProps } from "@progress/kendo-react-form";
import { requiredValidator } from "../../components/formFields/CommonValidator";
import { closeDialog, openDialog } from "../../components/dialog/dialogSlice";
import AppDialog, { DialogCloseButton } from "../../components/dialog/Dialog";
import { Typography } from "@progress/kendo-react-common";
import { setProcessDetailDeleteIndex } from "./orderSlice";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import FormDatePicker from "../../components/formFields/FormDateField";
import FormSelectionField from "../../components/formFields/FormSelectionField";

import FormRichTextField from "../../components/formFields/FormRichTextField";
import FormNumericTextField from "../../components/formFields/FormNumericTextField";

const ProcessDetailArray = (fieldArrayRenderProps: FieldArrayRenderProps) => {
  const dispatch = useAppDispatch();
  const ProcessDeleteIndex = useAppSelector(
    (state) => state.order.ProcessDeleteIndex
  );
  const karigarList = useAppSelector((state) => state.karigar.KarigarList);
  const processList = useAppSelector((state) => state.Process.ProcessList);
  const ImageDetails = useAppSelector((state) => state.item.ImageList);
  const dialogName = useAppSelector((state) => state.dialog.dialogName);


  const handleDeleteAction = React.useCallback(
    (index: number) => {
      fieldArrayRenderProps.onRemove({ index: index });
      dispatch(closeDialog());
      dispatch(setProcessDetailDeleteIndex(-1));
    },
    [fieldArrayRenderProps]
  );

  const pushElementInarray = React.useCallback(() => {
    fieldArrayRenderProps.onPush({
      value: {
        process_id: null,
        karigar_id: null,
        image_id: null,
        given_date: "",
        receive_date: "",
        duration_days: null,
        description: "",
        estimated_weight: null,
      },
    });
  }, [fieldArrayRenderProps]);

  const handleOpenDeleteDialog = (index: any) => {
    dispatch(openDialog("orderDeleteDialog"));
    dispatch(setProcessDetailDeleteIndex(index));
  };

  return (
    <GridLayout cols={[{ width: "100%" }]}>
      {!fieldArrayRenderProps.value ? pushElementInarray() : null}
      {dialogName === "orderDeleteDialog" && (
        <AppDialog>
          <>
            <Typography.h5>{"Delete Process Data"}</Typography.h5>
            <GridLayout>
              <GridLayoutItem>
                <Typography.p>
                  Are you sure you want to delete this?
                </Typography.p>
              </GridLayoutItem>
              <GridLayoutItem
                style={{
                  display: "flex",
                  justifyContent: "end",
                }}
              >
                <DialogCloseButton themeColor="error" />
                <Button
                  fillMode={"solid"}
                  themeColor={"error"}
                  onClick={() => handleDeleteAction(ProcessDeleteIndex)}
                  type="button"
                >
                  Delete
                </Button>
              </GridLayoutItem>
            </GridLayout>
          </>
        </AppDialog>
      )}
      {fieldArrayRenderProps.value &&
        fieldArrayRenderProps.value.length > 0 &&
        fieldArrayRenderProps.value.map((process_data: any, index: number) => (
          <GridLayoutItem key={index}>
            <Card className="fieldarray-card">
              <GridLayout
                style={{ marginRight: 40 }}
                gap={{ rows: 0, cols: 10 }}
                cols={[
                  { width: "16.55%" },
                  { width: "16.55%" },
                  { width: "16.55%" },
                  { width: "16.55%" },
                  { width: "16.55%" },
                  { width: "16.55%" },
                ]}
              >
                <GridLayoutItem
                  colSpan={6}
                  style={{
                    textAlign: "right",
                  }}
                >
                  <IconButton
                    onClick={() => handleOpenDeleteDialog(index)}
                    disabled={fieldArrayRenderProps.value.length === 1}
                    themeColor={"error"}
                    size={"small"}
                    fillMode={"solid"}
                    type="button"
                    style={{ height: 24, width: 24 }}
                  >
                    <MdDelete
                      className="absolute-position"
                      style={{ fontSize: "16px" }}
                    />
                  </IconButton>
                </GridLayoutItem>
                <GridLayoutItem>
                  <Field
                    name={`processdetails.${index}.process_id`}
                    label="Process Name"
                    placeholder="Select Process"
                    component={FormSelectionField}
                    validator={requiredValidator}
                    options={processList?.map((process: any) => {
                      return {
                        value: process.ID,
                        label: process.process_name,
                      };
                    })}
                  />
                </GridLayoutItem>
                <GridLayoutItem>
                  <Field
                    name={`processdetails.${index}.karigar_id`}
                    label="Karigar Nick Name"
                    placeholder="Select Nick Name"
                    component={FormSelectionField}
                    validator={requiredValidator}
                    options={karigarList
                      ?.filter(
                        (e: any) =>
                          e.process_id ===
                          fieldArrayRenderProps?.value[index]?.process_id
                      )
                      ?.map((karigar: any) => {
                        return {
                          value: karigar.id,
                          label: karigar.karigar_nick_name,
                        };
                      })}
                  />
                </GridLayoutItem>
                <GridLayoutItem>
                  <Field
                    name={`processdetails.${index}.estimated_weight`}
                    label="Process Weight"
                    placeholder="0.00"
                    format="n3"
                    type="number"
                    component={FormNumericTextField}
                    validator={requiredValidator}
                  />
                </GridLayoutItem>
                <GridLayoutItem colSpan={3} rowSpan={2}>
                  <Field
                    name={`processdetails.${index}.description`}
                    label="Description"
                    component={FormRichTextField}
                  />
                </GridLayoutItem>
                <GridLayoutItem>
                  <Field
                    name={`processdetails.${index}.given_date`}
                    label="Approx work Start Date"
                    placeholder="day/month/year"
                    format={"dd/MM/yyyy"}
                    component={FormDatePicker}
                    validator={requiredValidator}
                  />
                </GridLayoutItem>
                <GridLayoutItem>
                  <Field
                    name={`processdetails.${index}.duration_days`}
                    label="Duration days"
                    placeholder="Duration days"
                    component={FormNumericTextField}
                    type="number"
                    validator={requiredValidator}
                  />
                </GridLayoutItem>
                <GridLayoutItem>
                  <Field
                    name={`processdetails.${index}.receive_date`}
                    label="Received Date"
                    placeholder="day/month/year"
                    format={"dd/MM/yyyy"}
                    component={FormDatePicker}
                    disabled
                    validator={requiredValidator}
                  />
                </GridLayoutItem>
                <GridLayoutItem>
                  <Field
                    name={`processdetails.${index}.image_id`}
                    label="Images"
                    placeholder="Images"
                    component={FormSelectionField}
                    options={ImageDetails?.map((image: any) => {
                      return {
                        value: image.id,
                        label: image.title,
                      };
                    })}
                    validator={requiredValidator}
                  />
                </GridLayoutItem>
              </GridLayout>
            </Card>
          </GridLayoutItem>
        ))}
      <GridLayoutItem style={{ marginBottom: 10, textAlign: "left" }}>
        <Button
          fillMode={"solid"}
          themeColor={"primary"}
          onClick={pushElementInarray}
          type="button"
        >
          Add Process Data
        </Button>
      </GridLayoutItem>
    </GridLayout>
  );
};

export default ProcessDetailArray;
