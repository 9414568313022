export const GENDER = [
  { label: "Male", value: "Male" },
  { label: "Female", value: "Female" },
];
export const STATUSARRAY = [
  { label: "Active", value: 1 },
  { label: "Inactive", value: 2 },
];
export const CREDIT_DEBIT = [
  { label: "Receivable", value: 'credit' },
  { label: "Payable", value: 'debit' },
];


export type ISelectionType = {
  value: number;
  label: string;
};

export const INSERT_OPT_TYPE = 1;
export const EDIT_OPT_TYPE = 2;
export const DELETE_OPT_TYPE = 3;
export const GET_BY_GUID_OPT_TYPE = 4;
export const GET_ALL_ACTIVE_WITH_FILTER_OPT_TYPE = 5;
export const GET_ALL_OPT_TYPE = 6;
export const ACTIVE_INACTIVE_OPT_TYPE = 7;

export const INSERT_ACCESS = "1";
export const EDIT_ACCESS = "2";
export const DELETE_ACCESS = "3";
export const PDF_DOWNLOAD_ACCESS = "4";
export const EXCEL_EXPORT_ACCESS = "5";
export const PRINT_ACCESS = "6";
