import React from "react";
import {
  Upload as KendoUpload,
  UploadOnAddEvent,
  UploadOnRemoveEvent,
} from "@progress/kendo-react-upload";
import { FieldRenderProps, FieldWrapper } from "@progress/kendo-react-form";
import { Label, Error, Hint } from "@progress/kendo-react-labels";
import { Avatar } from "@progress/kendo-react-layout";

import userAvatar from "../../assets/Images/useravatar.jpg";

const FormImageUpload = (fieldRenderProps: FieldRenderProps) => {
  const {
    valid,
    value,
    layout = "horizontal",
    justifyContent = "space-evenly",
    id,
    optional,
    label,
    hint,
    validationMessage,
    imageWidth = "90px",
    imageHeight = "90px",
    touched,
    ...others
  } = fieldRenderProps;
  const imgRef = React.useRef<HTMLImageElement | null>(null);
  const hasImage = value && value.length > 0;
  const showValidationMessage = touched && validationMessage;
  const showHint = !showValidationMessage && hint;
  const hintId = showHint ? `${id}_hint` : "";
  const errorId = showValidationMessage ? `${id}_error` : "";
  const labelId = label ? `${id}_label` : "";

  const onChangeHandler = (event: UploadOnAddEvent) => {
    fieldRenderProps.onChange({ value: event.newState });
  };
  const onRemoveHandler = (event: UploadOnRemoveEvent) => {
    fieldRenderProps.onChange({ value: event.newState });
  };

  React.useEffect(() => {
    if (hasImage && imgRef.current) {
      if (Array.isArray(value) && value.length > 0) {
        var reader = new FileReader();

        reader.onload = function (e: ProgressEvent<FileReader>) {
          const target = e.target as FileReader;
          if (target && imgRef.current) {
            imgRef.current.setAttribute("src", target.result as string);
          }
        };

        reader.readAsDataURL(value[0].getRawFile());
      } else if (typeof value === "string") {
        imgRef.current.setAttribute("src", value);
      }
    }
  }, [value, hasImage]);

  return (
    <FieldWrapper
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: `${justifyContent}`,
        flexDirection: layout === "horizontal" ? "row" : "column",
        width: "100%",
      }}
    >
      <Avatar
        style={{
          width: imageWidth,
          height: imageHeight,
          flexBasis: imageWidth,
        }}
        rounded={"full"}
        type={"image"}
      >
        {hasImage ? (
          <img
            style={{ width: imageWidth, height: imageHeight }}
            ref={imgRef}
            src={"#"}
            alt={"User Avatar"}
          />
        ) : (
          <img
            style={{ width: imageWidth, height: imageHeight }}
            src={userAvatar}
            alt="user-avatar"
          />
        )}
      </Avatar>
      <div
        className={"k-form-field-wrap"}
        style={{ width: "100%", maxWidth: "350px" }}
      >
        <Label
          id={labelId}
          editorId={id}
          editorValid={valid}
          optional={optional}
          // className="field-label"
        >
          {label}
        </Label>
        <KendoUpload
          id={id}
          autoUpload={false}
          showActionButtons={false}
          multiple={false}
          files={Array.isArray(value) ? value : []}
          onAdd={onChangeHandler}
          onRemove={onRemoveHandler}
          ariaDescribedBy={`${hintId} ${errorId}`}
          ariaLabelledBy={labelId}
          {...others}
        />
        {showHint && <Hint id={hintId}>{hint}</Hint>}
        {showValidationMessage && (
          <Error id={errorId}>{validationMessage}</Error>
        )}
      </div>
    </FieldWrapper>
  );
};

export default FormImageUpload;
