import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IPriority, IPriorityInitialState } from "../priority/priorityModel";
import {
  createPriority,
  deletePriority,
  findAllActivePriority,
  getAllPriority,
  getPriorityById,
  PriorityActiveInactive,
  updatePriority,
} from "./services/priority.services";

const initialState: IPriorityInitialState = {
  loading: false,
  formLoading: false,
  error: "",
  PriorityList: [],
  PriorityID: null,
  PriorityDetail: {
    priority: "",
    isactive: 1,
    Details: "",
  },
};

export const prioritySlice = createSlice({
  name: "proirity",
  initialState,
  reducers: {
    clearPriorityDetails: (state) => {
      state.PriorityDetail = initialState.PriorityDetail;
    },
    setPriorityID: (state, action) => {
      state.PriorityID = action.payload;
    },
    clearPriorityID: (state) => {
      state.PriorityID = null;
    },
  },
  extraReducers: (builder) => {
    /* For getAll ItemCategory */

    builder.addCase(getAllPriority.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(
      getAllPriority.fulfilled,
      (state, action: PayloadAction<IPriority[]>) => {
        state.loading = false;
        state.PriorityList = action.payload;
      }
    );
    builder.addCase(getAllPriority.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message || "Something went wrong";
      state.PriorityList = [];
    });

    /* ForfindAllActivePriority */

    builder.addCase(findAllActivePriority.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(
      findAllActivePriority.fulfilled,
      (state, action: PayloadAction<IPriority[]>) => {
        state.loading = false;
        state.PriorityList = action.payload || [];
      }
    );
    builder.addCase(findAllActivePriority.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message || "Something went wrong";
      state.PriorityList = [];
    });

    /* For createItemCategory */

    builder.addCase(createPriority.pending, (state) => {
      state.formLoading = false;
      state.error = "";
    });
    builder.addCase(createPriority.fulfilled, (state) => {
      state.formLoading = false;
    });
    builder.addCase(createPriority.rejected, (state, action) => {
      state.formLoading = false;
      state.error = action.error.message || "Something went wrong";
    });

    /* For updatePriority */

    builder.addCase(updatePriority.pending, (state) => {
      state.formLoading = true;
      state.error = "";
    });
    builder.addCase(updatePriority.fulfilled, (state) => {
      state.formLoading = false;
    });
    builder.addCase(updatePriority.rejected, (state, action) => {
      state.formLoading = false;
      state.error = action.error.message || "Something went wrong";
    });

    /* For deletePriority */

    builder.addCase(deletePriority.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(deletePriority.fulfilled, (state) => {
      state.loading = false;
    });
    builder.addCase(deletePriority.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message || "Something went wrong";
    });

    /* For PriorityActiveInactive */

    builder.addCase(PriorityActiveInactive.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(PriorityActiveInactive.fulfilled, (state) => {
      state.loading = false;
    });
    builder.addCase(PriorityActiveInactive.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message || "Something went wrong";
    });

    /* For getPriorityById */

    builder.addCase(getPriorityById.pending, (state) => {
      state.formLoading = true;
      state.error = "";
    });
    builder.addCase(getPriorityById.fulfilled, (state, action: any) => {
      state.formLoading = false;
      state.PriorityDetail = action.payload;
    });
    builder.addCase(getPriorityById.rejected, (state, action) => {
      state.formLoading = false;
      state.PriorityDetail = initialState.PriorityDetail;
      state.error = action.error.message || "Something went wrong";
    });
  },
});

export const { clearPriorityDetails, clearPriorityID, setPriorityID } =
  prioritySlice.actions;
export default prioritySlice.reducer;
