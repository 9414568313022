import { Button } from "@progress/kendo-react-buttons";
import { Card, GridLayout, GridLayoutItem } from "@progress/kendo-react-layout";
import React, { useEffect } from "react";
import {
  Field,
  FieldArrayRenderProps,
  FormRenderProps,
} from "@progress/kendo-react-form";
import { requiredValidator } from "../../components/formFields/CommonValidator";
import FormTextField from "../../components/formFields/FormTextField";
import { closeDialog, openDialog } from "../../components/dialog/dialogSlice";
import AppDialog, { DialogCloseButton } from "../../components/dialog/Dialog";
import { Typography } from "@progress/kendo-react-common";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { setGhatItemIssueDeleteIndex } from "./ghatSlice";
import FormNumericTextField from "../../components/formFields/FormNumericTextField";
import { MdDelete } from "react-icons/md";
import IconButton from "../../components/common/IconButton";
import { deleteGhadiaIssueItems } from "./services/ghat.services";
import { ErrorToast, SuccessToast } from "../../components/toast/Toasts";

interface ChangeTotalPhineWatchers {
  formRenderProps: FormRenderProps;
  index: number;
}

const ChangeTotalPhineWatcher: React.FC<ChangeTotalPhineWatchers> = ({
  formRenderProps,
  index,
}) => {
  const given_weight = formRenderProps.valueGetter(
    `ghat_item_issue.${index}.given_weight`
  );
  const given_purity = formRenderProps.valueGetter(
    `ghat_item_issue.${index}.given_purity`
  );

  useEffect(() => {
    const GivenWeight = given_weight ? +given_weight : 0;
    const GivenPurity = given_purity ? +given_purity : 0;
    let phine = (GivenWeight * GivenPurity) / 100;

    if (GivenWeight && GivenPurity) {
      formRenderProps.onChange(`ghat_item_issue.${index}.phine`, {
        value: phine.toFixed(2),
      });
    }
  }, [given_weight, given_purity]);

  return null;
};

const GhatIssueDetailArray = (fieldArrayRenderProps: FieldArrayRenderProps) => {
  const dispatch = useAppDispatch();
  const GhatDetailIssueDeleteIndex = useAppSelector(
    (state) => state.ghat.GhatItemsIssueDeleteIndex
  );
  const name = fieldArrayRenderProps?.name;
  const dialogName = useAppSelector((state) => state.dialog.dialogName);
  const handleDeleteAction = React.useCallback(
    async (index: number) => {
      if (fieldArrayRenderProps?.value[index].id) {
        try {
          const response = await dispatch(
            deleteGhadiaIssueItems(fieldArrayRenderProps?.value[index].id)
          );
          if (response.meta.requestStatus === "fulfilled") {
            SuccessToast(response.payload.message || "Success");
            fieldArrayRenderProps.onRemove({ index: index });
            dispatch(closeDialog());
            dispatch(setGhatItemIssueDeleteIndex(-1));
          }
        } catch (error: any) {
          ErrorToast(error?.response?.data?.Details || "Something went wrong");
          console.error("Error deleting Item Details:", error);
          return Promise.reject({
            message: error?.response?.data?.Details || "Something went wrong",
          });
        }
      } else {
        fieldArrayRenderProps.onRemove({ index: index });
        dispatch(closeDialog());
        dispatch(setGhatItemIssueDeleteIndex(-1));
      }
    },
    [fieldArrayRenderProps]
  );
  const pushElementInarray = React.useCallback(() => {
    fieldArrayRenderProps?.onPush({
      value: {
        given_weight: null,
        given_purity: 100,
        phine: null,
        amount: null,
      },
    });
  }, [fieldArrayRenderProps]);

  const handleOpenDeleteDialog = (index: any) => {
    dispatch(openDialog("ghatDetailIssueDialog"));
    dispatch(setGhatItemIssueDeleteIndex(index));
  };

  // console.log("first", fieldArrayRenderProps?.value);

  return (
    <GridLayout cols={[{ width: "100%" }]}>
      {/* {fieldArrayRenderProps?.value?.length <= 0 ? pushElementInarray() : null} */}
      {dialogName === "ghatDetailIssueDialog" && (
        <AppDialog>
          <>
            <Typography.h5>{"Delete Product Issue Detail Data"}</Typography.h5>
            <GridLayout>
              <GridLayoutItem>
                <Typography.p>
                  Are you sure you want to delete this?
                </Typography.p>
              </GridLayoutItem>
              <GridLayoutItem
                style={{
                  display: "flex",
                  justifyContent: "end",
                }}
              >
                <DialogCloseButton themeColor="error" />
                <Button
                  fillMode={"solid"}
                  themeColor={"error"}
                  onClick={() => handleDeleteAction(GhatDetailIssueDeleteIndex)}
                  type="button"
                >
                  Delete
                </Button>
              </GridLayoutItem>
            </GridLayout>
          </>
        </AppDialog>
      )}
      {fieldArrayRenderProps.value &&
        fieldArrayRenderProps.value.length > 0 &&
        fieldArrayRenderProps.value.map((process_data: any, index: number) => (
          <GridLayoutItem key={index}>
            <Card className="fieldarray-card">
              <GridLayout
                style={{ marginRight: 80 }}
                gap={{ rows: 0, cols: 10 }}
                cols={[
                  { width: "24.7%" },
                  { width: "24.7%" },
                  { width: "24.7%" },
                  { width: "24.7%" },
                  { width: "5%" },
                ]}
              >
                <GridLayoutItem>
                  <Field
                    name={`${name}.${index}.given_weight`}
                    label="Given Weight"
                    placeholder="0.00"
                    type="number"
                    format="n3"
                    component={FormNumericTextField}
                    validator={requiredValidator}
                  />
                </GridLayoutItem>
                <GridLayoutItem>
                  <Field
                    name={`${name}.${index}.given_purity`}
                    label="Given purity"
                    placeholder="0"
                    type="number"
                    component={FormTextField}
                    validator={requiredValidator}
                  />
                </GridLayoutItem>
                <GridLayoutItem>
                  <Field
                    name={`${name}.${index}.phine`}
                    label="Phine"
                    placeholder="Phine"
                    type="number"
                    disabled
                    defaultValue={0}
                    component={FormTextField}
                    validator={requiredValidator}
                  />
                </GridLayoutItem>
                <GridLayoutItem>
                  <Field
                    name={`${name}.${index}.amount`}
                    label="Amount"
                    placeholder="0"
                    type="number"
                    component={FormTextField}
                    validator={requiredValidator}
                  />
                </GridLayoutItem>
                <GridLayoutItem
                  style={{
                    marginTop: 42,
                  }}
                >
                  <IconButton
                    onClick={() => handleOpenDeleteDialog(index)}
                    disabled={fieldArrayRenderProps.value.length === 1}
                    themeColor={"error"}
                    size={"small"}
                    fillMode={"solid"}
                    type="button"
                    style={{ height: 24, width: 24 }}
                  >
                    <MdDelete
                      className="absolute-position"
                      style={{ fontSize: "16px" }}
                    />
                  </IconButton>
                </GridLayoutItem>
              </GridLayout>
            </Card>
            <ChangeTotalPhineWatcher
              formRenderProps={fieldArrayRenderProps.formRenderProps}
              index={index}
            />
          </GridLayoutItem>
        ))}
      <GridLayoutItem style={{ marginBottom: 10, textAlign: "left" }}>
        <Button
          fillMode={"solid"}
          themeColor={"primary"}
          onClick={pushElementInarray}
          type="button"
        >
          Add Issue
        </Button>
      </GridLayoutItem>
    </GridLayout>
  );
};

export default GhatIssueDetailArray;
