import { Routes, Route } from "react-router-dom";
import Layout from "../components/layout/Layout";
import LogIn from "../features/login/Login";
import Country from "../features/country/Country";
import State from "../features/state/State";
import City from "../features/city/City";
import ItemCategory from "../features/itemcategory/ItemCategory";
import Item from "../features/item/Item";
import CreateItem from "../features/item/CreateItem";
import Process from "../features/process/Process";
import Priority from "../features/priority/Priority";
import Party from "../features/party/Party";
import CreateParty from "../features/party/CreateParty";
import Karigar from "../features/karigar/Karigar";
import CreateKarigar from "../features/karigar/CreateKarigar";
import Order from "../features/order/Order";
import CreateOrder from "../features/order/CreateOrder";
import Ghat from "../features/ghat/Ghat";
import CreateGhat from "../features/ghat/CreateGhat";
import CreateGhatReceipt from "../features/ghatreceipt/CreateGhatReceipt";
import GhatReceipt from "../features/ghatreceipt/GhatReceipt";
import ProductSubCategory from "../features/product subcategory/ProductSubCategory";
import Purity from "../features/purity/Purity";
import Unit from "../features/unit/Unit";
import Charges from "../features/charges/Charges";
import User from "../features/user/User";
import CreateUser from "../features/user/CreateUser";
import UserType from "../features/userType/UserType";
import CreateUserType from "../features/userType/CreateUserType";
import Menu from "../features/menu/Menu";
import CreateMenu from "../features/menu/CreateMenu";
import Rights from "../features/rights/Rights";
import CreateRights from "../features/rights/CreateRights";

const MainRouter = () => {
  return (
    <Routes>
      <Route path="login" element={<LogIn />} />
      <Route path="/" element={<Layout />}>
        <Route path="country" element={<Country />} />
        <Route path="state" element={<State />} />
        <Route path="city" element={<City />} />
        <Route path="itemcategory" element={<ItemCategory />} />
        <Route path="item" element={<Item />} />
        <Route path="item/create" element={<CreateItem />} />
        <Route path="item/edit" element={<CreateItem />} />
        <Route path="process" element={<Process />} />
        <Route path="priority" element={<Priority />} />
        <Route path="party" element={<Party />} />
        <Route path="party/create" element={<CreateParty />} />
        <Route path="party/edit" element={<CreateParty />} />
        <Route path="user" element={<User />} />
        <Route path="user/create" element={<CreateUser />} />
        <Route path="user/edit" element={<CreateUser />} />
        <Route path="usertype" element={<UserType />} />
        <Route path="usertype/create" element={<CreateUserType />} />
        <Route path="usertype/edit" element={<CreateUserType />} />
        <Route path="menu" element={<Menu />} />
        <Route path="menu/create" element={<CreateMenu />} />
        <Route path="menu/edit" element={<CreateMenu />} />
        <Route path="rights" element={<Rights />} />
        <Route path="rights/create" element={<CreateRights />} />
        <Route path="rights/edit" element={<CreateRights />} />

        <Route path="karigar" element={<Karigar />} />
        <Route path="karigar/create" element={<CreateKarigar />} />
        <Route path="karigar/edit" element={<CreateKarigar />} />
        <Route path="order" element={<Order />} />
        <Route path="order/create" element={<CreateOrder />} />
        <Route path="order/edit" element={<CreateOrder />} />
        <Route path="ghatissue" element={<Ghat />} />
        <Route path="ghatissue/create" element={<CreateGhat />} />
        <Route path="ghatissue/edit" element={<CreateGhat />} />
        <Route path="ghatissuereceipt" element={<GhatReceipt />} />
        <Route path="ghatissuereceipt/edit" element={<CreateGhatReceipt />} />
        <Route path="ghatissuereceipt/create" element={<CreateGhatReceipt />} />
        <Route path="productsubcategory" element={<ProductSubCategory />} />
        <Route path="purity" element={<Purity />} />
        <Route path="unit" element={<Unit />} />
        <Route path="charges" element={<Charges />} />
        <Route path="*" element={<h1>404</h1>} />
      </Route>
    </Routes>
  );
};

export default MainRouter;
