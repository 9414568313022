import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IUnit, IUnitInitilState } from "./unitModel";
import { createUnit, deleteUnit, findAllActiveUnit, getAllUnit, getUnitById, UnitActiveInactive, updateUnit } from "./services/unit.services";


const initialState: IUnitInitilState = {
  loading: false,
  formLoading: false,
  error: "",
  UnitList: [],
  UnitID: null,
  UnitDetail: {
    unit_name: "",
    short_code: "",
    isactive: 1,
  },
};

export const unitSlice = createSlice({
  name: "unit",
  initialState,
  reducers: {
    clearUnitDetails: (state) => {
      state.UnitDetail = initialState.UnitDetail;
    },
    setUnitID: (state, action) => {
      state.UnitID = action.payload;
    },
    clearUnitID: (state) => {
      state.UnitID = null;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getAllUnit.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(
      getAllUnit.fulfilled,
      (state, action: PayloadAction<IUnit[]>) => {
        state.loading = false;
        state.UnitList = action.payload;
      }
    );
    builder.addCase(getAllUnit.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message || "Something went wrong";
      state.UnitList = [];
    });

    builder.addCase(findAllActiveUnit.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(
      findAllActiveUnit.fulfilled,
      (state, action: PayloadAction<IUnit[]>) => {
        state.loading = false;
        state.UnitList = action.payload || [];
      }
    );
    builder.addCase(findAllActiveUnit.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message || "Something went wrong";
      state.UnitList = [];
    });

    builder.addCase(createUnit.pending, (state) => {
      state.formLoading = false;
      state.error = "";
    });
    builder.addCase(createUnit.fulfilled, (state) => {
      state.formLoading = false;
    });
    builder.addCase(createUnit.rejected, (state, action) => {
      state.formLoading = false;
      state.error = action.error.message || "Something went wrong";
    });

    builder.addCase(updateUnit.pending, (state) => {
      state.formLoading = true;
      state.error = "";
    });
    builder.addCase(updateUnit.fulfilled, (state) => {
      state.formLoading = false;
    });
    builder.addCase(updateUnit.rejected, (state, action) => {
      state.formLoading = false;
      state.error = action.error.message || "Something went wrong";
    });

    builder.addCase(deleteUnit.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(deleteUnit.fulfilled, (state) => {
      state.loading = false;
    });
    builder.addCase(deleteUnit.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message || "Something went wrong";
    });

    builder.addCase(UnitActiveInactive.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(UnitActiveInactive.fulfilled, (state) => {
      state.loading = false;
    });
    builder.addCase(UnitActiveInactive.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message || "Something went wrong";
    });

    builder.addCase(getUnitById.pending, (state) => {
      state.formLoading = true;
      state.error = "";
    });
    builder.addCase(getUnitById.fulfilled, (state, action: any) => {
      state.formLoading = false;
      state.UnitDetail = action.payload;
    });
    builder.addCase(getUnitById.rejected, (state, action) => {
      state.formLoading = false;
      state.UnitDetail = initialState.UnitDetail;
      state.error = action.error.message || "Something went wrong";
    });
  },
});

export const { clearUnitDetails, setUnitID, clearUnitID } =
  unitSlice.actions;
export default unitSlice.reducer;
