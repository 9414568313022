import { createAsyncThunk } from "@reduxjs/toolkit";
import { API_URL } from "../../../app/config";
import { axiosApiInstance } from "../../../app/axios";
import { ErrorToast, SuccessToast } from "../../../components/toast/Toasts";
import { IItemCategory } from "../itemCategoryModel";

export const getAllItemCategory = createAsyncThunk(
  "ItemCategory/FindAllItemCategory",
  async () => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/ItemCategory/FindAllItemCategory`,
        {
          itemCategory: "",
        }
      );
      return response.data?.Data;
    } catch (error: any) {
      console.error("Error Fetching ItemCategory:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const createItemCategory = createAsyncThunk(
  "ItemCategory/InsertItemCategory",
  async (itemCategory: any) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/ItemCategory/InsertItemCategory`,
        itemCategory
      );
      SuccessToast(response.data?.Details || "Success");
      return response.data;
    } catch (error: any) {
      ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error Fetching ItemCategory:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const updateItemCategory = createAsyncThunk(
  "ItemCategory/UpdateItemCategory",
  async (itemCategory: any) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/ItemCategory/UpdateItemCategory`,
        itemCategory
      );
      SuccessToast(response.data?.Details || "Success");
      return response.data;
    } catch (error: any) {
      ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error Fetching ItemCategory:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const getItemCategoryById = createAsyncThunk(
  "ItemCategory/FindByIDItemCategory",
  async (ID: any) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/ItemCategory/FindByIDItemCategory`,
        {
          category_guid: ID,
        }
      );
      // SuccessToast(response.data?.Details || "Success")
      const result = response.data?.Data as any;
      return {
        ID: result.ID,
        category_name: result.category_name,
        isactive: result.isactive === true ? 1 : 2,
      } as IItemCategory;
    } catch (error: any) {
      ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error Fetching ItemCategory:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const deleteItemCategory = createAsyncThunk(
  "ItemCategory/DeleteItemCategory",
  async (ID: number) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/ItemCategory/DeleteItemCategory`,
        {
          category_guid: ID,
        }
      );
      SuccessToast(response.data?.Details || "Success");
      return response.data;
    } catch (error: any) {
      ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error Deleting ItemCategory:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const findAllActiveItemCategory = createAsyncThunk(
  "ItemCategory/FindAllActiveItemCategory",
  async () => {
    try {
      const response = await axiosApiInstance.get(
        `${API_URL}/ItemCategory/FindAllActiveItemCategory`
      );
      // SuccessToast(response.data?.Details || "Success")
      return response.data.Data;
    } catch (error: any) {
      console.error("Error Deleting ItemCategory:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const ItemCategoryActiveInactive = createAsyncThunk(
  "ItemCategory/ActiveInActiveItemCategory",
  async (formData: any) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/ItemCategory/ActiveInActiveItemCategory`,
        formData
      );
      SuccessToast(response.data?.Details || "Success");
      return response.data;
    } catch (error: any) {
      ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error active-inactive ItemCategory:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);
