import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ICharges, IChargesInitialState } from "./chargesModel";
import { ChargesActiveInactive, createCharges, deleteCharges, findAllActiveCharges, getAllCharges, getChargesById, updateCharges } from "./services/charges.services";

const initialState: IChargesInitialState = {
  loading: false,
  formLoading: false,
  error: "",
  ChargesList: [],
  ChargesID: null,
  ChargesDetail: {
      charges_name: "",
      isactive: 1
  },
};

export const chargesSlice = createSlice({
  name: "charges",
  initialState,
  reducers: {
    clearChargesDetails: (state) => {
      state.ChargesDetail = initialState.ChargesDetail;
    },
    setChargesID: (state, action) => {
      state.ChargesID = action.payload;
    },
    clearChargesID: (state) => {
      state.ChargesID = null;
    },
  },
  extraReducers: (builder) => {
    /* For getAll ItemCharges */

    builder.addCase(getAllCharges.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(
      getAllCharges.fulfilled,
      (state, action: PayloadAction<ICharges[]>) => {
        state.loading = false;
        state.ChargesList = action.payload;
      }
    );
    builder.addCase(getAllCharges.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message || "Something went wrong";
      state.ChargesList = [];
    });

    /* ForfindAllActiveCharges */

    builder.addCase(findAllActiveCharges.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(
      findAllActiveCharges.fulfilled,
      (state, action: PayloadAction<ICharges[]>) => {
        state.loading = false;
        state.ChargesList = action.payload || [];
      }
    );
    builder.addCase(findAllActiveCharges.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message || "Something went wrong";
      state.ChargesList = [];
    });

    /* For createItemCharges */

    builder.addCase(createCharges.pending, (state) => {
      state.formLoading = false;
      state.error = "";
    });
    builder.addCase(createCharges.fulfilled, (state) => {
      state.formLoading = false;
    });
    builder.addCase(createCharges.rejected, (state, action) => {
      state.formLoading = false;
      state.error = action.error.message || "Something went wrong";
    });

    /* For updateCharges */

    builder.addCase(updateCharges.pending, (state) => {
      state.formLoading = true;
      state.error = "";
    });
    builder.addCase(updateCharges.fulfilled, (state) => {
      state.formLoading = false;
    });
    builder.addCase(updateCharges.rejected, (state, action) => {
      state.formLoading = false;
      state.error = action.error.message || "Something went wrong";
    });

    /* For deleteCharges */

    builder.addCase(deleteCharges.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(deleteCharges.fulfilled, (state) => {
      state.loading = false;
    });
    builder.addCase(deleteCharges.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message || "Something went wrong";
    });

    /* For ChargesActiveInactive */

    builder.addCase(ChargesActiveInactive.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(ChargesActiveInactive.fulfilled, (state) => {
      state.loading = false;
    });
    builder.addCase(ChargesActiveInactive.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message || "Something went wrong";
    });

    /* For getChargesById */

    builder.addCase(getChargesById.pending, (state) => {
      state.formLoading = true;
      state.error = "";
    });
    builder.addCase(getChargesById.fulfilled, (state, action: any) => {
      state.formLoading = false;
      state.ChargesDetail = action.payload;
    });
    builder.addCase(getChargesById.rejected, (state, action) => {
      state.formLoading = false;
      state.ChargesDetail = initialState.ChargesDetail;
      state.error = action.error.message || "Something went wrong";
    });
  },
});

export const { clearChargesDetails, clearChargesID, setChargesID } =
  chargesSlice.actions;
export default chargesSlice.reducer;
