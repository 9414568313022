import { createAsyncThunk } from "@reduxjs/toolkit";
import { API_URL } from "../../../app/config";
import { axiosApiInstance } from "../../../app/axios";
import { ErrorToast, SuccessToast } from "../../../components/toast/Toasts";
import { IOrder, IPendingOrder } from "../orderModel";
import moment from "moment";

export const createOrder = createAsyncThunk(
  "Order/InsertOrder",
  async (order: any) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/Order/InsertOrder`,
        order
      );
      SuccessToast(response.data?.Details || "Success");
      return response.data;
    } catch (error: any) {
      ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error Fetching Order:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const updateOrder = createAsyncThunk(
  "Order/UpdateOrder",
  async (order: any) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/Order/UpdateOrder`,
        order
      );
      SuccessToast(response.data?.Details || "Success");
      return response.data;
    } catch (error: any) {
      ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error Fetching Order:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const deleteOrder = createAsyncThunk(
  "Order/DeleteOrder",
  async (ID: number) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/Order/DeleteOrder`,
        { order_guid: ID }
      );
      SuccessToast(response.data?.Details || "Success");
      return response.data;
    } catch (error: any) {
      ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error Deleting Order:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const findAllOrder = createAsyncThunk(
  "Order/FindAllOrder",
  async (formData: any) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/Order/FindAllOrder`,
        formData
      );
      // SuccessToast(response.data?.Details || "Success")
      return response.data.Data;
    } catch (error: any) {
      console.error("Error Deleting Order:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const findAllPendingOrder = createAsyncThunk(
  "Order/FindAllPendingOrder",
  async (ID: any) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/Order/FindAllPendingOrder`,
        { karigar_id: ID }
      );
      const result = response?.data?.Data;
      const pendingOrders = result.map((order: any) => {
        return {
          order_no: order.order_no,
          karigar_id: order.karigar_id,
          isreceived: order.isreceived,
        } as IPendingOrder;
      });
      return pendingOrders;
    } catch (error: any) {
      console.error("Error Pending Order No:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const getOrderById = createAsyncThunk(
  "Order/FindByIDOrder",
  async (ID: any) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/Order/FindByIDOrder`,
        { order_guid: ID }
      );
      // SuccessToast(response.data?.Details || "Success");
      const result = response.data?.Data as any;
      return {
        id: result.id,
        order_date: result.order_date
          ? moment(result.order_date, "DD/MM/YYYY").toDate()
          : "",
        delivery_date: result.delivery_date
          ? moment(result.delivery_date, "DD/MM/YYYY").toDate()
          : "",
        party_id: result.party_id,
        order_no: result.order_no,
        item_code_id: result.item_code_id,
        item_name:result.item_name,
        description: result.description,
        estimate_weight: result.estimate_weight,
        item_id: result.item_id,
        purity: result.purity,
        priority_id: result.priority_id,
        color: result.color,
        estimate_amount: result.estimate_amount,
        remark: result.remark,
        size: result.size,
        // isactive: true,
        processdetails: result.processdetails
          ? result.processdetails.map((results: any) => {
              return {
                // DayNo: index + 1,
                order_id: results.order_id,
                process_id: results.process_id,
                karigar_id: results.karigar_id,
                image_id: results.image_id,
                description: results.description,
                estimated_weight: results.estimated_weight,
                given_date: results.given_date
                  ? moment(results.given_date).toDate()
                  : "",
                duration_days: results.duration_days,
                receive_date: results.receive_date
                  ? moment(results.receive_date).toDate()
                  : "",
              };
            })
          : [],
        sizedetails: result.sizedetails
          ? result.sizedetails.map((results: any) => {
              return {
                title: results.title,
                value: results.value,
              };
            })
          : [],
      } as IOrder;
    } catch (error: any) {
      ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error Fetching Order:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const findbyOrderNo = createAsyncThunk(
  "Order/FindByOrderNo",
  async (ID: any) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/Order/FindByOrderNo`,
        { order_no: ID }
      );
      // SuccessToast(response.data?.Details || "Success");
      const result = response.data?.Data as any;
      return {
        id: result.id,
        order_no: result.order_no,
        order_guid: result.order_guid,
        item_id: result.item_id,
        item_name: result.item_name,
        description: result.description,
        item_category_id: result.item_category_id,
        order_date: result.order_date ? moment(result.order_date) : "",
        party_id: result.party_id,
        party_name: result.party_name,
        delivery_date: result.delivery_date ? moment(result.delivery_date) : "",
        item_code_id: result.item_code_id,
        priority_id: result.priority_id,
        priority: result.priority,
        process_id: result.process_id,
        weight: result.estimated_weight,
        purity: result.purity,
        purity_name:result.purity_name,
        color: result.color,
        estimate_amount: result.estimate_amount,
        size: result.size,
        isactive: 1,
        Images: result.Images
          ? result.Images.map((img: any) => {
              return {
                image_name: img.image_name,
                image_guid: img.image_guid,
                isreadyimage: img.isreadyimage,
                item_id: img.item_id,
                title: img.title,
              };
            })
          : [],
      } as any;
    } catch (error: any) {
      ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error Fetching Order:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);
