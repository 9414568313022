import { createAsyncThunk } from "@reduxjs/toolkit";
import { API_URL } from "../../../app/config";
import { axiosApiInstance } from "../../../app/axios";
import { ErrorToast, SuccessToast } from "../../../components/toast/Toasts";
import { IProcess } from "../processModel";


export const getAllProcess = createAsyncThunk(
    "Process/FindAllProcess",
    async () => {
        try {
            const response = await axiosApiInstance.post(
                `${API_URL}/Process/FindAllProcess`,
                {
                    processData: "",
                }
            );
            return response.data?.Data;
        } catch (error: any) {
            console.error("Error Fetching Process:", error);
            return Promise.reject({
                message: error?.response?.data?.Details || "Something went wrong",
            });
        }
    }
);


export const createProcess = createAsyncThunk(
    "Process/InsertProcess", async (processData: any) => {
        try {
            const response = await axiosApiInstance.post(`${API_URL}/Process/InsertProcess`, processData)
            SuccessToast(response.data?.Details || "Success");
            return response.data;
        } catch (error: any) {
            ErrorToast(error?.response?.data?.Details || "Something went wrong");
            console.error("Error Fetching Process:", error);
            return Promise.reject({
                message: error?.response?.data?.Details || "Something went wrong",
            });
        }
    }
)

export const updateProcess = createAsyncThunk(
    "Process/UpdateProcess", async (processData: any) => {
        try {
            const response = await axiosApiInstance.post(`${API_URL}/Process/UpdateProcess`, processData)
            SuccessToast(response.data?.Details || "Success")
            return response.data
        } catch (error: any) {
            ErrorToast(error?.response?.data?.Details || "Something went wrong");
            console.error("Error Fetching Process:", error);
            return Promise.reject({
                message: error?.response?.data?.Details || "Something went wrong",
            })
        }
    }
)

export const getProcessById = createAsyncThunk(
    "Process/FindByIDProcess", async (ID: any) => {
        try {
            const response = await axiosApiInstance.post(`${API_URL}/Process/FindByIDProcess`, {
                process_guid:ID
            })
            // SuccessToast(response.data?.Details || "Success")
            const result = response.data?.Data as any;
            return {
                process_guid: result?.process_guid,
                process_name: result?.process_name,
                process_sr_number: result?.process_sr_number,
                isactive: result?.isactive === true ? 1 : 2,
            } as IProcess;
        } catch (error: any) {
            ErrorToast(error?.response?.data?.Details || "Something went wrong");
            console.error("Error Fetching Process:", error);
            return Promise.reject({
                message: error?.response?.data?.Details || "Something went wrong",
            })
        }
    }
)

export const deleteProcess = createAsyncThunk(
    "Process/DeleteProcess", async (ID: number) => {
        try {
            const response = await axiosApiInstance.post(`${API_URL}/Process/DeleteProcess`, {
                process_guid: ID
            });
            SuccessToast(response.data?.Details || "Success")
            return response.data
        } catch (error: any) {
            ErrorToast(error?.response?.data?.Details || "Something went wrong");
            console.error("Error Deleting Process:", error);
            return Promise.reject({
                message: error?.response?.data?.Details || "Something went wrong",
            })
        }
    }
)

export const findAllActiveProcess = createAsyncThunk(
    "Process/FindAllActiveProcess", async () => {
        try {
            const response = await axiosApiInstance.get(`${API_URL}/Process/FindAllActiveProcess`)
            // SuccessToast(response.data?.Details || "Success")
            return response.data.Data
        } catch (error: any) {
            console.error("Error Deleting Process:", error);
            return Promise.reject({
                message: error?.response?.data?.Details || "Something went wrong",
            })
        }
    }
)

export const ProcessActiveInactive = createAsyncThunk(
    "Process/ActiveInActiveProcess",
    async (formData: any) => {
        try {
            const response = await axiosApiInstance.post(
                `${API_URL}/Process/ActiveInActiveProcess`,
                formData
            );
            SuccessToast(response.data?.Details || "Success");
            return response.data;
        } catch (error: any) {
            ErrorToast(error?.response?.data?.Details || "Something went wrong");
            console.error("Error active-inactive Process:", error);
            return Promise.reject({
                message: error?.response?.data?.Details || "Something went wrong",
            });
        }
    }
);
