import { createAsyncThunk } from "@reduxjs/toolkit";
import { ErrorToast, SuccessToast } from "../../../components/toast/Toasts";
import { IUnit } from "../unitModel";
import { API_URL } from "../../../app/config";
import { axiosApiInstance } from "../../../app/axios";

export const getAllUnit = createAsyncThunk("Unit/FindAllUnit", async () => {
  try {
    const response = await axiosApiInstance.post(
      `${API_URL}/Unit/FindAllUnit`,
      {
        unit_name: "",
        short_code: "",
      }
    );
    return response.data?.Data;
  } catch (error: any) {
    console.error("Error Fetching Unit:", error);
    return Promise.reject({
      message: error?.response?.data?.Details || "Something went wrong",
    });
  }
});

export const createUnit = createAsyncThunk(
  "Unit/InsertUnit",
  async (Unit: any) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/Unit/InsertUnit`,
        Unit
      );
      SuccessToast(response.data?.Details || "Success");
      return response.data;
    } catch (error: any) {
      ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error Fetching unit:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const updateUnit = createAsyncThunk(
  "Unit/UpdateUnit",
  async (Unit: any) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/Unit/UpdateUnit`,
        Unit
      );
      SuccessToast(response.data?.Details || "Success");
      return response.data;
    } catch (error: any) {
      ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error Fetching unit:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const getUnitById = createAsyncThunk(
  "Unit/FindByIDUnit",
  async (ID: any) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/Unit/FindByIDUnit`,
        {
          unit_guid: ID,
        }
      );
      // SuccessToast(response.data?.Details || "Success")
      const result = response.data?.Data as any;
      return {
        id: result.id,
        unit_guid: result.unit_guid,
        short_code: result.short_code,
        unit_name: result.unit_name,
        isactive: result.isactive === true ? 1 : 2,
      } as IUnit;
    } catch (error: any) {
      ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error Fetching unit:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const deleteUnit = createAsyncThunk(
  "Unit/DeleteUnit",
  async (ID: number) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/Unit/DeleteUnit`,
        {
          unit_guid: ID,
        }
      );
      SuccessToast(response.data?.Details || "Success");
      return response.data;
    } catch (error: any) {
      ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error Deleting unit:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const findAllActiveUnit = createAsyncThunk(
  "Unit/FindAllActiveUnit",
  async () => {
    try {
      const response = await axiosApiInstance.get(
        `${API_URL}/Unit/FindAllActiveUnit`
      );
      // SuccessToast(response.data?.Details || "Success")
      return response.data.Data;
    } catch (error: any) {
      console.error("Error Deleting unit:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const UnitActiveInactive = createAsyncThunk(
  "Unit/ActiveInActiveUnit",
  async (formData: any) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/Unit/ActiveInActiveUnit`,
        formData
      );
      SuccessToast(response.data?.Details || "Success");
      return response.data;
    } catch (error: any) {
      ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error active-inactive unit:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);
