import { createAsyncThunk } from "@reduxjs/toolkit";
import { API_URL } from "../../../app/config";
import { axiosApiInstance } from "../../../app/axios";
import { ErrorToast, SuccessToast } from "../../../components/toast/Toasts";
import { IGhatReceipt } from "../ghatReceiptModel";
import moment from "moment";

export const createGhatReceipt = createAsyncThunk(
  "GhatReceipt/InsertGhatReceipt",
  async (ghatReceipt: any) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/GhatReceipt/InsertGhatReceipt`,
        ghatReceipt
      );
      SuccessToast(response.data?.Details || "Success");
      return response.data;
    } catch (error: any) {
      ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error Fetching GhatReceipt:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const updateGhatReceipt = createAsyncThunk(
  "GhatReceipt/UpdateGhatReceipt",
  async (ghatReceipt: any) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/GhatReceipt/UpdateGhatReceipt`,
        ghatReceipt
      );
      SuccessToast(response.data?.Details || "Success");
      return response.data;
    } catch (error: any) {
      ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error Fetching Ghat Redceipt:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const findAllGhatReceipt = createAsyncThunk(
  "GhatReceipt/FindAllGhatReceipt",
  async (formData: any) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/GhatReceipt/FindAllGhatReceipt`,
        formData
      );
      // SuccessToast(response.data?.Details || "Success")
      return response.data.Data;
    } catch (error: any) {
      console.error("Error Fatching GhatReceipt:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const deleteGhatReceipt = createAsyncThunk(
  "GhatReceipt/DeleteGhatReceipt",
  async (ID: number) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/GhatReceipt/DeleteGhatReceipt`,
        { ghat_receipt_guid: ID }
      );
      SuccessToast(response.data?.Details || "Success");
      return response.data;
    } catch (error: any) {
      ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error Delete Ghat Receipt:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const getGhatReceiptById = createAsyncThunk(
  "GhatReceipt/FindByIDGhatReceipt",
  async (ID: any) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/GhatReceipt/FindByIDGhatReceipt`,
        { ghat_receipt_guid: ID }
      );
      // SuccessToast(response.data?.Details || "Success");
      const result = response.data?.Data as any;
      return {
        id: result.id,
        ghat_receipt_guid: result.ghat_receipt_guid,
        karigar_id: result.karigar_id,
        receipt_date: result.receipt_date
          ? moment(result.receipt_date).toDate()
          : "",
        order_no: result.order_no,
        item_id: result.item_id,
        item_description: result.item_description,
        receiver_weight: result.receiver_weight,
        item_name: result.item_name,
        purity: result.purity,
        remark: result.remark,
        phine: result.phine,
        amount: result.amount,
        process_id: result.process_id,
        wastage: result.wastage,
        issize: result.issize,
        isweight: result.isweight,
        isdesign: result.isdesign,
        isnaka: result.isnaka,
        ispurity: result.ispurity,
        iscolor: result.iscolor,
        isactive: result.isactive === true ? 1 : 2,
      } as IGhatReceipt;
    } catch (error: any) {
      ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error Fetching Ghat Receipt:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const deleteGhatReceiptProcess = createAsyncThunk(
  "GhatReceipt/DeleteGhatReceiptProcess",
  async (ID: number) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/GhatReceipt/DeleteGhatReceiptProcess`,
        { id: ID }
      );
      // SuccessToast(response.data?.Details || "Success");
      return response.data;
    } catch (error: any) {
      ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error deleting Ghat Receipt Process:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const findByOrderNoGhatReceipt = createAsyncThunk(
  "GhatReceipt/FindByOrderNoGhatReceipt",
  async (OrderNo: any) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/GhatReceipt/FindByOrderNoGhatReceipt`,
        { order_no: OrderNo }
      );
      const result = response.data?.Data as any;
      return {
        id: result.id,
        order_no: result.order_no,
        item_id: result.item_id,
        ghat_id: result.ghat_id,
        item_name: result.item_name,
        karigar_id: result.karigar_id,
        wastage: result.wastage,
        item_description: result.item_description,
        item_category_id: result.item_category_id,
        weight: result.weight,
        purity: result.purity,
        // item_code: result.item_code,
      } as any;
    } catch (error: any) {
      ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error Fetching Order:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const getAllOrderGhatIssue = createAsyncThunk(
  "GhatReceipt/FindAllOrderNoGhatReceipt",
  async () => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/GhatReceipt/FindAllOrderNoGhatReceipt`
      );
      // SuccessToast(response.data?.Details || "Success")
      return response.data.Data;
    } catch (error: any) {
      console.error("Error Deleting Order:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);
