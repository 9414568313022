import React, { useEffect, useRef, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import {
  filterBy,
  CompositeFilterDescriptor,
} from "@progress/kendo-data-query";
import {
  Grid as KendoGrid,
  GridColumn as Column,
  GridCellProps,
  GridFilterChangeEvent,
  GridPageChangeEvent,
  GridCustomHeaderCellProps,
  GridCustomCellProps,
} from "@progress/kendo-react-grid";
import { GridLayout, GridLayoutItem } from "@progress/kendo-react-layout";
import { PagerTargetEvent } from "@progress/kendo-react-data-tools";
import { HeaderThElement } from "@progress/kendo-react-data-tools";
import { Typography } from "@progress/kendo-react-common";
import { Button } from "@progress/kendo-react-buttons";
import { Switch } from "@progress/kendo-react-inputs";
import AlertBox from "../../components/common/AlertBox";
import { LoadingPanel } from "../../components/layout/Loading";
import IconButton from "../../components/common/IconButton";
import AppDialog, { DialogCloseButton } from "../../components/dialog/Dialog";
import { closeDialog, openDialog } from "../../components/dialog/dialogSlice";
import { MdDelete } from "react-icons/md";
import AppDrawer, {
  ButtonCreate,
  ButtonEdit,
  LinkEdit,
} from "../../components/drawer/Drawer";
import {
  ActiveInactiveRights,
  deleteRights,
  getAllRights,
} from "./services/rights.services";
import CreateRights from "./CreateRights";
import { clearRightsID, setRightsID } from "./rightsSlice";
// import {
//   DELETE_ACCESS,
//   EDIT_ACCESS,
//   INSERT_ACCESS,
// } from "../../_contstants/common";
// import { checkAcessRights } from "../../_helper/helper";

const Rights: React.FC = () => {
  const dispatch = useAppDispatch();
  const isDrawerOpen = useAppSelector((state) => state.drawer.open);
  const drawerName = useAppSelector((state) => state.drawer.name);
  // const isAddAccess = checkAcessRights(location.pathname, INSERT_ACCESS);

  useEffect(() => {
    dispatch(getAllRights());
    return () => {
      dispatch(closeDialog());
    };
  }, []);
  return (
    <>
      <DeleteRightsDialog />
      <GridLayout cols={[{ width: "100%" }]}>
        <GridLayoutItem
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            padding: "4px 20px 20px 0",
          }}
        >
          <Typography.h4 style={{ marginBottom: 0, marginLeft: "3px" }}>
            Rights List
          </Typography.h4>
          {/* {isAddAccess && ( */}
            <ButtonCreate
              label="Create Right"
              drawerName="rightsForm"
              size="default"
              title="Create Right"
            />
          {/* )} */}
        </GridLayoutItem>
        <GridLayoutItem>
          <RightsGridComponent />
        </GridLayoutItem>
        {isDrawerOpen && drawerName === "rightsForm" && (
          <AppDrawer>
            <CreateRights />
          </AppDrawer>
        )}
      </GridLayout>
    </>
  );
};

interface PageState {
  skip: number;
  take: number;
}

const initialDataState: PageState = { skip: 0, take: 10 };

const RightsGridComponent: React.FC = () => {
  const gridRef = useRef<any>(null);
  const dispatch = useAppDispatch();
  const RightsList = useAppSelector((state) => state.rights.RightsList);
  const loading = useAppSelector((state) => state.rights.loading);
  // const isEditAccess = checkAcessRights(location.pathname, EDIT_ACCESS);
  // const isDeleteAccess = checkAcessRights(location.pathname, DELETE_ACCESS);

  const [filter, setFilter] = React.useState<
    CompositeFilterDescriptor | undefined
  >(undefined);
  const [page, setPage] = React.useState<PageState>(initialDataState);
  const [pageSizeValue, setPageSizeValue] = React.useState<
    number | string | undefined
  >();

  const pageChange = (event: GridPageChangeEvent) => {
    const targetEvent = event.targetEvent as PagerTargetEvent;
    const take =
      targetEvent.value === "All" ? RightsList.length : event.page.take;

    if (targetEvent.value) {
      setPageSizeValue(targetEvent.value);
    }
    setPage({
      ...event.page,
      take,
    });
  };

  const MyEditCommandCell = (props: GridCellProps) => {
    const handleOpenDeleteDialog = (rights_guid: any) => {
      dispatch(openDialog("deleteRights"));
      dispatch(setRightsID(rights_guid));
    };

    return (
      <td
        className="action-td"
        style={{
          ...props.style,
        }}
      >
        {/* {isEditAccess && ( */}
          <ButtonEdit
            ID={props.dataItem.rights_guid}
            name="rightsForm"
            title="Update Right"
          />
        {/* )}
        {isDeleteAccess && ( */}
          <IconButton
            type="button"
            fillMode="flat"
            size="small"
            title="Delete"
            onClick={() => handleOpenDeleteDialog(props.dataItem.rights_guid)}
          >
            <MdDelete
              className="absolute-position"
              style={{ fontSize: "24px" }}
            />
          </IconButton>
        {/* )} */}
      </td>
    );
  };

  const handleFilterChange = (e: GridFilterChangeEvent) => {
    setFilter(e.filter);
    setPage({ skip: 0, take: page.take });
  };
  const HeaderCustomCell = (props: GridCustomHeaderCellProps) => (
    <HeaderThElement
      columnId={props.thProps?.columnId || ""}
      {...props.thProps}
      className="table-header"
    >
      {props.children}
    </HeaderThElement>
  );

  const CustomCell = (props: any) => {
    return (
      <td {...props.tdProps} colSpan={1} className={"table-cell"}>
        {props.children}
      </td>
    );
  };

  const MyDataCustomCell = (props: GridCustomCellProps) => (
    <CustomCell {...props} />
  );

  const MyActiveInActtiveCommandCell = (props: GridCellProps) => {
    const [IsActive, setIsActive] = useState<boolean>(props.dataItem.isactive);

    const handleActiveInActive = () => {
      setIsActive(!IsActive);
      const data = {
        rights_guid: props.dataItem.rights_guid,
        isactive: !IsActive,
      };
      dispatch(ActiveInactiveRights(data)).then((response: any) => {
        if (response.payload?.data?.Status === 200) {
          dispatch(getAllRights());
        } else {
          dispatch(getAllRights());
        }
      });
    };

    return (
      <td style={{ textAlign: "center" }}>
        <Switch
          // disabled={isEditAccess ? false : true}
          name="isactive"
          checked={IsActive}
          onChange={handleActiveInActive}
          size="small"
        />
      </td>
    );
  };

  if (loading) return <LoadingPanel gridRef={gridRef} />;
  return (
    <>
      {RightsList && RightsList.length ? (
        <KendoGrid
          style={{ maxHeight: "calc(100vh - 152px)" }}
          filterable={true}
          filter={filter}
          data={
            filter
              ? filterBy(RightsList, filter).slice(
                  page.skip,
                  page.take + page.skip
                )
              : RightsList.slice(page.skip, page.take + page.skip)
          }
          onFilterChange={handleFilterChange}
          skip={page.skip}
          take={page.take}
          total={RightsList.length}
          pageable={{
            buttonCount: 5,
            pageSizes: [5, 10, 15, "All"],
            pageSizeValue: pageSizeValue,
            type: "input",
          }}
          onPageChange={pageChange}
          cells={{
            headerCell: HeaderCustomCell,
            data: MyDataCustomCell,
          }}
        >
          <Column
            field="name"
            title="Name"
            cell={(props: any) => (
              <td
                className="hoverableName"
              >
              {/* <td
                className={`${isEditAccess ? "hoverableName" : "normalName"}`}
              > */}
                {/* {isEditAccess ? ( */}
                  <LinkEdit
                    ID={props.dataItem.rights_guid}
                    name="rightsForm"
                    title="Update Right"
                    masterName={props.dataItem?.name}
                  />
                {/* ) : (
                  props.dataItem?.name
                )} */}
              </td>
            )}
          />
          <Column
            field="ID"
            title="Status"
            cell={MyActiveInActtiveCommandCell}
            width={"90px"}
            filterable={false}
            locked={true}
          />
          {/* {(isEditAccess || isDeleteAccess) && ( */}
            <Column
              field="id"
              title="Actions"
              cell={MyEditCommandCell}
              width={"110px"}
              filterable={false}
            />
          {/* )} */}
        </KendoGrid>
      ) : (
        <AlertBox />
      )}
    </>
  );
};

const DeleteRightsDialog: React.FC = () => {
  const dispatch = useAppDispatch();
  const RightsID = useAppSelector((state) => state.rights.RightsID);
  const dialogName = useAppSelector((state) => state.dialog.dialogName);
  const handleDeleteAction = async (ID: any) => {
    if (ID) {
      const response = await dispatch(deleteRights(ID));
      if (response.payload.Data.Status === 200) {
        dispatch(getAllRights());
        dispatch(closeDialog());
        dispatch(clearRightsID());
      } else {
        dispatch(getAllRights());
        dispatch(closeDialog());
        dispatch(clearRightsID());
      }
    }
  };

  return (
    <>
      {dialogName === "deleteRights" && (
        <AppDialog>
          <>
            <Typography.h5>{"Delete Rights"}</Typography.h5>
            <GridLayout>
              <GridLayoutItem>
                <Typography.p>
                  Are you sure you want to delete this Right?
                </Typography.p>
              </GridLayoutItem>
              <GridLayoutItem
                style={{
                  display: "flex",
                  justifyContent: "end",
                }}
              >
                <DialogCloseButton themeColor="error" />
                <Button
                  fillMode={"solid"}
                  themeColor={"error"}
                  onClick={() => handleDeleteAction(RightsID)}
                  type="button"
                >
                  Delete
                </Button>
              </GridLayoutItem>
            </GridLayout>
          </>
        </AppDialog>
      )}
    </>
  );
};

export default Rights;
