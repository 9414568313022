import {
  Field,
  Form,
  FormElement,
  FormRenderProps,
} from "@progress/kendo-react-form";
import { GridLayout, GridLayoutItem } from "@progress/kendo-react-layout";
import React, { useEffect, useRef } from "react";
import FormTextField from "../../components/formFields/FormTextField";
import { requiredValidator } from "../../components/formFields/CommonValidator";
import FormSelectionField from "../../components/formFields/FormSelectionField";
import { Button } from "@progress/kendo-react-buttons";
import ButtonWithLoading from "../../components/common/ButtonWithLoading";
import { LoadingPanel } from "../../components/layout/Loading";
import { closed } from "../../components/drawer/drawerSlice";
import { STATUSARRAY } from "../../_contstants/common";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import {
  createPurity,
  getAllPurity,
  getPurityById,
  updatePurity,
} from "./services/purity.services";
import { clearPurityDetails } from "./puritySlice";

const CreatePurity: React.FC = () => {
  const dispatch = useAppDispatch();
  const gridRef = useRef<any>(null);

  // const PriorityList = useAppSelector((state) => state.priority.PriorityList);
  const PurityDetail = useAppSelector((state) => state.purity.PurityDetail);
  const purity_guid = useAppSelector((state) => state.drawer.data);
  const formLoading = useAppSelector((state) => state.purity.formLoading);
  useEffect(() => {
    dispatch(getAllPurity());
    return () => {
      dispatch(clearPurityDetails());
      dispatch(closed());
    };
  }, []);

  useEffect(() => {
    if (purity_guid) {
      dispatch(getPurityById(purity_guid));
    }
  }, [purity_guid]);

  /* This Function calls when Form submit button Clicked */
  const handleSubmit = async (values: any) => {
    try {
      if (!purity_guid) {
        const InsertPayload = {
          purity_name: values.purity_name,
          isactive: values.isactive === 1,
        };
        const response = await dispatch(createPurity(InsertPayload));
        if (response?.meta?.requestStatus === "fulfilled") {
          dispatch(closed());
          dispatch(clearPurityDetails());
          dispatch(getAllPurity());
        }
      } else {
        const editPayload = {
          purity_guid: purity_guid,
          purity_name: values.purity_name,
          isactive: values.isactive === 1,
        };
        const response = await dispatch(updatePurity(editPayload));
        if (response?.meta?.requestStatus === "fulfilled") {
          dispatch(closed());
          dispatch(getAllPurity());
          dispatch(clearPurityDetails());
        }
      }
    } catch (error) {
      console.error("Error in handleSubmit:", error);
      throw error;
    }
  };

  const handleFormClose = () => {
    dispatch(closed());
  };

  if (formLoading) return <LoadingPanel gridRef={gridRef} />;
  return (
    <Form
      onSubmit={handleSubmit}
      initialValues={PurityDetail}
      render={(formRenderProps: FormRenderProps) => (
        <FormElement style={{ width: "100%" }}>
          <GridLayout
            style={{
              display: "flex",
              flexWrap: "wrap",
            }}
            gap={{ rows: 10 }}
            cols={[{ width: "100%" }]}
          >
            <GridLayoutItem style={{ width: "100%" }}>
              <Field
                name="purity_name"
                label="Purity"
                placeholder="Purity"
                component={FormTextField}
                validator={requiredValidator}
              />
            </GridLayoutItem>
            <GridLayoutItem style={{ width: "100%" }}>
              <Field
                id={"IsActive"}
                name={"isactive"}
                component={FormSelectionField}
                label={"Status"}
                options={STATUSARRAY?.map((status: any) => {
                  return {
                    value: status.value,
                    label: status.label,
                  };
                })}
              />
            </GridLayoutItem>
            <GridLayoutItem
              colSpan={3}
              style={{
                textAlign: "end",
                marginTop: "10px",
                width: "100%",
              }}
            >
              <Button
                type="button"
                fillMode={"outline"}
                themeColor={"primary"}
                style={{ marginRight: 4 }}
                onClick={handleFormClose}
              >
                Cancel
              </Button>
              <ButtonWithLoading
                label={purity_guid ? "Update" : "Create"}
                type="submit"
                disabled={!formRenderProps.allowSubmit || formLoading}
                loading={formLoading}
              />
            </GridLayoutItem>
          </GridLayout>
        </FormElement>
      )}
    />
  );
};

export default CreatePurity;
