import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IPurity, IPurityInitialState } from "./purityModel";
import {
  createPurity,
  deletePurity,
  findAllActivePurity,
  getAllPurity,
  getPurityById,
  PurityActiveInactive,
  updatePurity,
} from "./services/purity.services";

const initialState: IPurityInitialState = {
  loading: false,
  formLoading: false,
  error: "",
  PurityList: [],
  PurityID: null,
  PurityDetail: {
    purity_name: "",
    isactive: 1,
  },
};

export const puritySlice = createSlice({
  name: "purity",
  initialState,
  reducers: {
    clearPurityDetails: (state) => {
      state.PurityDetail = initialState.PurityDetail;
    },
    setPurityID: (state, action) => {
      state.PurityID = action.payload;
    },
    clearPurityID: (state) => {
      state.PurityID = null;
    },
  },
  extraReducers: (builder) => {
    /* For getAll ItemPurity */

    builder.addCase(getAllPurity.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(
      getAllPurity.fulfilled,
      (state, action: PayloadAction<IPurity[]>) => {
        state.loading = false;
        state.PurityList = action.payload;
      }
    );
    builder.addCase(getAllPurity.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message || "Something went wrong";
      state.PurityList = [];
    });

    /* ForfindAllActivePurity */

    builder.addCase(findAllActivePurity.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(
      findAllActivePurity.fulfilled,
      (state, action: PayloadAction<IPurity[]>) => {
        state.loading = false;
        state.PurityList = action.payload || [];
      }
    );
    builder.addCase(findAllActivePurity.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message || "Something went wrong";
      state.PurityList = [];
    });

    /* For createItemPurity */

    builder.addCase(createPurity.pending, (state) => {
      state.formLoading = false;
      state.error = "";
    });
    builder.addCase(createPurity.fulfilled, (state) => {
      state.formLoading = false;
    });
    builder.addCase(createPurity.rejected, (state, action) => {
      state.formLoading = false;
      state.error = action.error.message || "Something went wrong";
    });

    /* For updatePurity */

    builder.addCase(updatePurity.pending, (state) => {
      state.formLoading = true;
      state.error = "";
    });
    builder.addCase(updatePurity.fulfilled, (state) => {
      state.formLoading = false;
    });
    builder.addCase(updatePurity.rejected, (state, action) => {
      state.formLoading = false;
      state.error = action.error.message || "Something went wrong";
    });

    /* For deletePurity */

    builder.addCase(deletePurity.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(deletePurity.fulfilled, (state) => {
      state.loading = false;
    });
    builder.addCase(deletePurity.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message || "Something went wrong";
    });

    /* For PurityActiveInactive */

    builder.addCase(PurityActiveInactive.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(PurityActiveInactive.fulfilled, (state) => {
      state.loading = false;
    });
    builder.addCase(PurityActiveInactive.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message || "Something went wrong";
    });

    /* For getPurityById */

    builder.addCase(getPurityById.pending, (state) => {
      state.formLoading = true;
      state.error = "";
    });
    builder.addCase(getPurityById.fulfilled, (state, action: any) => {
      state.formLoading = false;
      state.PurityDetail = action.payload;
    });
    builder.addCase(getPurityById.rejected, (state, action) => {
      state.formLoading = false;
      state.PurityDetail = initialState.PurityDetail;
      state.error = action.error.message || "Something went wrong";
    });
  },
});

export const { clearPurityDetails, clearPurityID, setPurityID } =
  puritySlice.actions;
export default puritySlice.reducer;
