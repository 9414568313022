import { createAsyncThunk } from "@reduxjs/toolkit";
import { API_URL } from "../../../app/config";
import { axiosApiInstance } from "../../../app/axios";
import { ErrorToast, SuccessToast } from "../../../components/toast/Toasts";
import { IProductsubCategory } from "../productsubcategoryModel";

export const getAllProductSubCategory = createAsyncThunk(
  "ItemSubCategory/FindAllItemSubCategory",
  async () => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/ItemSubCategory/FindAllItemSubCategory`,
        {
          productSubCategory: "",
        }
      );
      return response.data?.Data;
    } catch (error: any) {
      console.error("Error Fetching ProductSubCategory:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const createProductSubCategory = createAsyncThunk(
  "ItemSubCategory/InsertItemSubCategory",
  async (productSubCategory: any) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/ItemSubCategory/InsertItemSubCategory`,
        productSubCategory
      );
      SuccessToast(response.data?.Details || "Success");
      return response.data;
    } catch (error: any) {
      ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error Fetching ProductSubCategory:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const updateProductSubCategory = createAsyncThunk(
  "ItemSubCategory/UpdateItemSubCategory",
  async (productSubCategory: any) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/ItemSubCategory/UpdateItemSubCategory`,
        productSubCategory
      );
      SuccessToast(response.data?.Details || "Success");
      return response.data;
    } catch (error: any) {
      ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error Fetching ProductSubCategory:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const getProductSubCategoryById = createAsyncThunk(
  "ItemSubCategory/FindByIDItemSubCategory",
  async (ID: any) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/ItemSubCategory/FindByIDItemSubCategory`,
        {
          item_subcategory_guid: ID,
        }
      );
      // SuccessToast(response.data?.Details || "Success")
      const result = response.data?.Data as any;
      return {
        id: result.Id,
        item_subcategory_guid: result.item_subcategory_guid,
        item_subcategory_name: result.item_subcategory_name,
        item_category_id: result.item_category_id,
        item_category_name: result.item_category_name,
        short_code: result.short_code,
        isactive: result.isactive === true ? 1 : 2,
      } as IProductsubCategory;
    } catch (error: any) {
      ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error Fetching ProductSubCategory:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const deleteProductSubCategory = createAsyncThunk(
  "ItemSubCategory/DeleteitemSubCategory",
  async (ID: number) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/ItemSubCategory/DeleteitemSubCategory`,
        {
          item_subcategory_guid: ID,
        }
      );
      SuccessToast(response.data?.Details || "Success");
      return response.data;
    } catch (error: any) {
      ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error Deleting ProductSubCategory:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const findAllActiveProductSubCategory = createAsyncThunk(
  "ItemSubCategory/FindAllActiveitemSubCategory",
  async () => {
    try {
      const response = await axiosApiInstance.get(
        `${API_URL}/ItemSubCategory/FindAllActiveitemSubCategory`
      );
      // SuccessToast(response.data?.Details || "Success")
      return response.data.Data;
    } catch (error: any) {
      console.error("Error Fetching ProductSubCategory:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const ProductSubCategoryActiveInactive = createAsyncThunk(
  "ItemSubCategory/ActiveInActiveitemSubCategory",
  async (formData: any) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/ItemSubCategory/ActiveInActiveitemSubCategory`,
        formData
      );
      SuccessToast(response.data?.Details || "Success");
      return response.data;
    } catch (error: any) {
      ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error(
        "Error Fetching active-inactive ProductSubCategory:",
        error
      );
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);
