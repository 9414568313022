import { createAsyncThunk } from "@reduxjs/toolkit";
import { API_URL } from "../../../app/config";
import { axiosApiInstance } from "../../../app/axios";
import { ErrorToast, SuccessToast } from "../../../components/toast/Toasts";
import { IParty } from "../partyModel";

export const getAllParty = createAsyncThunk("Party/FindAllParty", async (formData: any) => {
  try {
    const response = await axiosApiInstance.post(
      `${API_URL}/Party/FindAllParty`,formData
      
    );
    return response.data?.Data;
  } catch (error: any) {
    console.error("Error Fetching Vepari", error);
    return Promise.reject({
      message: error?.response?.data?.Details || "Something went wrong",
    });
  }
});

export const createParty = createAsyncThunk(
  "Party/InsertParty",
  async (party: any) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/Party/InsertParty`,
        party
      );
      SuccessToast(response.data?.Details || "Success");
      return response.data;
    } catch (error: any) {
      ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error Fetching Vepari:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const updateParty = createAsyncThunk(
  "Party/UpdateParty",
  async (party: any) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/Party/UpdateParty`,
        party
      );
      SuccessToast(response.data?.Details || "Success");
      return response.data;
    } catch (error: any) {
      ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error Fetching Vepari:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const getPartyById = createAsyncThunk(
  "Party/FindByIDParty",
  async (ID: any) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/Party/FindByIDParty`,
        { party_guid: ID }
      );
      // SuccessToast(response.data?.Details || "Success");
      const result = response.data?.Data as any;
      return {
        id: result.id,
        party_guid: result.party_guid,
        party_name: result.party_name,
        party_nick_name: result.party_nick_name,
        mobile_no: result.mobile_no,
        address: result.address,
        email: result.email,
        pan_no: result.pan_no,
        gst_no: result.gst_no,
        opening_balance: result.opening_balance,
        credit_debit: result.credit_debit,
        rs_opening_balance: result.rs_opening_balance,
        rs_credit_debit: result.rs_credit_debit,
        provide_bank_details: result.provide_bank_details,
        bank_account_name: result.bank_account_name,
        ifsc_code: result.ifsc_code,
        bank_name: result.bank_name,
        branch: result.branch,
        account_no: result.account_no,
        remarks: result.remarks,
        contact_details: result?.contact_details
          ? result?.contact_details?.map((results: any) => {
              return {
                party_id: results?.party_id,
                person_name: results?.person_name,
                country_code: results?.country_code,
                mobile_no: results?.mobile_no,
                email: results?.email,
                department: results?.department,
              };
            })
          : [],
        party_images: result?.party_images
          ? result?.party_images?.map((results: any) => {
              return {
                id: results?.id,
                image_guid: results?.image_guid,
                party_id: results?.party_id,
                title: results?.title,
                image_name: results?.image_name,
              };
            })
          : [],
          charges_details: result.charges_details
            ? result.charges_details.map((results: any) => {
                return {
                  party_id: results.party_id,
                  charge_id: results.charge_id ,
                  unit_id: results.unit_id,
                  charges_type: results.charges_type,
                  charges: results.charges,
                };
              })
            : [],
        isactive: result.isactive === true ? 1 : 2,
      } as IParty;
    } catch (error: any) {
      ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error Fetching Vepari:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const deleteParty = createAsyncThunk(
  "Party/DeleteParty",
  async (ID: number) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/Party/DeleteParty`,
        { party_guid: ID }
      );
      SuccessToast(response.data?.Details || "Success");
      return response.data;
    } catch (error: any) {
      ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error Deleting Vepari:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const findAllActiveParty = createAsyncThunk(
  "Party/FindAllActiveParty",
  async () => {
    try {
      const response = await axiosApiInstance.get(
        `${API_URL}/Party/FindAllActiveParty`
      );
      // SuccessToast(response.data?.Details || "Success")
      return response.data.Data;
    } catch (error: any) {
      console.error("Error Deleting Product:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const PartyActiveInactive = createAsyncThunk(
  "Party/ActiveInActiveParty",
  async (formData: any) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/Party/ActiveInActiveParty`,
        formData
      );
      SuccessToast(response.data?.Details || "Success");
      return response.data;
    } catch (error: any) {
      ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error active-inactive Vepari:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const InsertPartyImage = createAsyncThunk(
  "Party/InsertPartyImage",
  async ({
    party_id,
    title,
    ImageData,
  }: {
    party_id: any;
    title: any;
    ImageData: any;
  }) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/Party/InsertPartyImage?party_id=${party_id}&title=${title}`,
        ImageData
      );
      SuccessToast(response.data?.Details || "Success");
      return response.data;
    } catch (error: any) {
      ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error uploading Image:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const updatePartyImage = createAsyncThunk(
  "UpdatePartyImage",
  async ({
    party_id,
    title,
    image_guid,
    ImageData,
  }: {
    party_id: any;
    title: any;
    image_guid: any;
    ImageData: any;
  }) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/Party/UpdatePartyImage?&image_guid=${image_guid}&party_id=${party_id}&title=${title}`,
        ImageData
      );
      SuccessToast(response.data?.Details || "Success");
      return response.data;
    } catch (error: any) {
      ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error Update Image:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const deletePartyImage = createAsyncThunk(
  "Party/DeletePartyImage",
  async (ID: number) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/Party/DeletePartyImage`,
        { image_guid: ID }
      );
      // SuccessToast(response.data?.Details || "Success");
      return response.data;
    } catch (error: any) {
      ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error Deleting Image:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const getPartyImageById = createAsyncThunk(
  "Party/FindBYPartyImage",
  async (ID: any) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/Party/FindBYPartyImage`,
        { party_id: ID }
      );
      // SuccessToast(response.data?.Details || "Success")
      const result = response.data?.Data as any;
      return result?.map((party: any) => {
        return {
          id: party?.id,
          image_guid: party?.image_guid,
          party_id: party?.party_id,
          title: party?.title,
          image_name: party?.image_name,
        };
      });
    } catch (error: any) {
      ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error Fetching Image:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);
