import { Button } from "@progress/kendo-react-buttons";
import { Card, GridLayout, GridLayoutItem } from "@progress/kendo-react-layout";
import React from "react";
import { Field, FieldArrayRenderProps } from "@progress/kendo-react-form";
import FormTextField from "../../components/formFields/FormTextField";
import { closeDialog, openDialog } from "../../components/dialog/dialogSlice";
import AppDialog from "../../components/dialog/Dialog";
import { Typography } from "@progress/kendo-react-common";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { setImageArrayDeleteIndex } from "../item/itemSlice";
import FormImageUpload from "../../components/formFields/FormImageUpload";
import {
  InsertImage,
  deleteImage,
  updateImage,
  getImageById,
} from "../item/services/item.services";
import { ErrorToast, SuccessToast } from "../../components/toast/Toasts";
import { Ripple } from "@progress/kendo-react-ripple";
import { requiredValidator } from "../../components/formFields/CommonValidator";
// import FormCheckbox from "../../components/formFields/FormCheckbox";
import { Label } from "@progress/kendo-react-labels";
import { FaRegEye } from "react-icons/fa";
import { MdDelete } from "react-icons/md";
import { Checkbox } from "@progress/kendo-react-inputs";
// import { requiredValidator } from "../../components/formFields/CommonValidator";

const ImageArray = (fieldArrayRenderProps: FieldArrayRenderProps) => {
  const dispatch = useAppDispatch();
  const deleteImageArrayIndex = useAppSelector(
    (state) => state.item.DeleteIndex
  );
  const dialogName = useAppSelector((state) => state.dialog.dialogName);
  const GhatReceiptDetail = useAppSelector(
    (state) => state.ghatreceipt.GhatReceiptDetail
  );

  // const [checkboxStates, setCheckboxStates] = React.useState(
  //   fieldArrayRenderProps.value?.map((img: any) => img.isreadyimage)
  // );

  const handleCheckboxChange = (index: number) => {
    const newValues = fieldArrayRenderProps.value.map(
      (item: any, idx: number) => ({
        ...item,
        isreadyimage: idx === index ? true : false,
      })
    );

    fieldArrayRenderProps.formRenderProps.onChange("Images", {
      value: newValues,
    });
  };

  const pushElementInarray = React.useCallback(() => {
    fieldArrayRenderProps.onPush({
      value: {
        isreadyimage: false,
        title: "",
        image_name: "",
      },
    });
  }, [fieldArrayRenderProps]);

  const handleImageSave = async (index: number) => {
    const image_name: any[] = fieldArrayRenderProps?.value[index]?.image_name;
    const item_id = fieldArrayRenderProps?.value[index]?.item_id;
    const title = fieldArrayRenderProps?.value[index]?.title;
    const image_guid = fieldArrayRenderProps?.value[index]?.image_guid;
    const isreadyimage = fieldArrayRenderProps?.value[index]?.isreadyimage;
    const ImageData = new FormData();
    if (!title) return ErrorToast("please Enter title!");
    // Prepare binaryPhoto only if image_name is not a string
    let binaryPhoto = "";
    if (typeof image_name !== "string") {
      binaryPhoto = image_name[0].getRawFile();
    }
    ImageData.append("image_name", binaryPhoto);
    try {
      // Determine action based on the presence of image_guid
      const action = image_guid
        ? updateImage({
            item_id: item_id,
            title: title,
            isreadyimage: isreadyimage,
            ImageData: ImageData,
            image_guid: image_guid,
          })
        : InsertImage({
            item_id: GhatReceiptDetail?.item_id,
            title: title,
            isreadyimage: isreadyimage,
            ImageData: ImageData,
          });

      const response = await dispatch(action);

      // Handle response
      if (response?.meta?.requestStatus === "fulfilled") {
        const res = await dispatch(getImageById(item_id));
        if (res?.meta?.requestStatus === "fulfilled") {
          fieldArrayRenderProps?.formRenderProps?.onChange("Images", {
            value: JSON.parse(JSON.stringify(res.payload)),
          });
        }
      }
    } catch (error: any) {
      // Error handling
      ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error saving Image:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  };

  const handleOpenDeleteDialog = (index: number) => {
    dispatch(openDialog("ImageDeleteDialog"));
    dispatch(setImageArrayDeleteIndex(index));
  };

  const handleOpenImage = (url: string) => {
    if (url && typeof url === "string") {
      window.open(url);
    }
  };
  const handleDeleteAction = React.useCallback(
    async (index: number) => {
      const item_id = fieldArrayRenderProps?.value[index]?.item_id;
      if (fieldArrayRenderProps?.value[index]?.image_guid) {
        try {
          const response = await dispatch(
            deleteImage(fieldArrayRenderProps?.value[index]?.image_guid)
          );
          if (response?.meta?.requestStatus === "fulfilled") {
            fieldArrayRenderProps?.onRemove({ index: index });
            dispatch(closeDialog());
            dispatch(setImageArrayDeleteIndex(-1));
            const res = await dispatch(getImageById(item_id));
            if (res?.meta?.requestStatus === "fulfilled") {
              fieldArrayRenderProps?.formRenderProps?.onChange("Images", {
                value: JSON.parse(JSON.stringify(res.payload)),
              });
            }
            SuccessToast(response.payload.message || "Success");
          }
        } catch (error: any) {
          ErrorToast(error?.response?.data?.Details || "Something went wrong");
          console.error("Error deleting Image:", error);
          return Promise.reject({
            message: error?.response?.data?.Details || "Something went wrong",
          });
        }
      } else {
        fieldArrayRenderProps.onRemove({ index: index });
        dispatch(closeDialog());
        dispatch(setImageArrayDeleteIndex(-1));
      }
    },
    [fieldArrayRenderProps]
  );

  return (
    <GridLayout cols={[{ width: "100%" }]}>
      {fieldArrayRenderProps?.value?.length < 1 ? pushElementInarray() : null}
      {dialogName === "ImageDeleteDialog" && (
        <AppDialog>
          <>
            <Typography.h5>{"Delete Image"}</Typography.h5>
            <GridLayout>
              <GridLayoutItem>
                <Typography.p>
                  Are you sure you want to delete this?
                </Typography.p>
              </GridLayoutItem>
              <GridLayoutItem
                style={{
                  display: "flex",
                  justifyContent: "end",
                }}
              >
                <Ripple>
                  <Button
                    style={{ marginRight: 4 }}
                    fillMode={"outline"}
                    themeColor={"error"}
                    onClick={() => {
                      dispatch(closeDialog());
                      dispatch(setImageArrayDeleteIndex(-1));
                    }}
                  >
                    Cancel
                  </Button>
                </Ripple>
                <Button
                  fillMode={"solid"}
                  themeColor={"error"}
                  onClick={() => handleDeleteAction(deleteImageArrayIndex)}
                  type="button"
                >
                  Delete
                </Button>
              </GridLayoutItem>
            </GridLayout>
          </>
        </AppDialog>
      )}
      {fieldArrayRenderProps.value &&
        fieldArrayRenderProps.value.length > 0 &&
        fieldArrayRenderProps.value.map((Images: any, index: number) => (
          <GridLayoutItem key={index}>
            <Card className="fieldarray-card-imageArray">
              <GridLayout
                style={{ marginRight: 20, alignItems: "center" }}
                gap={{ rows: 0, cols: 10 }}
                cols={[{ width: "5%" }, { width: "20%" }, { width: "75%" }]}
              >
                <GridLayoutItem>
                  <Label>Is Main</Label>
                  <div className={"k-form-field-wrap"}>
                    <Checkbox
                      size={"medium"}
                      name={`Images.${index}.isreadyimage`}
                      labelClassName="k-form-label"
                      onChange={() => handleCheckboxChange(index)}
                      value={fieldArrayRenderProps.value[index]?.isreadyimage}
                    />
                  </div>
                </GridLayoutItem>
                <GridLayoutItem>
                  <Field
                    name={`Images.${index}.title`}
                    label="Title"
                    placeholder="Title"
                    validator={requiredValidator}
                    component={FormTextField}
                  />
                </GridLayoutItem>
                <GridLayoutItem style={{ display: "flex" }}>
                  <Field
                    imageWidth={60}
                    imageHeight={60}
                    name={`Images.${index}.image_name`}
                    label="Image"
                    type="file"
                    accept="image/*"
                    component={FormImageUpload}
                  />
                  <div
                    style={{
                      width: 230,
                      display: "flex",
                      alignItems: "end",
                      justifyContent: "center",
                      marginBottom: 8,
                    }}
                  >
                    <Button
                      style={{ padding: 5 }}
                      type="button"
                      fillMode={"solid"}
                      themeColor={"primary"}
                      onClick={() => handleImageSave(index)}
                    >
                      {!fieldArrayRenderProps.value[index]?.image_guid
                        ? "Save"
                        : "Update"}
                    </Button>
                    <Button
                      style={{
                        position: "relative",
                        width: 30,
                        height: 32,
                        marginLeft: 5,
                      }}
                      onClick={() => handleOpenDeleteDialog(index)}
                      disabled={fieldArrayRenderProps.value.length === 1}
                      fillMode={"solid"}
                      themeColor={"error"}
                      type="button"
                      size={"small"}
                    >
                      <MdDelete className="absolute-position" />
                    </Button>
                    <Button
                      style={{
                        position: "relative",
                        width: 30,
                        height: 32,
                        marginLeft: 5,
                      }}
                      onClick={() =>
                        handleOpenImage(
                          fieldArrayRenderProps.value[index]?.image_name
                        )
                      }
                      disabled={!fieldArrayRenderProps.value[index]?.image_guid}
                      fillMode={"solid"}
                      themeColor={"primary"}
                      type="button"
                      size={"small"}
                    >
                      <FaRegEye className="absolute-position" />
                    </Button>
                  </div>
                </GridLayoutItem>
                {/* <GridLayoutItem
                  colSpan={3}
                  style={{
                    marginTop: 47,
                    display: "flex",
                    justifyContent: "end",
                  }}
                >
                  <Button
                    style={{ padding: 10 }}
                    type="button"
                    fillMode={"solid"}
                    themeColor={"primary"}
                    onClick={() => handleImageSave(index)}
                  >
                    {!fieldArrayRenderProps.value[index]?.image_guid
                      ? "Save"
                      : "Update"}
                  </Button>
                  <Button
                    style={{ padding: 10, marginLeft: 10 }}
                    onClick={() => handleOpenDeleteDialog(index)}
                    disabled={fieldArrayRenderProps.value.length === 1}
                    fillMode={"solid"}
                    themeColor={"error"}
                    type="button"
                    size={"small"}
                  >
                    <MdDelete />
                  </Button>
                  <Button
                    style={{ padding: 10, marginLeft: 10 }}
                    onClick={() =>
                      handleOpenImage(
                        fieldArrayRenderProps.value[index]?.image_name
                      )
                    }
                    disabled={!fieldArrayRenderProps.value[index]?.image_guid}
                    fillMode={"solid"}
                    themeColor={"primary"}
                    type="button"
                    size={"small"}
                  >
                    <FaRegEye />
                  </Button>
                </GridLayoutItem> */}
              </GridLayout>
            </Card>
          </GridLayoutItem>
        ))}
      <GridLayoutItem style={{ marginBottom: 10, textAlign: "left" }}>
        <Button
          fillMode={"solid"}
          themeColor={"primary"}
          onClick={pushElementInarray}
          type="button"
          disabled={
            !fieldArrayRenderProps.value[
              fieldArrayRenderProps?.value?.length - 1
            ]?.image_guid ||
            fieldArrayRenderProps.value?.filter(
              (e: any) => typeof e.image_name === "object"
            )?.length > 0
          }
        >
          Add Image
        </Button>
      </GridLayoutItem>
    </GridLayout>
  );
};

export default ImageArray;
