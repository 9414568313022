import { Button } from "@progress/kendo-react-buttons";
import { Typography } from "@progress/kendo-react-common";
import { GridLayout, GridLayoutItem } from "@progress/kendo-react-layout";
import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { closeDialog, openDialog } from "../../components/dialog/dialogSlice";
import AppDialog, { DialogCloseButton } from "../../components/dialog/Dialog";
import { deleteOrder, findAllOrder } from "./services/order.services";
import { clearOrderDetail, setOrderID } from "./orderSlice";
import {
  CompositeFilterDescriptor,
  filterBy,
} from "@progress/kendo-data-query";
import {
  GridCellProps,
  GridCustomCellProps,
  GridCustomHeaderCellProps,
  GridFilterChangeEvent,
  GridPageChangeEvent,
  Grid as KendoGrid,
  GridColumn as Column,
} from "@progress/kendo-react-grid";
import { HeaderThElement } from "@progress/kendo-react-data-tools";
import IconButton from "../../components/common/IconButton";
import { FiEdit } from "react-icons/fi";
import { MdDelete } from "react-icons/md";
import { LoadingPanel } from "../../components/layout/Loading";
import AlertBox from "../../components/common/AlertBox";
import { setCurrentPage, setPageLimit } from "../pagination/paginationSlice";

const Order: React.FC = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  useEffect(() => {
    return () => {
      dispatch(closeDialog());
      dispatch(clearOrderDetail());
      dispatch(setCurrentPage(0));
    };
  }, []);

  const handleOrderCreate = () => {
    navigate("/order/create");
  };
  return (
    <>
      <DeleteOrderDialog />
      <GridLayout cols={[{ width: "100%" }]}>
        <GridLayoutItem
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            padding: "4px 20px 20px 0",
          }}
        >
          <Typography.h4 style={{ marginBottom: 0, marginLeft: "3px" }}>
            Order List
          </Typography.h4>
          <Button
            onClick={handleOrderCreate}
            fillMode={"solid"}
            themeColor={"primary"}
          >
            New Order
          </Button>
        </GridLayoutItem>
        <GridLayoutItem>
          <OrderGridComponent />
        </GridLayoutItem>
      </GridLayout>
    </>
  );
};

const DeleteOrderDialog: React.FC = () => {
  const dispatch = useAppDispatch();
  const OrderID = useAppSelector((state) => state.order.OrderID);
  const dialogName = useAppSelector((state) => state.dialog.dialogName);
  const currentPage = useAppSelector((state) => state.pagination.currentPage);
  const pageLimit = useAppSelector((state) => state.pagination.pageLimit);

  const handleDeleteAction = async (ID: number | null) => {
    if (ID) {
      const response = await dispatch(deleteOrder(ID));
      dispatch(setCurrentPage(0));
      const payload = {
        limit: +pageLimit,
        skip: +currentPage * +pageLimit,
      };
      if (response.payload?.Data.Status === 200) {
        dispatch(findAllOrder(payload));
        dispatch(closeDialog());
        dispatch(clearOrderDetail());
      } else {
        dispatch(findAllOrder(payload));
        dispatch(closeDialog());
        dispatch(clearOrderDetail());
      }
    }
  };

  return (
    <>
      {dialogName === "deleteOrder" && (
        <AppDialog>
          <>
            <Typography.h5>{"Delete Order?"}</Typography.h5>
            <GridLayout>
              <GridLayoutItem>
                <Typography.p>
                  Are you sure you want to delete this Order?
                </Typography.p>
              </GridLayoutItem>
              <GridLayoutItem
                style={{
                  display: "flex",
                  justifyContent: "end",
                }}
              >
                <DialogCloseButton themeColor="error" />
                <Button
                  fillMode={"solid"}
                  themeColor={"error"}
                  onClick={() => {
                    handleDeleteAction(OrderID);
                  }}
                  type="button"
                >
                  Delete
                </Button>
              </GridLayoutItem>
            </GridLayout>
          </>
        </AppDialog>
      )}
    </>
  );
};

interface PageState {
  skip: number;
  take: number;
}

const initialDataState: PageState = { skip: 0, take: 10 };

const OrderGridComponent: React.FC = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const OrderList = useAppSelector((state) => state.order.OrderList);
  const loading = useAppSelector((state) => state.order.loading);
  const currentPage = useAppSelector((state) => state.pagination.currentPage);
  const pageLimit = useAppSelector((state) => state.pagination.pageLimit);
  const [page, setPage] = React.useState<PageState>(initialDataState);
  const [uiLoading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    const payload = {
      party_name: "",
      limit: +pageLimit,
      skip: +currentPage * +pageLimit,
    };
    dispatch(findAllOrder(payload));
  }, [currentPage, pageLimit]);

  const pageChange = (event: GridPageChangeEvent) => {
    const newPage = event.page.skip / event.page.take;
    dispatch(setPageLimit(event.page.take));
    dispatch(setCurrentPage(newPage));
  };

  const [filter, setFilter] = React.useState<
    CompositeFilterDescriptor | undefined
  >(undefined);

  useEffect(() => {
    setLoading(loading);
  }, [loading]);

  const MyEditCommandCell = (props: GridCellProps) => {
    const handleEditItem = (ID: number) => {
      navigate("/order/edit", {
        state: { ID, order_guid: props.dataItem?.order_guid },
      });
    };

    const handleOpenDeleteDialog = (ID: number) => {
      dispatch(openDialog("deleteOrder"));
      dispatch(setOrderID(ID));
    };

    return (
      <td className="action-td">
        <IconButton
          type="button"
          fillMode="flat"
          size="small"
          title="Edit"
          onClick={() => handleEditItem(props.dataItem.order_guid)}
        >
          <FiEdit className="absolute-position" style={{ fontSize: "20px" }} />
        </IconButton>
        <IconButton
          type="button"
          fillMode="flat"
          size="small"
          title="Delete"
          onClick={() => handleOpenDeleteDialog(props.dataItem.order_guid)}
        >
          <MdDelete
            className="absolute-position"
            style={{ fontSize: "24px" }}
          />
        </IconButton>
      </td>
    );
  };
  const handleFilterChange = (e: GridFilterChangeEvent) => {
    setFilter(e.filter);
    setPage({ skip: 0, take: page.take });
  };

  const HeaderCustomCell = (props: GridCustomHeaderCellProps) => (
    <HeaderThElement
      columnId={props.thProps?.columnId || ""}
      {...props.thProps}
      className={`table-header ${props.index === 5 ? "locked-header" : ""}`}
    >
      {props.children}
    </HeaderThElement>
  );
  const CustomCell = (props: any) => {
    return (
      <td {...props.tdProps} colSpan={1} className={"table-cell"}>
        {props.children}
      </td>
    );
  };
  const MyDataCustomCell = (props: GridCustomCellProps) => (
    <CustomCell {...props} />
  );
  const gridRef = useRef<any>(null);
  if (uiLoading) return <LoadingPanel gridRef={gridRef} />;

  return (
    <>
      {OrderList && OrderList.length ? (
        <KendoGrid
          style={{ maxHeight: "calc(100vh - 152px)" }}
          filterable={true}
          filter={filter}
          data={filter ? filterBy(OrderList, filter) : OrderList}
          total={OrderList[0]?.itemcount || OrderList.length}
          onFilterChange={handleFilterChange}
          skip={currentPage * pageLimit}
          take={pageLimit}
          pageable={{
            buttonCount: 5,
            pageSizes: [10, 50, 100, 300, 500],
            pageSizeValue: pageLimit,
            type: "input",
          }}
          onPageChange={pageChange}
          cells={{
            headerCell: HeaderCustomCell,
            data: MyDataCustomCell,
          }}
        >
          <Column
            field="order_no"
            title="Order No"
            cell={(props: any) => (
              <td>
                <div
                  className="hoverableName"
                  onClick={() =>
                    navigate("/order/edit", {
                      state: { ID: props.dataItem?.order_guid },
                    })
                  }
                >
                  {props.dataItem?.order_no}
                </div>
              </td>
            )}
          />
          <Column field="order_date" title="Order Date" />
          <Column field="delivery_date" title="Delivery Date" />
          <Column field="party_name" title="Vepari Name" />
          <Column field="item_name" title="Product Name" />
          <Column field="estimate_weight" title="Estimate Weight" />
          <Column field="purity" title="Purity" />
          <Column field="color" title="Color" />
          <Column field="estimate_amount" title="Estimated Rs." />
          <Column
            field="order_guid"
            title="Actions"
            cell={MyEditCommandCell}
            width={"110px"}
            filterable={false}
            locked={true}
          />
        </KendoGrid>
      ) : (
        <AlertBox />
      )}
    </>
  );
};

export default Order;
