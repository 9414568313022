import { createAsyncThunk } from "@reduxjs/toolkit";
import { API_URL } from "../../../app/config";
import { axiosApiInstance } from "../../../app/axios";
import { ErrorToast, SuccessToast } from "../../../components/toast/Toasts";
import { IGhat } from "../ghatModel";
import moment from "moment";
// import { IGhat } from "../ghatModel";

export const createGhat = createAsyncThunk(
  "Ghat/InsertGhat",
  async (ghat: any) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/Ghat/InsertGhat`,
        ghat
      );
      SuccessToast(response.data?.Details || "Success");
      return response.data;
    } catch (error: any) {
      ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error Fetching Ghat:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const updateGhat = createAsyncThunk(
  "Ghat/UpdateGhat",
  async (ghat: any) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/Ghat/UpdateGhat`,
        ghat
      );
      SuccessToast(response.data?.Details || "Success");
      return response.data;
    } catch (error: any) {
      ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error Fetching Ghat:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const deleteGhat = createAsyncThunk(
  "Ghat/DeleteGhat",
  async (ID: number) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/Ghat/DeleteGhat`,
        { ghat_guid: ID }
      );
      SuccessToast(response.data?.Details || "Success");
      return response.data;
    } catch (error: any) {
      ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error Deleting Ghat:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const findAllGhat = createAsyncThunk(
  "Ghat/FindAllGhat",
  async (formData: any) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/Ghat/FindAllGhat`,
        formData
      );
      // SuccessToast(response.data?.Details || "Success")
      return response.data.Data;
    } catch (error: any) {
      console.error("Error Deleting Ghat", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const getGhatById = createAsyncThunk(
  "Ghat/FindByIDGhat",
  async (ID: any) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/Ghat/FindByIDGhat`,
        { ghat_guid: ID }
      );
      // SuccessToast(response.data?.Details || "Success");
      const result = response.data?.Data as any;
      return {
        issue_date: result.issue_date ? moment(result.issue_date).toDate() : "",
        karigar_id: result.karigar_id,
        issue_no: result.issue_no,
        approx_delivery_date: result.approx_delivery_date
          ? moment(result.approx_delivery_date).toDate()
          : "",
        given_given_weight_total: result.given_given_weight_total,
        phine_total: result.phine_total,
        amount_total: result.amount_totals,
        remarks: result.remarks,
        isactive: true,
        ghat_items: result.ghat_items
          ? result.ghat_items.map((results: any) => {
              return {
                id: results.id,
                order_no: results.order_no,
                item_id: results.item_id,
                item_category_id: results.item_category_id,
                item_description: results.item_description,
                weight: results.weight,
                purity: results.purity,
                item_name: results.item_name,
                Category_name: results.Category_name,
              };
            })
          : [],
        ghat_item_issue: result.ghat_item_issue
          ? result.ghat_item_issue.map((results: any) => {
              return {
                id: results.id,
                ghat_id: results.ghat_id,
                given_weight: results.given_weight,
                given_purity: results.given_purity,
                phine: results.phine,
                amount: results.amount,
              };
            })
          : [],
      } as unknown as IGhat;
    } catch (error: any) {
      ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error Fetching Ghat:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const deleteGhatItems = createAsyncThunk(
  "Ghat/DeleteGhatItem",
  async (ID: number) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/Ghat/DeleteGhatItem`,
        { id: ID }
      );
      // SuccessToast(response.data?.Details || "Success");
      return response.data;
    } catch (error: any) {
      ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error deleting Ghat ITem:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);
export const deleteGhadiaIssueItems = createAsyncThunk(
  "Ghat/DeleteGhatItemIssue",
  async (ID: number) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/Ghat/DeleteGhatItemIssue`,
        { id: ID }
      );
      // SuccessToast(response.data?.Details || "Success");
      return response.data;
    } catch (error: any) {
      ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error deleting Ghadia Issue ITem:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);
