import { Button } from "@progress/kendo-react-buttons";
import { Card, GridLayout, GridLayoutItem } from "@progress/kendo-react-layout";
import React from "react";
// import IconButton from "../../components/common/IconButton";
// import { MdDelete } from "react-icons/md";
import { Field, FieldArrayRenderProps } from "@progress/kendo-react-form";
import { requiredValidator } from "../../components/formFields/CommonValidator";
import FormTextField from "../../components/formFields/FormTextField";
import { closeDialog, openDialog } from "../../components/dialog/dialogSlice";
import AppDialog, { DialogCloseButton } from "../../components/dialog/Dialog";
import { Typography } from "@progress/kendo-react-common";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { setContactDetailDeleteIndex } from "./partySlice";
import IconButton from "../../components/common/IconButton";
import { MdDelete } from "react-icons/md";

const ContactDetailsArray = (fieldArrayRenderProps: FieldArrayRenderProps) => {
  const dispatch = useAppDispatch();
  const ContactDetailDeleteIndex = useAppSelector(
    (state) => state.party.ContactDeleteIndex
  );
  const dialogName = useAppSelector((state) => state.dialog.dialogName);

  const handleDeleteAction = React.useCallback(
    (index: number) => {
      fieldArrayRenderProps.onRemove({ index: index });
      dispatch(closeDialog());
      dispatch(setContactDetailDeleteIndex(-1));
    },
    [fieldArrayRenderProps]
  );

  const pushElementInarray = React.useCallback(() => {
    fieldArrayRenderProps?.onPush({
      value: {
        // party_id:null,
        person_name: "",
        country_code: "+91",
        mobile_no: "",
        email: "",
        department: "",
      },
    });
  }, [fieldArrayRenderProps]);

  const handleOpenDeleteDialog = (index: any) => {
    dispatch(openDialog("contactDetailsDeleteDialog"));
    dispatch(setContactDetailDeleteIndex(index));
  };

  return (
    <GridLayout cols={[{ width: "100%" }]}>
      {/* {fieldArrayRenderProps?.value?.length <= 0 ? pushElementInarray() : null} */}
      {dialogName === "contactDetailsDeleteDialog" && (
        <AppDialog>
          <>
            <Typography.h5>{"Delete Contact Detail Data"}</Typography.h5>
            <GridLayout>
              <GridLayoutItem>
                <Typography.p>
                  Are you sure you want to delete this?
                </Typography.p>
              </GridLayoutItem>
              <GridLayoutItem
                style={{
                  display: "flex",
                  justifyContent: "end",
                }}
              >
                <DialogCloseButton themeColor="error" />
                <Button
                  fillMode={"solid"}
                  themeColor={"error"}
                  onClick={() => handleDeleteAction(ContactDetailDeleteIndex)}
                  type="button"
                >
                  Delete
                </Button>
              </GridLayoutItem>
            </GridLayout>
          </>
        </AppDialog>
      )}
      {fieldArrayRenderProps.value &&
        fieldArrayRenderProps.value.length > 0 &&
        fieldArrayRenderProps.value.map((process_data: any, index: number) => (
          <GridLayoutItem key={index}>
            <Card className="fieldarray-card">
              <GridLayout
                style={{ marginRight: 50 }}
                gap={{ rows: 0, cols: 10 }}
                cols={[
                  { width: "19%" },
                  { width: "19%" },
                  { width: "19%" },
                  { width: "19%" },
                  { width: "19%" },
                  { width: "5%" },
                ]}
              >
                <GridLayoutItem>
                  <Field
                    name={`contact_details.${index}.person_name`}
                    label="Person Name"
                    placeholder="Person Name"
                    component={FormTextField}
                    validator={requiredValidator}
                  />
                </GridLayoutItem>
                <GridLayoutItem>
                  <Field
                    name={`contact_details.${index}.country_code`}
                    label="Country Code"
                    placeholder="Country Code"
                    component={FormTextField}
                  />
                </GridLayoutItem>
                <GridLayoutItem>
                  <Field
                    name={`contact_details.${index}.mobile_no`}
                    label="Mobile"
                    type="number"
                    placeholder="Mobile"
                    max={10}
                    component={FormTextField}
                    validator={requiredValidator}
                  />
                </GridLayoutItem>
                <GridLayoutItem>
                  <Field
                    name={`contact_details.${index}.email`}
                    label="Email"
                    placeholder="Email"
                    component={FormTextField}
                  />
                </GridLayoutItem>
                <GridLayoutItem>
                  <Field
                    name={`contact_details.${index}.department`}
                    label="Department"
                    placeholder="Department"
                    component={FormTextField}
                  />
                </GridLayoutItem>
                <GridLayoutItem
                  style={{
                    marginTop: 42,
                  }}
                >
                  {/* <IconButton
                    onClick={() => handleOpenDeleteDialog(index)}
                    disabled={fieldArrayRenderProps.value.length === 1}
                    themeColor={"error"}
                    size={"small"}
                    fillMode={"solid"}
                    type="button"
                    style={{ height: 24, width: 24 }}
                  >
                    <MdDelete
                      className="absolute-position"
                      style={{ fontSize: "16px" }}
                    />
                  </IconButton> */}
                  <IconButton
                    onClick={() => handleOpenDeleteDialog(index)}
                    // disabled={fieldArrayRenderProps.value.length === 1}
                    themeColor={"error"}
                    size={"small"}
                    fillMode={"solid"}
                    type="button"
                    style={{ height: 24, width: 24 }}
                  >
                    <MdDelete
                      className="absolute-position"
                      style={{ fontSize: "16px" }}
                    />
                  </IconButton>
                </GridLayoutItem>
              </GridLayout>
            </Card>
          </GridLayoutItem>
        ))}
      <GridLayoutItem style={{ marginBottom: 10, textAlign: "left" }}>
        <Button
          fillMode={"solid"}
          themeColor={"primary"}
          onClick={pushElementInarray}
          type="button"
        >
          Add Contact Detail
        </Button>
      </GridLayoutItem>
    </GridLayout>
  );
};

export default ContactDetailsArray;
