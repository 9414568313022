import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { IKarigar, IKarigarInitialSate } from "./karigarModel";
import {
  InsertKarigarImage,
  KarigarActiveInactive,
  createKarigar,
  deleteKarigar,
  deleteKarigarImage,
  findAllActiveKarigar,
  getAllKarigar,
  getKarigarById,
  getKarigarImageById,
  updateKarigar,
  updateKarigarImage,
} from "./services/karigar.services";

const initialState: IKarigarInitialSate = {
  loading: false,
  formLoading: false,
  error: "",
  KarigarList: [],
  KarigarID: null,
  KarigarDetail: {
    id: null,
    karigar_id: null,
    karigar_name: "",
    karigar_nick_name: "",
    mobile: "",
    address: "",
    email: "",
    pan_no: "",
    opening_balance: 0,
    opening_balance_credit_debit: "credit",
    opening_gold_stock: 0,
    opening_gold_stock_credit_debit: "credit",
    process_id: null,
    provide_bank_details: false,
    bank_account_name: "",
    account_no: "",
    bank: "",
    branch: "",
    ifsc: "",
    wastage: null,
    remarks: "",
    karigar_images:[
      {
        id:null,
        image_guid:null,
        karigar_id:null,
        no: "",
        title: '',
        image_name: '',
      },
    ],
    isactive: 1
  },
  DeleteIndex: 1
};

const karigarSlice = createSlice({
  name: "karigar",
  initialState,
  reducers: {
    clearKarigarDetail: (state) => {
      state.KarigarDetail = initialState.KarigarDetail;
    },
    setKarigarID: (state, action) => {
      state.KarigarID = action.payload;
    },
    clearKarigarID: (state) => {
      state.KarigarID = null;
    },
    setKarigarImageArrayDeleteIndex: (state, action) => {
      state.DeleteIndex = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getAllKarigar.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(
      getAllKarigar.fulfilled,
      (state, action: PayloadAction<IKarigar[]>) => {
        state.loading = false;
        state.KarigarList = action.payload;
      }
    );
    builder.addCase(getAllKarigar.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message || "Something went wrong";
      state.KarigarList = [];
    });

    builder.addCase(findAllActiveKarigar.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(
      findAllActiveKarigar.fulfilled,
      (state, action: PayloadAction<IKarigar[]>) => {
        state.loading = false;
        state.KarigarList = action.payload || [];
      }
    );
    builder.addCase(findAllActiveKarigar.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message || "Something went wrong";
      state.KarigarList = [];
    });

    builder.addCase(createKarigar.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(createKarigar.fulfilled, (state) => {
      state.loading = false;
    });
    builder.addCase(createKarigar.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message || "Something went wrong";
    });

    builder.addCase(InsertKarigarImage.pending, (state) => {
      state.formLoading = true;
      state.error = "";
    });
    builder.addCase(InsertKarigarImage.fulfilled, (state) => {
      state.formLoading = false;
    });
    builder.addCase(InsertKarigarImage.rejected, (state, action) => {
      state.formLoading = false;
      state.error = action.error.message || "Something went wrong";
    });

    builder.addCase(updateKarigar.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(updateKarigar.fulfilled, (state) => {
      state.loading = false;
    });
    builder.addCase(updateKarigar.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message || "Something went wrong";
    });

    builder.addCase(updateKarigarImage.pending, (state) => {
      state.formLoading = true;
      state.error = "";
    });
    builder.addCase(updateKarigarImage.fulfilled, (state) => {
      state.formLoading = false;
    });
    builder.addCase(updateKarigarImage.rejected, (state, action) => {
      state.formLoading = false;
      state.error = action.error.message || "Something went wrong";
    });

    builder.addCase(deleteKarigar.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(deleteKarigar.fulfilled, (state) => {
      state.loading = false;
    });
    builder.addCase(deleteKarigar.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message || "Something went wrong";
    });

    builder.addCase(deleteKarigarImage.pending, (state) => {
      state.formLoading = true;
      state.error = "";
    });
    builder.addCase(deleteKarigarImage.fulfilled, (state) => {
      state.formLoading = false;
    });
    builder.addCase(deleteKarigarImage.rejected, (state, action) => {
      state.formLoading = false;
      state.error = action.error.message || "Something went wrong";
    });

    builder.addCase(KarigarActiveInactive.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(KarigarActiveInactive.fulfilled, (state) => {
      state.loading = false;
    });
    builder.addCase(KarigarActiveInactive.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message || "Something went wrong";
    });

    builder.addCase(getKarigarById.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(getKarigarById.fulfilled, (state, action: any) => {
      state.loading = false;
      state.KarigarDetail = action.payload;
    });
    builder.addCase(getKarigarById.rejected, (state, action) => {
      state.loading = false;
      state.KarigarDetail = initialState.KarigarDetail;
      state.error = action.error.message || "Something went wrong";
    });

    builder.addCase(getKarigarImageById.pending, (state) => {
      state.formLoading = true;
      state.error = "";
    });
    builder.addCase(getKarigarImageById.fulfilled, (state) => {
      state.formLoading = false;
    });
    builder.addCase(getKarigarImageById.rejected, (state, action) => {
      state.formLoading = false;
      state.error = action.error.message || "Something went wrong";
    });

  },
});

export const { clearKarigarDetail, clearKarigarID, setKarigarID, setKarigarImageArrayDeleteIndex } =
  karigarSlice.actions;
export default karigarSlice.reducer;
