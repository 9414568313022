import { createAsyncThunk } from "@reduxjs/toolkit";
import { API_URL } from "../../../app/config";
import { axiosApiInstance } from "../../../app/axios";
import { ErrorToast, SuccessToast } from "../../../components/toast/Toasts";
import { IItem } from "../itemModel";

export const getAllItem = createAsyncThunk(
  "Item/FindAllItem",
  async (formData: any) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/Item/FindAllItem`,
        formData
      );
      return response.data?.Data;
    } catch (error: any) {
      console.error("Error Fetching Product:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const createItem = createAsyncThunk(
  "Item/InsertItem",
  async (Product: any) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/Item/InsertItem`,
        Product
      );
      SuccessToast(response.data?.Details || "Success");
      return response.data;
    } catch (error: any) {
      ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error Fetching Product:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const updateItem = createAsyncThunk(
  "Item/UpdateItem",
  async (Product: any) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/Item/UpdateItem`,
        Product
      );
      // SuccessToast(response.data?.Details || "Success");
      return response.data;
    } catch (error: any) {
      ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error Fetching Product:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const uploadItemImage = createAsyncThunk(
  "Item/ImageUpload",
  async ({ item_id, ImageData }: { item_id: number; ImageData: any }) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/Item/ImageUpload?item_id=${item_id}`,
        ImageData
      );
      SuccessToast(response.data?.Details || "Success");
      return response.data;
    } catch (error: any) {
      ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error uploading Image:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const InsertImage = createAsyncThunk(
  "Item/InsertItemImage",
  async ({
    item_id,
    title,
    isreadyimage,
    ImageData,
  }: {
    item_id: any;
    title: any;
    isreadyimage: boolean;
    ImageData: any;
  }) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/Item/InsertItemImage?item_id=${item_id}&title=${title}&isreadyimage=${isreadyimage}`,
        ImageData
      );
      SuccessToast(response.data?.Details || "Success");
      return response.data;
    } catch (error: any) {
      ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error uploading Image:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const updateImage = createAsyncThunk(
  "Item/UpdateItemImage",
  async ({
    item_id,
    title,
    isreadyimage,
    image_guid,
    ImageData,
  }: {
    item_id: any;
    title: any;
    isreadyimage: boolean;
    image_guid: any;
    ImageData: any;
  }) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/Item/UpdateItemImage?item_id=${item_id}&title=${title}&image_guid=${image_guid}&isreadyimage=${isreadyimage}`,
        ImageData
      );
      SuccessToast(response.data?.Details || "Success");
      return response.data;
    } catch (error: any) {
      ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error Update Image:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const deleteImage = createAsyncThunk(
  "Item/DeleteItemImage",
  async (ID: number) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/Item/DeleteItemImage`,
        { image_guid: ID }
      );
      // SuccessToast(response.data?.Details || "Success");
      return response.data;
    } catch (error: any) {
      ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error Deleting Image:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const getImageById = createAsyncThunk(
  "Item/FindBYItemImage",
  async (ID: any) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/Item/FindBYItemImage`,
        { item_id: ID }
      );
      // SuccessToast(response.data?.Details || "Success")
      const result = response.data?.Data as any;
      return result?.map((item: any) => {
        return {
          id: item?.id,
          image_guid: item?.image_guid,
          item_id: item?.item_id,
          title: item?.title,
          isreadyimage: item?.isreadyimage,
          image_name: item?.image_name,
        };
      });
    } catch (error: any) {
      ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error Fetching Image:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const getItemById = createAsyncThunk(
  "Item/FindByIDItem",
  async (ID: any) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/Item/FindByIDItem`,
        { item_guid: ID }
      );
      // SuccessToast(response.data?.Details || "Success")
      const result = response.data?.Data as any;
      return {
        id: result.id,
        item_guid: result.item_guid,
        item_id: result.item_id,
        item_sub_category_id: result.item_sub_category_id,
        item_category_id: result.item_category_id,
        item_name: result.item_name,
        remarks: result.remarks,
        category_name: result.category_name,
        item_subcategory_name: result.item_subcategory_name,
        item_nick_name: result.item_nick_name,
        Images: result.Images
          ? result.Images.map((img: any) => ({
              id: img.id,
              image_guid: img.image_guid,
              item_id: img.item_id,
              isreadyimage: img.isreadyimage,
              title: img.title,
              image_name: img.image_name,
            }))
          : [],
        isactive: result.isactive ? 1 : 2,
      } as IItem;
    } catch (error: any) {
      ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error Fetching Product:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const findbyItemCode = createAsyncThunk(
  "Item/FindByItemCode",
  async (ID: any) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/IItem/FindByItemCode`,
        { item_code: ID }
      );
      // SuccessToast(response.data?.Details || "Success")
      const result = response.data?.Data as any;
      return {
        id: result.id,
        item_guid: result.item_guid,
        item_category_id: result.item_category_id,
        item_name: result.item_name,
        item_code: result.item_code,
        remarks: result.remarks,
        item_Rate: result.item_Rate,
        OpeningStock: result.OpeningStock,
        isactive: result.isactive === true ? 1 : 2,
      } as unknown as IItem;
    } catch (error: any) {
      ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error Fetching Product:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const deleteItem = createAsyncThunk(
  "Item/DeleteItem",
  async (ID: number) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/Item/DeleteItem`,
        { item_guid: ID }
      );
      SuccessToast(response.data?.Details || "Success");
      return response.data;
    } catch (error: any) {
      ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error Deleting Product:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const findAllActiveItem = createAsyncThunk(
  "Item/FindAllActiveItem",
  async () => {
    try {
      const response = await axiosApiInstance.get(
        `${API_URL}/Item/FindAllActiveItem`
      );
      // SuccessToast(response.data?.Details || "Success")
      return response.data.Data;
    } catch (error: any) {
      console.error("Error fetching Product:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const ItemActiveInactive = createAsyncThunk(
  "Item/ActiveInActiveItem",
  async (formData: any) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/Item/ActiveInActiveItem`,
        formData
      );
      SuccessToast(response.data?.Details || "Success");
      return response.data;
    } catch (error: any) {
      ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error active-inactive Product:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);
