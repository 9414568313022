import {
  Field,
  FieldArray,
  Form,
  FormElement,
  FormRenderProps,
} from "@progress/kendo-react-form";
import React, { useEffect, useRef, useState } from "react";
import ShadowCard from "../../components/common/ShadowCard";
import { GridLayout, GridLayoutItem } from "@progress/kendo-react-layout";
import { Typography } from "@progress/kendo-react-common";
import { requiredValidator } from "../../components/formFields/CommonValidator";
import FormSelectionField from "../../components/formFields/FormSelectionField";
import { Button } from "@progress/kendo-react-buttons";
import ButtonWithLoading from "../../components/common/ButtonWithLoading";
import FormDatePicker from "../../components/formFields/FormDateField";
import ItemDetailsArray from "./ItemDetailsArray";
import { useLocation, useNavigate } from "react-router-dom";
import { createGhat, getGhatById, updateGhat } from "./services/ghat.services";
import { clearGhatDetail } from "./ghatSlice";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import moment from "moment";
import { closed } from "../../components/drawer/drawerSlice";
import { RootState } from "../../app/store";
import { findAllActiveKarigar } from "../karigar/services/karigar.services";
import { LoadingPanel } from "../../components/layout/Loading";
import FormTextField from "../../components/formFields/FormTextField";
import FormRichTextField from "../../components/formFields/FormRichTextField";
import GhatIssueDetailArray from "./GhatIssueDetailArray";
import { IKarigar } from "../karigar/karigarModel";
import FormNumericTextField from "../../components/formFields/FormNumericTextField";
import { findAllActiveItem } from "../item/services/item.services";
import { findAllActivePurity } from "../purity/services/purity.services";
import { findAllActiveItemCategory } from "../itemcategory/services/itemcategory.services";
import { clearOrderDetail } from "../order/orderSlice";
import { findAllOrder } from "../order/services/order.services";

interface FormChangeWatcherProps {
  formRenderProps: FormRenderProps;
}
const TotalChangeWatcher: React.FC<FormChangeWatcherProps> = ({
  formRenderProps,
}) => {
  const GhatItems = formRenderProps.valueGetter("ghat_item_issue");
  let weight_total = 0;
  let phine_total = 0;
  let amount_total = 0;
  useEffect(() => {
    if (GhatItems && GhatItems.length > 0) {
      GhatItems?.map((item: any) => {
        weight_total += +item.given_weight;
        phine_total += +item.phine;
        amount_total += +item.amount;
      });
    }
    formRenderProps.onChange(`weight_total`, {
      value: weight_total || "",
    });
    formRenderProps.onChange(`phine_total`, {
      value: phine_total || "",
    });
    formRenderProps.onChange(`amount_total`, {
      value: amount_total || "",
    });
  }, [
    GhatItems?.map((item: any) => item?.given_weight).join("-"),
    GhatItems?.map((item: any) => item?.phine).join("-"),
    GhatItems?.map((item: any) => item?.amount).join("-"),
  ]);
  return null;
};

const CreateGhat: React.FC = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const gridRef = useRef<any>(null);
  const ghat_guid = location?.state?.ID;
  const formLoading = useAppSelector((state) => state.ghat?.formLoading);
  const loading = useAppSelector((state) => state.ghat?.loading);
  const KarigarList = useAppSelector((state) => state.karigar?.KarigarList);
  const GhatDetail = useAppSelector(
    (state: RootState) => state.ghat.GhatDetail
  );
  const [formKey, setFormKey] = useState(1);
  const today = new Date();
  const maxDate =
    new Date(today.getFullYear(), today.getMonth(), today.getDate()) || null;

  useEffect(() => {
    dispatch(findAllActiveKarigar());
    dispatch(findAllActiveItem());
    dispatch(findAllActivePurity());
    dispatch(findAllActiveItemCategory());
    const payload = {
      party_name: "",
      limit: 10,
      skip: 0,
    };
    dispatch(findAllOrder(payload));
    return () => {
      dispatch(clearGhatDetail());
      dispatch(clearOrderDetail());
    };
  }, []);

  useEffect(() => {
    if (ghat_guid) {
      dispatch(getGhatById(ghat_guid));
      dispatch(closed());
    }
  }, [ghat_guid]);

  useEffect(() => {
    setFormKey(formKey + 1);
  }, [GhatDetail]);

  const handleSubmit = async (values: any) => {
    const formData = {
      issue_date: values.issue_date
        ? moment(values.issue_date).format("YYYY-MM-DD")
        : "",
      karigar_id: values.karigar_id ? +values.karigar_id : 0,
      approx_delivery_date: values.approx_delivery_date
        ? moment(values.approx_delivery_date).format("YYYY-MM-DD")
        : "",
      weight_total: values.weight_total ? +values.weight_total : 0,
      phine_total: values.phine_total ? +values.phine_total : 0,
      amount_total: values.amount_total ? +values.amount_total : 0,
      remarks: values.remarks,
      isactive: true,
      ghat_items: values.ghat_items
        ? values.ghat_items.map((ghat_info: any) => {
            return {
              id: ghat_info.id ? ghat_info.id : 0,
              order_no: ghat_info.order_no ? +ghat_info.order_no : null,
              item_id: ghat_info.item_id ? +ghat_info.item_id : null,
              item_description: ghat_info.item_description,
              item_category_id: ghat_info.item_category_id
                ? +ghat_info.item_category_id
                : null,

              weight: ghat_info.weight ? +ghat_info.weight : null,
              purity: ghat_info.purity ? +ghat_info.purity : null,
            };
          })
        : [],
      ghat_item_issue: values.ghat_item_issue
        ? values.ghat_item_issue.map((ghat_issue_detail: any) => {
            return {
              id: ghat_issue_detail.id ? ghat_issue_detail.id : 0,
              given_weight: ghat_issue_detail.given_weight
                ? +ghat_issue_detail.given_weight
                : null,
              given_purity: ghat_issue_detail.given_purity
                ? +ghat_issue_detail.given_purity
                : null,
              phine: ghat_issue_detail.phine ? +ghat_issue_detail.phine : null,
              amount: ghat_issue_detail.amount
                ? +ghat_issue_detail.amount
                : null,
            };
          })
        : [],
    };
    if (ghat_guid) {
      const editPayload = {
        ghat_guid: ghat_guid,
        issue_no: values.issue_no,
        ...formData,
      };
      try {
        const response = await dispatch(updateGhat(editPayload));
        if (response?.meta?.requestStatus === "fulfilled") {
          dispatch(clearGhatDetail());
          dispatch(closed());
          navigate("/ghatissue");
        }
      } catch (error) {
        console.error("Error in handleSubmit:", error);
        throw error;
      }
    } else {
      try {
        const response = await dispatch(createGhat(formData));
        if (response?.meta?.requestStatus === "fulfilled") {
          dispatch(clearGhatDetail());
          navigate("/ghatissue");
        }
      } catch (error) {
        console.error("Error in handleSubmit:", error);
        throw error;
      }
    }
  };
  const handleFormClose = () => {
    dispatch(clearGhatDetail());
    dispatch(closed());
    navigate("/ghatissue");
  };

  if (formLoading) return <LoadingPanel gridRef={gridRef} />;

  return (
    <>
      <Form
        onSubmit={handleSubmit}
        key={formKey}
        initialValues={GhatDetail}
        render={(formRenderProps: FormRenderProps) => (
          <FormElement>
            <ShadowCard style={{ padding: 12 }}>
              <GridLayout
                style={{ marginRight: 20 }}
                gap={{ rows: 0, cols: 10 }}
                cols={[
                  { width: "33.33%" },
                  { width: "33.33%" },
                  { width: "33.33%" },
                ]}
              >
                <GridLayoutItem colSpan={3}>
                  <Typography.h4>
                    {ghat_guid ? "Update Ghat Issue" : "Create Ghat Issue"}
                  </Typography.h4>
                </GridLayoutItem>
                <GridLayoutItem colSpan={3}>
                  {ghat_guid ? (
                    <Typography.h5>
                      Ghat No :{" "}
                      <span style={{ color: "green" }}>
                        {GhatDetail.issue_no}
                      </span>
                    </Typography.h5>
                  ) : (
                    ""
                  )}
                </GridLayoutItem>
                <GridLayoutItem>
                  <Field
                    name="karigar_id"
                    label="Karigar Nick Name"
                    placeholder={"Karigar Nick Name"}
                    component={FormSelectionField}
                    validator={requiredValidator}
                    options={KarigarList?.map((karigar: IKarigar) => {
                      return {
                        value: karigar?.id,
                        label: karigar?.karigar_nick_name || "NO Nick Name",
                      };
                    })}
                  />
                </GridLayoutItem>
                <GridLayoutItem>
                  <Field
                    name="issue_date"
                    label="Issue Date"
                    placeholder="day/month/year"
                    format={"dd/MM/yyyy"}
                    component={FormDatePicker}
                    max={maxDate}
                    value={new Date()}
                    validator={requiredValidator}
                  />
                </GridLayoutItem>
                <GridLayoutItem>
                  <Field
                    name="approx_delivery_date"
                    label="Approx Delivery Date"
                    format={"dd/MM/yyyy"}
                    placeholder="day/month/year"
                    component={FormDatePicker}
                    validator={requiredValidator}
                  />
                </GridLayoutItem>
              </GridLayout>
            </ShadowCard>
            {loading ? (
              <LoadingPanel gridRef={gridRef} />
            ) : (
              <ShadowCard style={{ marginTop: 10 }}>
                <GridLayoutItem colSpan={3}>
                  <Typography.h4>Order Detail</Typography.h4>
                </GridLayoutItem>
                <FieldArray
                  formRenderProps={formRenderProps}
                  component={ItemDetailsArray}
                  name="ghat_items"
                />
              </ShadowCard>
            )}
            <TotalChangeWatcher formRenderProps={formRenderProps} />
            <ShadowCard style={{ marginTop: 10 }}>
              <GridLayoutItem colSpan={4}>
                <Typography.h4>Ghadia Issue Detail</Typography.h4>
              </GridLayoutItem>
              <FieldArray
                formRenderProps={formRenderProps}
                component={GhatIssueDetailArray}
                name="ghat_item_issue"
              />
              <GridLayout
                style={{ marginRight: 40 }}
                gap={{ rows: 0, cols: 10 }}
                cols={[
                  { width: "20%" },
                  { width: "20%" },
                  { width: "20%" },
                  { width: "20%" },
                  { width: "20%" },
                ]}
              >
                <GridLayoutItem col={3}>
                  <Field
                    name="weight_total"
                    label="Total Given Weight"
                    format="n3"
                    placeholder="0.00"
                    type="number"
                    component={FormNumericTextField}
                    disabled
                  />
                </GridLayoutItem>
                <GridLayoutItem col={4}>
                  <Field
                    name="phine_total"
                    label="Phine Total"
                    component={FormTextField}
                    placeholder="0"
                    disabled
                  />
                </GridLayoutItem>
                <GridLayoutItem col={5}>
                  <Field
                    name="amount_total"
                    label="Amount Total"
                    placeholder="0"
                    component={FormTextField}
                    disabled
                  />
                </GridLayoutItem>
              </GridLayout>
            </ShadowCard>
            <ShadowCard style={{ marginTop: 10 }}>
              <GridLayout
                style={{ marginRight: 20 }}
                gap={{ rows: 0, cols: 10 }}
                cols={[
                  { width: "33.33%" },
                  { width: "33.33%" },
                  { width: "33.33%" },
                ]}
              >
                <GridLayoutItem colSpan={2}>
                  <Field
                    name="remarks"
                    label="Remarks"
                    component={FormRichTextField}
                  />
                </GridLayoutItem>
                <GridLayoutItem
                  colSpan={3}
                  style={{
                    textAlign: "end",
                    marginTop: "20px",
                    width: "100%",
                  }}
                >
                  <Button
                    type="button"
                    fillMode={"outline"}
                    themeColor={"primary"}
                    style={{ marginRight: 4 }}
                    onClick={handleFormClose}
                  >
                    Cancel
                  </Button>
                  <ButtonWithLoading
                    label={!ghat_guid ? "Create" : "Update"}
                    type="submit"
                    disabled={!formRenderProps.allowSubmit || formLoading}
                    loading={formLoading}
                  />
                </GridLayoutItem>
              </GridLayout>
            </ShadowCard>
          </FormElement>
        )}
      />
    </>
  );
};

export default CreateGhat;
