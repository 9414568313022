import { createAsyncThunk } from "@reduxjs/toolkit";
import { API_URL } from "../../../app/config";
import { axiosApiInstance } from "../../../app/axios";
import { ErrorToast, SuccessToast } from "../../../components/toast/Toasts";
import { IKarigar } from "../karigarModel";

export const getAllKarigar = createAsyncThunk(
  "Karigar/FindAllKarigar",
  async () => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/Karigar/FindAllKarigar`,
        {
          karigar: "",
        }
      );
      return response.data?.Data;
    } catch (error: any) {
      console.error("Error Fetching Karigar", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const createKarigar = createAsyncThunk(
  "Karigar/InsertKarigar",
  async (karigar: any) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/Karigar/InsertKarigar`,
        karigar
      );
      // SuccessToast(response.data?.Details || "Success");
      return response.data;
    } catch (error: any) {
      ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error Fetching Karigar:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const updateKarigar = createAsyncThunk(
  "Karigar/UpdateKarigar",
  async (karigar: any) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/Karigar/UpdateKarigar`,
        karigar
      );
      SuccessToast(response.data?.Details || "Success");
      return response.data;
    } catch (error: any) {
      ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error Fetching Karigar:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const getKarigarById = createAsyncThunk(
  "Karigar/FindByIDKarigar",
  async (ID: any) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/Karigar/FindByIDKarigar`,
        { karigar_guid: ID }
      );
      // SuccessToast(response.data?.Details || "Success");
      const result = response.data?.Data as any;
      return {
        id: result.id,
        karigar_guid: result.karigar_guid,
        karigar_name: result.karigar_name,
        karigar_nick_name: result.karigar_nick_name,
        mobile: result.mobile,
        karigar_code: result.karigar_code,
        address: result.address,
        email: result.email,
        pan_no: result.pan_no,
        opening_balance: result.opening_balance,
        opening_balance_credit_debit: result.opening_balance_credit_debit,
        opening_gold_stock: result.opening_gold_stock,
        opening_gold_stock_credit_debit: result.opening_gold_stock_credit_debit,
        process_id: result.process_id,
        provide_bank_details: result.provide_bank_details,
        bank_account_name: result.bank_account_name,
        account_no: result.account_no,
        bank: result.bank,
        branch: result.branch,
        ifsc: result.ifsc,
        wastage: result.wastage,
        remarks: result.remarks,
        karigar_images: result.karigar_images
          ? result.karigar_images.map((results: any) => {
              return {
                id: results.id,
                image_guid: results.image_guid,
                karigar_id: results.karigar_id,
                title: results.title,
                no: results.no,
                image_name: results.image_name,
              };
            })
          : [],
        isactive: result.isactive === true ? 1 : 2,
      } as IKarigar;
    } catch (error: any) {
      ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error Fetching Karigar:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const deleteKarigar = createAsyncThunk(
  "Karigar/DeleteKarigar",
  async (ID: number) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/Karigar/DeleteKarigar`,
        { karigar_guid: ID }
      );
      SuccessToast(response.data?.Details || "Success");
      return response.data;
    } catch (error: any) {
      ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error Deleting Karigar:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const findAllActiveKarigar = createAsyncThunk(
  "Karigar/FindAllActiveKarigar",
  async () => {
    try {
      const response = await axiosApiInstance.get(
        `${API_URL}/Karigar/FindAllActiveKarigar`
      );
      // SuccessToast(response.data?.Details || "Success")
      return response.data.Data;
    } catch (error: any) {
      console.error("Error Deleting karigar:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const KarigarActiveInactive = createAsyncThunk(
  "Karigar/ActiveInActiveKarigar",
  async (formData: any) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/Karigar/ActiveInActiveKarigar`,
        formData
      );
      SuccessToast(response.data?.Details || "Success");
      return response.data;
    } catch (error: any) {
      ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error active-inactive Karigar:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const karigarImageUpload = createAsyncThunk(
  "Karigar/KarigarImageUpload",
  async ({
    karigar_guid,
    ImageData,
  }: {
    karigar_guid: string;
    ImageData: any;
  }) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/Karigar/KarigarImageUpload?karigar_guid=${karigar_guid}`,
        ImageData
      );
      // SuccessToast(response.data?.Details || "Success");
      return response.data;
    } catch (error: any) {
      ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error uploading Product Image:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const InsertKarigarImage = createAsyncThunk(
  "karigar/InsertkarigarImage",
  async ({
    karigar_id,
    no,
    title,
    ImageData,
  }: {
    karigar_id: any;
    title: any;
    no: any;
    ImageData: any;
  }) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/karigar/InsertkarigarImage?karigar_id=${karigar_id}&no=${no}&title=${title}`,
        ImageData
      );
      SuccessToast(response.data?.Details || "Success");
      return response.data;
    } catch (error: any) {
      ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error uploading karigar Image:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const updateKarigarImage = createAsyncThunk(
  "Karigar/UpdatekarigarImage",
  async ({
    karigar_id,
    title,
    image_guid,
    no,
    image_name,
  }: {
    karigar_id: any;
    title: any;
    image_guid: any;
    no: any;
    image_name: any;
  }) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/Karigar/UpdatekarigarImage?image_guid=${image_guid}&karigar_id=${karigar_id}&no=${no}&title=${title}`,
        image_name
      );
      SuccessToast(response.data?.Details || "Success");
      return response.data;
    } catch (error: any) {
      ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error Update Karigar Image:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const deleteKarigarImage = createAsyncThunk(
  "Karigar/DeletekarigarImage",
  async (ID: number) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/Karigar/DeletekarigarImage`,
        { image_guid: ID }
      );
      // SuccessToast(response.data?.Details || "Success");
      return response.data;
    } catch (error: any) {
      ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error Deleting karigar Image:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const getKarigarImageById = createAsyncThunk(
  "Karigar/FindBYKarigarImage",
  async (ID: any) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/Karigar/FindBYKarigarImage`,
        { karigar_id: ID }
      );
      const result = response.data?.Data as any;
      return result?.map((karigar: any) => {
        return {
          id: karigar?.id,
          image_guid: karigar?.image_guid,
          karigar_id: karigar?.karigar_id,
          no: karigar?.no,
          title: karigar?.title,
          image_name: karigar?.image_name,
        };
      });
    } catch (error: any) {
      ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error Fetching Kariga Image:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);
