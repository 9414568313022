import {
    Field,
    Form,
    FormElement,
    FormRenderProps,
  } from "@progress/kendo-react-form";
  import { GridLayout, GridLayoutItem } from "@progress/kendo-react-layout";
  import React, { useEffect, useRef } from "react";
  import FormTextField from "../../components/formFields/FormTextField";
  import { requiredValidator } from "../../components/formFields/CommonValidator";
  import FormSelectionField from "../../components/formFields/FormSelectionField";
  import { Button } from "@progress/kendo-react-buttons";
  import ButtonWithLoading from "../../components/common/ButtonWithLoading";
  import { LoadingPanel } from "../../components/layout/Loading";
  import { closed } from "../../components/drawer/drawerSlice";
  import { STATUSARRAY } from "../../_contstants/common";
  import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { createCharges, getAllCharges, getChargesById, updateCharges } from "./services/charges.services";
import { clearChargesDetails } from "./chargesSlice";
 
  const CreateCharges: React.FC = () => {
    const dispatch = useAppDispatch();
    const gridRef = useRef<any>(null);
  
    // const PriorityList = useAppSelector((state) => state.priority.PriorityList);
    const ChargesDetail = useAppSelector((state) => state.charges.ChargesDetail);
    const charges_guid = useAppSelector((state) => state.drawer.data);
    const formLoading = useAppSelector((state) => state.charges.formLoading);
    useEffect(() => {
      dispatch(getAllCharges());
      return () => {
        dispatch(clearChargesDetails());
        dispatch(closed());
      };
    }, []);
  
    useEffect(() => {
      if (charges_guid) {
        dispatch(getChargesById(charges_guid));
      }
    }, [charges_guid]);
  
    /* This Function calls when Form submit button Clicked */
    const handleSubmit = async (values: any) => {
      try {
        if (!charges_guid) {
          const InsertPayload = {
            charges_name: values.charges_name,
            isactive: values.isactive === 1,
          };
          const response = await dispatch(createCharges(InsertPayload));
          if (response?.meta?.requestStatus === "fulfilled") {
            dispatch(closed());
            dispatch(clearChargesDetails());
            dispatch(getAllCharges());
          }
        } else {
          const editPayload = {
            charges_guid: charges_guid,
            charges_name: values.charges_name,
            isactive: values.isactive === 1,
          };
          const response = await dispatch(updateCharges(editPayload));
          if (response?.meta?.requestStatus === "fulfilled") {
            dispatch(closed());
            dispatch(getAllCharges());
            dispatch(clearChargesDetails());
          }
        }
      } catch (error) {
        console.error("Error in handleSubmit:", error);
        throw error;
      }
    };
  
    const handleFormClose = () => {
      dispatch(closed());
    };
  
    if (formLoading) return <LoadingPanel gridRef={gridRef} />;
    return (
      <Form
        onSubmit={handleSubmit}
        initialValues={ChargesDetail}
        render={(formRenderProps: FormRenderProps) => (
          <FormElement style={{ width: "100%" }}>
            <GridLayout
              style={{
                display: "flex",
                flexWrap: "wrap",
              }}
              gap={{ rows: 10 }}
              cols={[{ width: "100%" }]}
            >
              <GridLayoutItem style={{ width: "100%" }}>
                <Field
                  name="charges_name"
                  label="Charges Name"
                  placeholder="Charges Name"
                  component={FormTextField}
                  validator={requiredValidator}
                />
              </GridLayoutItem>
              <GridLayoutItem style={{ width: "100%" }}>
                <Field
                  id={"IsActive"}
                  name={"isactive"}
                  component={FormSelectionField}
                  label={"Status"}
                  options={STATUSARRAY?.map((status: any) => {
                    return {
                      value: status.value,
                      label: status.label,
                    };
                  })}
                />
              </GridLayoutItem>
              <GridLayoutItem
                colSpan={3}
                style={{
                  textAlign: "end",
                  marginTop: "10px",
                  width: "100%",
                }}
              >
                <Button
                  type="button"
                  fillMode={"outline"}
                  themeColor={"primary"}
                  style={{ marginRight: 4 }}
                  onClick={handleFormClose}
                >
                  Cancel
                </Button>
                <ButtonWithLoading
                  label={charges_guid ? "Update" : "Create"}
                  type="submit"
                  disabled={!formRenderProps.allowSubmit || formLoading}
                  loading={formLoading}
                />
              </GridLayoutItem>
            </GridLayout>
          </FormElement>
        )}
      />
    );
  };
  
  export default CreateCharges;
  