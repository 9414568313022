import {
  Field,
  FieldArray,
  Form,
  FormElement,
  FormRenderProps,
} from "@progress/kendo-react-form";
import React, { useEffect, useRef } from "react";
import ShadowCard from "../../components/common/ShadowCard";
import { GridLayout, GridLayoutItem } from "@progress/kendo-react-layout";
import { Typography } from "@progress/kendo-react-common";
import FormTextField from "../../components/formFields/FormTextField";
import { requiredValidator } from "../../components/formFields/CommonValidator";
import FormSelectionField from "../../components/formFields/FormSelectionField";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { Button } from "@progress/kendo-react-buttons";
import ButtonWithLoading from "../../components/common/ButtonWithLoading";
import { closed } from "../../components/drawer/drawerSlice";
import { STATUSARRAY } from "../../_contstants/common";
import { createItem, getItemById, updateItem } from "./services/item.services";
import { clearItemDetail } from "./itemSlice";
import { LoadingPanel } from "../../components/layout/Loading";
import { useLocation, useNavigate } from "react-router-dom";
import { findAllActiveItemCategory } from "../itemcategory/services/itemcategory.services";
import { IItemCategory } from "../itemcategory/itemCategoryModel";
// import { IProductsubCategory } from "../product subcategory/productsubcategoryModel";
import { findAllActiveProductSubCategory } from "../product subcategory/services/productsubcategory.services";
import ImageArray from "./ImageArray";
import FormRichTextField from "../../components/formFields/FormRichTextField";
import { IProductsubCategory } from "../product subcategory/productsubcategoryModel";

const CreateItem: React.FC = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const location = useLocation();
  const ItemDetail = useAppSelector((state) => state.item.ItemDetail);
  const loading = useAppSelector((state) => state.item.loading);
  const formLoading = useAppSelector((state) => state.item.formLoading);
  const ItemCategoryList = useAppSelector(
    (state) => state.itemCategory.ItemCategoryList
  );
  const ProductSubCategoryList = useAppSelector(
    (state) => state.productsubcategory.ProductSubCategoryList
  );
  const item_guid = location.state?.item_guid;
  const gridRef = useRef<any>(null);
  useEffect(() => {
    if (item_guid) {
      dispatch(getItemById(item_guid));
    }
  }, [item_guid]);

  useEffect(() => {
    dispatch(findAllActiveItemCategory());
    dispatch(findAllActiveProductSubCategory());
    return () => {
      dispatch(clearItemDetail());
    };
  }, []);

  const filterItems = (ItemCategoryID: number) => {
    if (ItemCategoryID) {
      const result = ProductSubCategoryList.filter((item: any) => {
        return item.item_category_id === ItemCategoryID;
      });
      return result.map((item: IProductsubCategory) => {
        return {
          value: item?.id,
          label: item?.item_subcategory_name,
        };
      });
    }
  };

  const handleSubmit = async (values: any) => {
    const formData = {
      item_category_id: values.item_category_id
        ? +values.item_category_id
        : null,
      item_name: values.item_name,
      item_nick_name: values.item_nick_name,
      item_sub_category_id: values.item_sub_category_id
        ? +values.item_sub_category_id
        : null,
      remarks: values.remarks,
      isactive: values.isactive === 1,
    };

    const handleResponse = (response: any, successCallback: () => void) => {
      if (response?.meta?.requestStatus === "fulfilled") {
        successCallback();
      } else {
        console.error("Error in handleSubmit:", response);
      }
    };

    try {
      if (item_guid) {
        const editPayload = { item_guid, ...formData };
        const response = await dispatch(updateItem(editPayload));
        handleResponse(response, () => {
          dispatch(getItemById(item_guid));
          navigate("/item");
        });
      } else {
        const response = await dispatch(createItem(formData));
        handleResponse(response, () => {
          navigate("/item/edit", {
            state: { item_guid: response?.payload?.item_guid },
          });
        });
      }
    } catch (error) {
      console.error("Error in handleSubmit:", error);
      throw error;
    }
  };

  const handleFormClose = () => {
    dispatch(closed());
    navigate("/item");
  };

  const handleBack = () => {
    dispatch(closed());
    navigate("/item");
  };

  if (loading) return <LoadingPanel gridRef={gridRef} />;
  return (
    <>
      <Form
        onSubmit={handleSubmit}
        initialValues={ItemDetail}
        render={(formRenderProps: FormRenderProps) => (
          <FormElement>
            <ShadowCard style={{ padding: 12 }}>
              <GridLayout
                style={{ marginRight: 20 }}
                gap={{ rows: 0, cols: 10 }}
                cols={[
                  { width: "33.33%" },
                  { width: "33.33%" },
                  { width: "33.33%" },
                ]}
              >
                <GridLayoutItem colSpan={3}>
                  <Typography.h4>
                    {item_guid ? "Update Product" : "Create Product"}
                  </Typography.h4>
                </GridLayoutItem>
                <GridLayoutItem colSpan={3}>
                  {item_guid ? (
                    <Typography.h5>
                      Product Id :{" "}
                      <span style={{ color: "green" }}>
                        {ItemDetail.item_id}
                      </span>
                    </Typography.h5>
                  ) : (
                    ""
                  )}
                </GridLayoutItem>
                <GridLayoutItem>
                  <Field
                    name="item_name"
                    label="Product Name"
                    placeholder="Product Name"
                    component={FormTextField}
                    validator={requiredValidator}
                  />
                </GridLayoutItem>
                <GridLayoutItem>
                  <Field
                    name="item_nick_name"
                    label="Product Nick Name"
                    placeholder="Product Nick Name"
                    component={FormTextField}
                    validator={requiredValidator}
                  />
                </GridLayoutItem>
                <GridLayoutItem>
                  <Field
                    name="item_category_id"
                    label="Product Category"
                    placeholder="Product Category"
                    component={FormSelectionField}
                    validator={requiredValidator}
                    options={ItemCategoryList?.map(
                      (category: IItemCategory) => {
                        return {
                          value: category?.ID,
                          label: category?.category_name,
                        };
                      }
                    )}
                  />
                </GridLayoutItem>
                <GridLayoutItem>
                  <Field
                    name="item_sub_category_id"
                    label="Product Sub Category"
                    placeholder="Product Sub Category"
                    component={FormSelectionField}
                    validator={requiredValidator}
                    options={
                      ProductSubCategoryList &&
                      filterItems(
                        formRenderProps.valueGetter("item_category_id")
                      )
                    }
                  />
                </GridLayoutItem>
                <GridLayoutItem colSpan={2} rowSpan={2}>
                  <Field
                    name="remarks"
                    label="Remarks"
                    component={FormRichTextField}
                  />
                </GridLayoutItem>
                <GridLayoutItem>
                  <Field
                    id={"isactive"}
                    name={"isactive"}
                    component={FormSelectionField}
                    validator={requiredValidator}
                    label={"Status"}
                    options={STATUSARRAY?.map((status: any) => {
                      return {
                        value: status.value,
                        label: status.label,
                      };
                    })}
                  />
                </GridLayoutItem>
                <GridLayoutItem
                  colSpan={3}
                  style={{
                    textAlign: "end",
                    marginTop: "20px",
                    width: "100%",
                  }}
                >
                  <Button
                    type="button"
                    themeColor={"primary"}
                    style={{ marginRight: 4 }}
                    onClick={handleBack}
                  >
                    Go to Product List
                  </Button>
                  <Button
                    type="button"
                    fillMode={"outline"}
                    themeColor={"primary"}
                    style={{ marginRight: 4 }}
                    onClick={handleFormClose}
                  >
                    Cancel
                  </Button>
                  <ButtonWithLoading
                    label={!item_guid ? "Create" : "Update"}
                    type="submit"
                    disabled={!formRenderProps.allowSubmit || loading}
                    loading={loading}
                  />
                </GridLayoutItem>
              </GridLayout>
            </ShadowCard>
            {formLoading ? (
              <LoadingPanel gridRef={gridRef} />
            ) : (
              item_guid && (
                <ShadowCard style={{ marginTop: 10 }}>
                  <GridLayoutItem colSpan={3}>
                    <Typography.h4>Documents Upload</Typography.h4>
                  </GridLayoutItem>
                  <FieldArray
                    formRenderProps={formRenderProps}
                    component={ImageArray}
                    name="Images"
                  />
                </ShadowCard>
              )
            )}
          </FormElement>
        )}
      />
    </>
  );
};

export default CreateItem;
