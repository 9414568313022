import { Button } from "@progress/kendo-react-buttons";
import { Card, GridLayout, GridLayoutItem } from "@progress/kendo-react-layout";
import React, { useEffect, useRef } from "react";
import AppDialog, { DialogCloseButton } from "../../components/dialog/Dialog";
import { Typography } from "@progress/kendo-react-common";
import { MdDelete, MdOutlineImageNotSupported } from "react-icons/md";
import { requiredValidator } from "../../components/formFields/CommonValidator";
import {
  Field,
  FieldArrayRenderProps,
  FormRenderProps,
} from "@progress/kendo-react-form";
import { closeDialog, openDialog } from "../../components/dialog/dialogSlice";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { setGhatItemDeleteIndex, setOrderItemDetailsIndex } from "./ghatSlice";
// import { findAllActiveItem } from "../item/services/item.services";
// import { findAllActiveItemCategory } from "../itemcategory/services/itemcategory.services";
// import { findAllActivePurity } from "../purity/services/purity.services";
import { ErrorToast, SuccessToast } from "../../components/toast/Toasts";
import { deleteGhatItems } from "./services/ghat.services";
import FormSelectionField from "../../components/formFields/FormSelectionField";
import { IItem } from "../item/itemModel";
import { findbyOrderNo } from "../order/services/order.services";
// import _debounce from "lodash/debounce";
import { IItemCategory } from "../itemcategory/itemCategoryModel";
import moment from "moment";
import { LoadingPanel } from "../../components/layout/Loading";
import Slider from "react-slick";
import FormRichTextField from "../../components/formFields/FormRichTextField";
import FormNumericTextField from "../../components/formFields/FormNumericTextField";
import { setOrderID } from "../order/orderSlice";

import { setCurrentPage, setPageLimit } from "../pagination/paginationSlice";
import AlertBox from "../../components/common/AlertBox";
import { FaRegEye } from "react-icons/fa";
import { HeaderThElement } from "@progress/kendo-react-data-tools";
import { ImCross } from "react-icons/im";

import {
  GridCellProps,
  GridCustomCellProps,
  GridCustomHeaderCellProps,
  GridFilterChangeEvent,
  Grid as KendoGrid,
  GridColumn as Column,
  GridPageChangeEvent,
} from "@progress/kendo-react-grid";
import {
  CompositeFilterDescriptor,
  filterBy,
} from "@progress/kendo-data-query";
import FormTextField from "../../components/formFields/FormTextField";
import IconButton from "../../components/common/IconButton";

const ItemDetailsArray = (fieldArrayRenderProps: FieldArrayRenderProps) => {
  const dispatch = useAppDispatch();
  const ItemList = useAppSelector((state) => state.item.ItemList);
  const PurityList = useAppSelector((state) => state.purity.PurityList);
  const dialogName = useAppSelector((state) => state.dialog.dialogName);
  const ItemCategoryList = useAppSelector(
    (state) => state.itemCategory.ItemCategoryList
  );
  const GhatDeleteIndex = useAppSelector(
    (state) => state.ghat.GhatItemsDeleteIndex
  );

  const pushElementInarray = React.useCallback(() => {
    fieldArrayRenderProps.onPush({
      value: {
        order_no: null,
        item_id: null,
        item_description: " ",
        item_category_id: null,
        weight: null,
        purity: null,
      },
    });
  }, [fieldArrayRenderProps]);

  const handleOpenDeleteDialog = async (index: number) => {
    dispatch(openDialog("itemDaetailsArrayDeleteDialog"));
    dispatch(setGhatItemDeleteIndex(index));
  };

  const handleOrderView = (index: number) => {
    dispatch(openDialog("viewOrderDialog"));
    dispatch(setOrderID(fieldArrayRenderProps.value[index]?.order_no));
  };

  const filterItems = (ItemCategoryID: number) => {
    if (ItemCategoryID) {
      const result = ItemList.filter((item: any) => {
        return item.item_category_id === ItemCategoryID;
      });
      return result.map((item: IItem) => {
        return {
          value: item?.id,
          label: item?.item_name,
        };
      });
    }
  };
  const handleDeleteAction = React.useCallback(
    async (index: number) => {
      if (fieldArrayRenderProps?.value[index].id) {
        try {
          const response = await dispatch(
            deleteGhatItems(fieldArrayRenderProps?.value[index].id)
          );
          if (response.meta.requestStatus === "fulfilled") {
            SuccessToast(response?.payload?.Details || "Success")
            fieldArrayRenderProps.onRemove({ index: index });
            dispatch(closeDialog());
            dispatch(setGhatItemDeleteIndex(-1));
          }
        } catch (error: any) {
          ErrorToast(error?.response?.data?.Details || "Something went wrong");
          console.error("Error deleting Item Details:", error);
          return Promise.reject({
            message: error?.response?.data?.Details || "Something went wrong",
          });
        }
      } else {
        fieldArrayRenderProps.onRemove({ index: index });
        dispatch(closeDialog());
        dispatch(setGhatItemDeleteIndex(-1));
      }
    },
    [fieldArrayRenderProps]
  );

  const handleSelectOrder = (index: number) => {
    dispatch(openDialog("SelectOrderDialog"));
    dispatch(setOrderItemDetailsIndex(index));
  };

  return (
    <GridLayout cols={[{ width: "100%" }]}>
      {!fieldArrayRenderProps.value ? pushElementInarray() : null}
      {dialogName === "itemDaetailsArrayDeleteDialog" && (
        <AppDialog>
          <>
            <Typography.h5>{"Delete GhatItems Details"}</Typography.h5>
            <GridLayout>
              <GridLayoutItem>
                <Typography.p>
                  Are you sure you want to delete this?
                </Typography.p>
              </GridLayoutItem>
              <GridLayoutItem
                style={{
                  display: "flex",
                  justifyContent: "end",
                }}
              >
                <DialogCloseButton themeColor="error" />
                <Button
                  fillMode={"solid"}
                  themeColor={"error"}
                  size={"small"}
                  onClick={() => handleDeleteAction(GhatDeleteIndex)}
                  type="button"
                >
                  Delete
                </Button>
              </GridLayoutItem>
            </GridLayout>
          </>
        </AppDialog>
      )}

      <ViewOrderDetails />

      {fieldArrayRenderProps.value &&
        fieldArrayRenderProps.value.length > 0 &&
        fieldArrayRenderProps.value.map((GhatItems: any, index: number) => (
          <GridLayoutItem key={index}>
            <Card className="fieldarray-card">
              <GridLayout
                style={{ marginRight: 10 }}
                gap={{ rows: 0, cols: 10 }}
                cols={[{ width: "33%" }, { width: "33%" }, { width: "33%" }]}
              >
                <GridLayoutItem style={{ textAlign: "end" }} colSpan={3}>
                  <Button
                    fillMode={"solid"}
                    themeColor={"primary"}
                    size={"small"}
                    onClick={() => handleOrderView(index)}
                    disabled={
                      !fieldArrayRenderProps.formRenderProps.valueGetter(
                        `ghat_items.${index}.order_no`
                      )
                        ? true
                        : false
                    }
                    type="button"
                  >
                    View Order
                  </Button>
                </GridLayoutItem>
                <GridLayoutItem
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "end",
                  }}
                >
                  <Button
                    type="button"
                    fillMode={"solid"}
                    themeColor={"primary"}
                    style={{ width: "fit-content", padding: "5px" }}
                    onClick={() => handleSelectOrder(index)}
                  >
                    Select Order
                  </Button>
                  {fieldArrayRenderProps.formRenderProps.valueGetter(
                    `ghat_items.${index}.order_no`
                  ) !== null && (
                    <Field
                      name={`ghat_items.${index}.order_no`}
                      label="Order No"
                      placeholder="Order No"
                      disabled={"true"}
                      component={FormTextField}
                      validator={requiredValidator}
                    />
                  )}
                </GridLayoutItem>
                <SelectOrderDialog
                  formRenderProps={fieldArrayRenderProps.formRenderProps}
                  // index={index}
                />

                <GridLayoutItem>
                  <Field
                    name={`ghat_items.${index}.item_category_id`}
                    label="Product Category"
                    placeholder="Select Product Category"
                    component={FormSelectionField}
                    validator={requiredValidator}
                    disabled={
                      !fieldArrayRenderProps.value[index]?.order_no
                        ? false
                        : true
                    }
                    options={ItemCategoryList?.map(
                      (category: IItemCategory) => {
                        return {
                          value: category?.ID,
                          label: category?.category_name,
                        };
                      }
                    )}
                  />
                </GridLayoutItem>
                <GridLayoutItem>
                  <Field
                    name={`ghat_items.${index}.item_id`}
                    label="Product name"
                    placeholder="Select Product"
                    component={FormSelectionField}
                    validator={requiredValidator}
                    disabled={
                      !fieldArrayRenderProps.value[index]?.order_no
                        ? false
                        : true
                    }
                    options={
                      ItemList &&
                      filterItems(
                        fieldArrayRenderProps.formRenderProps.valueGetter(
                          `ghat_items.${index}.item_category_id`
                        )
                      )
                    }
                  />
                </GridLayoutItem>
                <GridLayoutItem colSpan={2} rowSpan={2}>
                  <Field
                    name={`ghat_items.${index}.item_description`}
                    label="Description"
                    placeholder="Description"
                    component={FormRichTextField}
                  />
                </GridLayoutItem>
                <GridLayoutItem rowSpan={2}>
                  <Field
                    name={`ghat_items.${index}.purity`}
                    label="Purity"
                    type="number"
                    placeholder="Select Purity"
                    component={FormSelectionField}
                    options={PurityList?.map((purity: any) => {
                      return {
                        value: purity.ID,
                        label: purity.purity_name,
                      };
                    })}
                  />
                  <Field
                    name={`ghat_items.${index}.weight`}
                    label="Estimated Weight"
                    placeholder="0.00"
                    type="number"
                    format="n3"
                    component={FormNumericTextField}
                    validator={requiredValidator}
                  />
                </GridLayoutItem>
                <GridLayoutItem
                  col={3}
                  style={{ textAlign: "end", marginTop: 10 }}
                >
                  <IconButton
                    onClick={() => handleOpenDeleteDialog(index)}
                    disabled={fieldArrayRenderProps.value.length === 1}
                    themeColor={"error"}
                    size={"small"}
                    fillMode={"solid"}
                    type="button"
                    style={{ height: 24, width: 24 }}
                  >
                    <MdDelete
                      className="absolute-position"
                      style={{ fontSize: "16px" }}
                    />
                  </IconButton>
                </GridLayoutItem>
              </GridLayout>
            </Card>
          </GridLayoutItem>
        ))}
      <GridLayoutItem style={{ marginBottom: 10, textAlign: "left" }}>
        <Button
          fillMode={"solid"}
          themeColor={"primary"}
          onClick={pushElementInarray}
          type="button"
        >
          Add Ghat Issue Data
        </Button>
      </GridLayoutItem>
    </GridLayout>
  );
};

export default ItemDetailsArray;

const ViewOrderDetails = () => {
  const orderDetail = useAppSelector((state) => state.order.OrderDetail);
  const dialogName = useAppSelector((state) => state.dialog.dialogName);
  const orderID = useAppSelector((state) => state.order.OrderID);
  const loading = useAppSelector((state) => state.order.loading);
  const gridRef = useRef<any>(null);
  const dispatch = useAppDispatch();
  useEffect(() => {
    if (orderID) {
      dispatch(findbyOrderNo(orderID));
    }
  }, [orderID]);

  const settings = {
    dots: true,
    infinite: true,
    autoplay: false,
    centerMode: true,
    centerPadding: "0px",
    autoplaySpeed: 2000,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: true,
    draggable: true,
    responsive: [
      {
        breakpoint: 991,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  const handleOpenImage = (url: string) => {
    if (url && typeof url === "string") {
      window.open(url);
    }
  };

  if (loading) return <LoadingPanel gridRef={gridRef} />;

  return (
    <>
      {dialogName === "viewOrderDialog" && (
        <AppDialog>
          <div style={{ width: 500, height: "75vh" }}>
            <GridLayout
              style={{
                marginRight: 10,
              }}
              gap={{ rows: 10, cols: 10 }}
              cols={[{ width: "50%" }, { width: "50%" }]}
            >
              <GridLayoutItem
                colSpan={2}
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                <Typography.h4>{"Order Details"}</Typography.h4>
                <ImCross
                  style={{
                    color: "white",
                    background: "red",
                    borderRadius: "50%",
                    padding: "5px",
                    fontSize: "24px",
                  }}
                  onClick={() => dispatch(closeDialog())}
                />
              </GridLayoutItem>
              <GridLayoutItem>
                <Typography.p>
                  <b>Order No :</b> {orderDetail.order_no}
                </Typography.p>
              </GridLayoutItem>
              <GridLayoutItem>
                <Typography.p>
                  <b>Product Name :</b> {orderDetail.item_name}
                </Typography.p>
              </GridLayoutItem>
              <GridLayoutItem>
                <Typography.p>
                  <b>Order Date :</b>{" "}
                  {orderDetail.order_date
                    ? moment(orderDetail.order_date).format("DD-MM-YYYY")
                    : " "}
                </Typography.p>
              </GridLayoutItem>
              <GridLayoutItem>
                <Typography.p>
                  <b>Delivery Date :</b>{" "}
                  {orderDetail.delivery_date
                    ? moment(orderDetail.delivery_date).format("DD-MM-YYYY")
                    : " "}
                </Typography.p>
              </GridLayoutItem>
              <GridLayoutItem>
                <Typography.p>
                  <b>Estimated Amount :</b> {orderDetail.estimate_amount}
                </Typography.p>
              </GridLayoutItem>
              <GridLayoutItem>
                <Typography.p>
                  <b>Purity:</b> {orderDetail.purity_name}
                </Typography.p>
              </GridLayoutItem>
              <GridLayoutItem>
                <Typography.p>
                  <b>Size :</b> {orderDetail.size}
                </Typography.p>
              </GridLayoutItem>
              <GridLayoutItem>
                <Typography.p>
                  <b>Color:</b> {orderDetail.color}
                </Typography.p>
              </GridLayoutItem>
              <GridLayoutItem></GridLayoutItem>
              <GridLayoutItem
                colSpan={2}
                style={{ display: "flex", justifyContent: "center" }}
              >
                <div className="slider-container">
                  {orderDetail.Images && orderDetail.Images.length > 1 ? (
                    <Slider {...settings}>
                      {orderDetail.Images &&
                        orderDetail.Images.length > 0 &&
                        orderDetail.Images.map((image: any) => (
                          <div>
                            <img
                              src={image.image_name}
                              style={{ width: "100%", height: "auto" }}
                            />
                            {/* <Label>{image.title}</Label> */}
                          </div>
                        ))}
                    </Slider>
                  ) : (
                    <>
                      {orderDetail.Images &&
                        orderDetail.Images.length > 0 &&
                        orderDetail.Images.map((image: any) => (
                          <div
                            style={{
                              width: "300px",
                              margin: "auto",
                            }}
                          >
                            <img
                              src={image.image_name}
                              style={{
                                width: "100%",
                                height: "auto",
                                objectFit: "contain",
                              }}
                              onClick={() => handleOpenImage(image.image_name)}
                            />
                            {/* <Label>{image.title}</Label> */}
                          </div>
                        ))}
                    </>
                  )}
                </div>
              </GridLayoutItem>
              {/* <GridLayoutItem
                  colSpan={2}
                  style={{
                    textAlign: "center",
                    marginTop: 20,
                  }}
                >
                  <DialogCloseButton themeColor="error" />
                </GridLayoutItem> */}
            </GridLayout>
          </div>
        </AppDialog>
      )}
    </>
  );
};

interface SelectDialogProps {
  formRenderProps: FormRenderProps;
}

const SelectOrderDialog = ({ formRenderProps }: SelectDialogProps) => {
  const orderList = useAppSelector((state) => state.order.OrderList);
  const orderItemIndex = useAppSelector((state) => state.ghat.orderItemIndex);

  const dispatch = useAppDispatch();
  const dialogName = useAppSelector((state) => state.dialog?.dialogName);
  interface PageState {
    skip: number;
    take: number;
  }

  const initialDataState: PageState = { skip: 0, take: 10 };

  const currentPage = useAppSelector((state) => state.pagination.currentPage);
  const pageLimit = useAppSelector((state) => state.pagination.pageLimit);
  const [page, setPage] = React.useState<PageState>(initialDataState);
  const loading = useAppSelector((state) => state.order.loading);
  const gridRef = useRef<any>(null);

  const [filter, setFilter] = React.useState<
    CompositeFilterDescriptor | undefined
  >(undefined);


  const pageChange = (event: GridPageChangeEvent) => {
    const newPage = event.page.skip / event.page.take;
    dispatch(setPageLimit(event.page.take));
    dispatch(setCurrentPage(newPage));
  };

  const MyImageCommandCell = (props: GridCellProps) => {
    const handlClickItem = (image: string) => {
      if (image && typeof image === "string") {
        window.open(image);
      }
    };
    if (props.dataItem?.image === null) {
      return (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            height: "50px",
          }}
        >
          <MdOutlineImageNotSupported style={{ fontSize: "30px" }} />
          <p style={{ margin: 0, fontSize: "12px" }}>No Image!</p>
        </div>
      );
    }
    return (
      <td className="action-td main-image">
        <div
          style={{
            position: "relative",
            border: "1px dotted lightgrey",
            borderRadius: "5px",
            height: "50px",
            overflow: "hidden",
          }}
        >
          <img
            className="product-image"
            src={props.dataItem?.image_name}
            alt=""
            style={{
              height: "100%",
              width: "100%",
              objectFit: "cover",
              borderRadius: "5px",
            }}
          />
          <div className="image-view">
            <FaRegEye
              style={{
                color: "white",
                height: 24,
                width: 24,
                cursor: "pointer",
              }}
              onClick={() => handlClickItem(props.dataItem?.image_name)}
            />
          </div>
        </div>
      </td>
    );
  };

  const MyEditCommandCell = (props: GridCellProps) => {
    const handleSelectOrder = (order_no: number) => {
      formRenderProps.onChange(`ghat_items.${orderItemIndex}.order_no`, {
        value: order_no,
      });
      const getOrderNowiseData = async () => {
        const updateField = (field: any, value: any) =>
          formRenderProps?.onChange(`ghat_items.${orderItemIndex}.${field}`, {
            value,
          });

        if (!order_no || formRenderProps.modified !== true) {
          updateField("item_category_id", null);
          updateField("item_id", null);
          updateField("item_description", " ");
          updateField("purity", null);
          updateField("weight", "");
          return;
        }

        try {
          const { payload: res, meta } = await dispatch(
            findbyOrderNo(order_no)
          );
          if (meta.requestStatus === "fulfilled" && res) {
            updateField("item_category_id", res?.item_category_id);
            updateField("item_id", res?.item_id);
            updateField("item_description", res?.description || " ");
            updateField("purity", res?.purity);
            updateField("weight", res?.weight ? +res?.weight : "");
          } else {
            updateField("item_category_id", null);
            updateField("item_id", null);
            updateField("item_description", " ");
            updateField("purity", null);
            updateField("weight", "");
            updateField("order_no", null);
          }
        } catch (error) {
          console.error(error);
        }
      };
      getOrderNowiseData();
      dispatch(closeDialog());
    };

    return (
      <td className="action-td">
        <Button
          type="button"
          fillMode="solid"
          themeColor={"primary"}
          size="small"
          title="Select Order"
          onClick={() => handleSelectOrder(props.dataItem?.order_no)}
        >
          Select
        </Button>
      </td>
    );
  };
  const handleFilterChange = (e: GridFilterChangeEvent) => {
    setFilter(e.filter);
    setPage({ skip: 0, take: page.take });
  };

  const HeaderCustomCell = (props: GridCustomHeaderCellProps) => (
    <HeaderThElement
      columnId={props.thProps?.columnId || ""}
      {...props.thProps}
      className={`table-header ${props.index === 5 ? "locked-header" : ""}`}
    >
      {props.children}
    </HeaderThElement>
  );
  const CustomCell = (props: any) => {
    return (
      <td {...props.tdProps} colSpan={1} className={"table-cell"}>
        {props.children}
      </td>
    );
  };
  const MyDataCustomCell = (props: GridCustomCellProps) => (
    <CustomCell {...props} />
  );

  return (
    <>
      {dialogName === "SelectOrderDialog" && (
        <AppDialog>
          <div style={{ width: "80vw", minHeight: "30vh" }}>
            {!loading ? (
              <GridLayout
                style={{
                  marginLeft: 30,
                }}
                gap={{ rows: 10, cols: 10 }}
              >
                <GridLayoutItem
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                  }}
                >
                  <Typography.h4>{"Select Order"}</Typography.h4>

                  <ImCross
                    style={{
                      color: "white",
                      background: "red",
                      borderRadius: "50%",
                      padding: "5px",
                      fontSize: "24px",
                    }}
                    onClick={() => dispatch(closeDialog())}
                  />
                </GridLayoutItem>
                <GridLayoutItem>
                  {orderList && orderList.length ? (
                    <KendoGrid
                      className="selection-dialog"
                      style={{ maxHeight: "calc(100vh - 152px)" }}
                      filterable={true}
                      filter={filter}
                      data={filter ? filterBy(orderList, filter) : orderList}
                      total={orderList[0]?.itemcount || orderList.length}
                      onFilterChange={handleFilterChange}
                      skip={currentPage * pageLimit}
                      take={pageLimit}
                      pageable={{
                        buttonCount: 5,
                        pageSizes: [10, 50, 100, 300, 500],
                        pageSizeValue: pageLimit,
                        type: "input",
                      }}
                      onPageChange={pageChange}
                      cells={{
                        headerCell: HeaderCustomCell,
                        data: MyDataCustomCell,
                      }}
                    >
                      <Column
                        field="image_name"
                        title="Image"
                        width={100}
                        cell={MyImageCommandCell}
                        filterable={false}
                        // locked={true}
                      />
                      <Column
                        field="order_no"
                        title="Order No"
                        width={"100px"}
                        cell={(props: any) => (
                          <td>
                            <div style={{ color: "green" }}>
                              <strong>{props.dataItem?.order_no}</strong>
                            </div>
                          </td>
                        )}
                      />
                      <Column field="party_name" title="Party Name" />
                      <Column field="item_name" title="Product Name" />
                      <Column field="order_date" title="Order Date" />
                      <Column field="delivery_date" title="Delivery Date" />
                      <Column
                        field="order_no"
                        title="Actions"
                        cell={MyEditCommandCell}
                        width={"150px"}
                        filterable={false}
                        locked={true}
                      />
                    </KendoGrid>
                  ) : (
                    <AlertBox />
                  )}
                </GridLayoutItem>
                <GridLayoutItem
                  style={{
                    textAlign: "center",
                    marginTop: 20,
                  }}
                ></GridLayoutItem>
              </GridLayout>
            ) : (
              <LoadingPanel gridRef={gridRef} />
            )}
          </div>
        </AppDialog>
      )}
    </>
  );
};
