import { configureStore } from "@reduxjs/toolkit";
import { getDefaultMiddleware } from "@reduxjs/toolkit";
import dialogSlice from "../components/dialog/dialogSlice";
import loginReducer from "../features/login/loginSlice";
import countrySlice from "../features/country/countrySlice";
import drawerSlice from "../components/drawer/drawerSlice";
import stateSlice from "../features/state/stateSlice";
import citySlice from "../features/city/citySlice";
import itemcategorySlice from "../features/itemcategory/itemcategorySlice";
import itemSlice from "../features/item/itemSlice";
import processSlice from "../features/process/processSlice";
import prioritySlice from "../features/priority/prioritySlice";
import partySlice from "../features/party/partySlice";
import karigarSlice from "../features/karigar/karigarSlice";
import orderSlice from "../features/order/orderSlice";
import ghatSlice from "../features/ghat/ghatSlice";
import ghatReceiptSlice from "../features/ghatreceipt/ghatReceiptSlice";
import productsubcategorySlice from "../features/product subcategory/productsubcategorySlice";
import puritySlice from "../features/purity/puritySlice";
import unitSlice from "../features/unit/unitSlice";
import chargesSlice from "../features/charges/chargesSlice";
import paginationSlice from "../features/pagination/paginationSlice";
import userSlice from "../features/user/userSlice";
import userTypeSlice from "../features/userType/userTypeSlice";
import menuSlice from "../features/menu/menuSlice";
import rightsSlice from "../features/rights/rightsSlice";

const customizedMiddleware = () =>
  getDefaultMiddleware({
    serializableCheck: false,
  });

const store = configureStore({
  reducer: {
    login: loginReducer,
    dialog: dialogSlice,
    drawer: drawerSlice,
    country: countrySlice,
    user: userSlice,
    userType: userTypeSlice,
    menu: menuSlice,
    rights: rightsSlice,
    state: stateSlice,
    city: citySlice,
    itemCategory: itemcategorySlice,
    item: itemSlice,
    Process: processSlice,
    priority: prioritySlice,
    party: partySlice,
    karigar: karigarSlice,
    order: orderSlice,
    ghat: ghatSlice,
    ghatreceipt: ghatReceiptSlice,
    productsubcategory: productsubcategorySlice,
    purity: puritySlice,
    unit: unitSlice,
    charges: chargesSlice,
    pagination: paginationSlice,
  },
  middleware: customizedMiddleware,
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export default store;
