import {
  Field,
  Form,
  FormElement,
  FormRenderProps,
} from "@progress/kendo-react-form";
import { GridLayout, GridLayoutItem } from "@progress/kendo-react-layout";
import React, { useEffect, useRef } from "react";
import FormTextField from "../../components/formFields/FormTextField";
import { requiredValidator } from "../../components/formFields/CommonValidator";
import { Button } from "@progress/kendo-react-buttons";
import ButtonWithLoading from "../../components/common/ButtonWithLoading";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { clearStateDetails } from "./stateSlice";
import { closed } from "../../components/drawer/drawerSlice";
import {
  createState,
  getAllStates,
  getStateById,
  updateState,
} from "./services/state.services";
import { LoadingPanel } from "../../components/layout/Loading";
import FormSelectionField from "../../components/formFields/FormSelectionField";
import { getAllActiveCountries } from "../country/services/country.services";
import { STATUSARRAY } from "../../_contstants/common";

const CreateState: React.FC = () => {
  const dispatch = useAppDispatch();
  const gridRef = useRef<any>(null);

  const CountryList = useAppSelector((state) => state.country.CountryList);
  const StateDetail = useAppSelector((state) => state.state.StateDetail);
  const state_guid = useAppSelector((state) => state.drawer.data);
  const formLoading = useAppSelector((state) => state.state.formLoading);

  useEffect(() => {
    dispatch(getAllActiveCountries());
    return () => {
      dispatch(clearStateDetails());
      dispatch(closed());
    };
  }, []);

  useEffect(() => {
    if (state_guid) {
      dispatch(getStateById(state_guid));
    }
  }, [state_guid]);

  /* This Function calls when Form submit button Clicked */
  const handleSubmit = async (values: any) => {
    try {
      if (!state_guid) {
        const insertPayload = {
          state_name: values.state_name,
          country_id: values.country_id,
          isactive: values.isactive === 1,
        };
        const response = await dispatch(createState(insertPayload));
        if (response?.meta?.requestStatus === "fulfilled") {
          dispatch(closed());
          dispatch(clearStateDetails());
          dispatch(getAllStates());
        }
      } else {
        const editPayload = {
          state_guid: state_guid,
          state_name: values.state_name,
          country_id: values.country_id,
          isactive: values.isactive === 1,
        };
        const response = await dispatch(updateState(editPayload));
        if (response?.meta?.requestStatus === "fulfilled") {
          dispatch(closed());
          dispatch(clearStateDetails());
          dispatch(getAllStates());
        }
      }
    } catch (error) {
      console.error("Error in handleSubmit:", error);
      throw error;
    }
  };

  const handleFormClose = () => {
    dispatch(closed());
  };

  if (formLoading) return <LoadingPanel gridRef={gridRef} />;
  return (
    <Form
      onSubmit={handleSubmit}
      initialValues={StateDetail}
      render={(formRenderProps: FormRenderProps) => (
        <FormElement style={{ width: "100%" }}>
          <GridLayout
            style={{
              display: "flex",
              flexWrap: "wrap",
            }}
            gap={{ rows: 10 }}
            cols={[{ width: "100%" }]}
          >
            <GridLayoutItem style={{ width: "100%" }}>
              <Field
                name="state_name"
                label="State Name"
                placeholder="State Name"
                component={FormTextField}
                validator={requiredValidator}
              />
            </GridLayoutItem>
            <GridLayoutItem style={{ width: "100%" }}>
              <Field
                name={"country_id"}
                label={"Country Name"}
                placeholder="Country Name"
                component={FormSelectionField}
                validator={requiredValidator}
                options={CountryList.map((country: any) => {
                  return {
                    value: country.id,
                    label: country.country_name,
                  };
                })}
              />
            </GridLayoutItem>
            <GridLayoutItem style={{ width: "100%" }}>
              <Field
                id={"IsActive"}
                name={"isactive"}
                component={FormSelectionField}
                label={"Status"}
                options={STATUSARRAY?.map((status: any) => {
                  return {
                    value: status.value,
                    label: status.label,
                  };
                })}
              />
            </GridLayoutItem>
            <GridLayoutItem
              colSpan={3}
              style={{
                textAlign: "end",
                marginTop: "10px",
                width: "100%",
              }}
            >
              <Button
                type="button"
                fillMode={"outline"}
                themeColor={"primary"}
                style={{ marginRight: 4 }}
                onClick={handleFormClose}
              >
                Cancel
              </Button>
              <ButtonWithLoading
                label={state_guid ? "Update" : "Create"}
                type="submit"
                disabled={!formRenderProps.allowSubmit || formLoading}
                loading={formLoading}
              />
            </GridLayoutItem>
          </GridLayout>
        </FormElement>
      )}
    />
  );
};

export default CreateState;
