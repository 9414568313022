import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { IItem, IItemInitial } from "./itemModel";
import {
  InsertImage,
  ItemActiveInactive,
  createItem,
  deleteImage,
  deleteItem,
  findAllActiveItem,
  findbyItemCode,
  getAllItem,
  getImageById,
  getItemById,
  updateImage,
  updateItem,
} from "./services/item.services";

const initialState: IItemInitial = {
  loading: false,
  formLoading: false,
  error: "",
  ItemList: [],
  ImageList: [],
  ItemCategoryID: null,
  ItemDetail: {
    id: null,
    item_category_id: null,
    item_name: "",
    item_nick_name: "",
    category_name: "",
    item_subcategory_name: "",
    remarks: "",
    item_sub_category_id: null,
    image_uploads: "",
    isactive: 1,
    Images: [
      {
        id: null,
        image_guid: null,
        isreadyimage: false,
        item_id: null,
        title: "",
        image_name: "",
      },
    ],
  },
  DeleteIndex: 1,
};

const itemSlice = createSlice({
  name: "item",
  initialState,
  reducers: {
    clearItemDetail: (state) => {
      state.ItemDetail = initialState.ItemDetail;
    },
    setItemID: (state, action) => {
      state.ItemCategoryID = action.payload;
    },
    clearItemID: (state) => {
      state.ItemCategoryID = null;
    },
    clearImageList: (state) => {
      state.ImageList = initialState.ImageList;
    },
    setImageArrayDeleteIndex: (state, action) => {
      state.DeleteIndex = action.payload;
    },
  },
  extraReducers: (builder) => {
    /* For getAll getAllItemCategory */

    builder.addCase(getAllItem.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(
      getAllItem.fulfilled,
      (state, action: PayloadAction<IItem[]>) => {
        state.loading = false;
        state.ItemList = action.payload;
      }
    );
    builder.addCase(getAllItem.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message || "Something went wrong";
      state.ItemList = [];
    });

    /* For findAllActiveItem */

    builder.addCase(findAllActiveItem.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(
      findAllActiveItem.fulfilled,
      (state, action: PayloadAction<IItem[]>) => {
        state.loading = false;
        state.ItemList = action.payload || [];
      }
    );
    builder.addCase(findAllActiveItem.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message || "Something went wrong";
      state.ItemList = [];
    });

    /* For createItemCategory and insert Image */

    builder.addCase(createItem.pending, (state) => {
      state.loading = false;
      state.error = "";
    });
    builder.addCase(createItem.fulfilled, (state) => {
      state.loading = false;
    });
    builder.addCase(createItem.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message || "Something went wrong";
    });

    builder.addCase(InsertImage.pending, (state) => {
      state.formLoading = true;
      state.error = "";
    });
    builder.addCase(InsertImage.fulfilled, (state) => {
      state.formLoading = false;
    });
    builder.addCase(InsertImage.rejected, (state, action) => {
      state.formLoading = false;
      state.error = action.error.message || "Something went wrong";
    });

    /* For updateItem and Update Image */

    builder.addCase(updateItem.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(updateItem.fulfilled, (state) => {
      state.loading = false;
    });
    builder.addCase(updateItem.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message || "Something went wrong";
    });

    builder.addCase(updateImage.pending, (state) => {
      state.formLoading = true;
      state.error = "";
    });
    builder.addCase(updateImage.fulfilled, (state) => {
      state.formLoading = false;
    });
    builder.addCase(updateImage.rejected, (state, action) => {
      state.formLoading = false;
      state.error = action.error.message || "Something went wrong";
    });

    /* For DeleteItem and Delete Image*/

    builder.addCase(deleteItem.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(deleteItem.fulfilled, (state) => {
      state.loading = false;
    });
    builder.addCase(deleteItem.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message || "Something went wrong";
    });

    builder.addCase(deleteImage.pending, (state) => {
      state.formLoading = true;
      state.error = "";
    });
    builder.addCase(deleteImage.fulfilled, (state) => {
      state.formLoading = false;
    });
    builder.addCase(deleteImage.rejected, (state, action) => {
      state.formLoading = false;
      state.error = action.error.message || "Something went wrong";
    });

    /* For ItemActiveInactive */

    builder.addCase(ItemActiveInactive.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(ItemActiveInactive.fulfilled, (state) => {
      state.loading = false;
    });
    builder.addCase(ItemActiveInactive.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message || "Something went wrong";
    });

    /* For getItemById and getImageById */

    builder.addCase(getItemById.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(getItemById.fulfilled, (state, action: any) => {
      state.loading = false;
      state.ItemDetail = action.payload;
    });
    builder.addCase(getItemById.rejected, (state, action) => {
      state.loading = false;
      state.ItemDetail = initialState.ItemDetail;
      state.error = action.error.message || "Something went wrong";
    });

    builder.addCase(getImageById.pending, (state) => {
      state.formLoading = true;
      state.error = "";
    });
    builder.addCase(getImageById.fulfilled, (state, action) => {
      state.formLoading = false;
      state.ImageList = action.payload || [];
    });
    builder.addCase(getImageById.rejected, (state, action) => {
      state.formLoading = false;
      state.ImageList = initialState.ImageList;
      state.error = action.error.message || "Something went wrong";
    });

    builder.addCase(findbyItemCode.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(findbyItemCode.fulfilled, (state, action: any) => {
      state.loading = false;
      state.ItemDetail = action.payload;
    });
    builder.addCase(findbyItemCode.rejected, (state, action) => {
      state.loading = false;
      state.ItemDetail = initialState.ItemDetail;
      state.error = action.error.message || "Something went wrong";
    });
  },
});

export const {
  clearItemDetail,
  clearItemID,
  setItemID,
  setImageArrayDeleteIndex,
  clearImageList,
} = itemSlice.actions;
export default itemSlice.reducer;
