import React, { useEffect, useRef, useState } from "react";
import {
  Field,
  FieldArray,
  Form,
  FormElement,
  FormRenderProps,
} from "@progress/kendo-react-form";
import { GridLayout, GridLayoutItem } from "@progress/kendo-react-layout";
import { Typography } from "@progress/kendo-react-common";
import { Button } from "@progress/kendo-react-buttons";
import {
  GridCellProps,
  GridCustomCellProps,
  GridCustomHeaderCellProps,
  GridFilterChangeEvent,
  Grid as KendoGrid,
  GridColumn as Column,
  GridPageChangeEvent,
} from "@progress/kendo-react-grid";
import {
  CompositeFilterDescriptor,
  filterBy,
} from "@progress/kendo-data-query";
import {
  HeaderThElement,
  PagerTargetEvent,
} from "@progress/kendo-react-data-tools";

import { useLocation, useNavigate } from "react-router-dom";

import ShadowCard from "../../components/common/ShadowCard";
import FormTextField from "../../components/formFields/FormTextField";
import {
  requiredValidator,
  skipValidator,
} from "../../components/formFields/CommonValidator";
import FormSelectionField from "../../components/formFields/FormSelectionField";
import ButtonWithLoading from "../../components/common/ButtonWithLoading";
import { LoadingPanel } from "../../components/layout/Loading";
import { closed } from "../../components/drawer/drawerSlice";
import FormDatePicker from "../../components/formFields/FormDateField";
import FormRichTextField from "../../components/formFields/FormRichTextField";
import FormNumericTextField from "../../components/formFields/FormNumericTextField";
import { closeDialog, openDialog } from "../../components/dialog/dialogSlice";
import AppDialog from "../../components/dialog/Dialog";
import AlertBox from "../../components/common/AlertBox";

import {
  createOrder,
  getOrderById,
  updateOrder,
} from "./services/order.services";
import { findAllActiveParty } from "../party/services/party.services";
import {
  findAllActiveItem,
  getImageById,
} from "../item/services/item.services";
import { findAllActivePriority } from "../priority/services/priority.services";
import { findAllActivePurity } from "../purity/services/purity.services";

import { clearOrderDetail } from "./orderSlice";
import { useAppDispatch, useAppSelector } from "../../app/hooks";

import { RootState } from "../../app/store";

import moment from "moment";
import { findIndex } from "lodash";
import Slider from "react-slick";

import ProcessDetailArray from "./ProcessDetailArray";
import SizeDetailArray from "./SizeDetailArray";

import { FaRegEye } from "react-icons/fa";
import { ImCross } from "react-icons/im";
import { MdOutlineImageNotSupported } from "react-icons/md";
import { clearImageList } from "../item/itemSlice";
import { findAllActiveKarigar } from "../karigar/services/karigar.services";
import { findAllActiveProcess } from "../process/services/process.services";
// import { setCurrentPage, setPageLimit } from "../pagination/paginationSlice";

interface IChangeWatcher {
  formRenderProps: FormRenderProps;
}
const OrderChangeWatcher: React.FC<IChangeWatcher> = ({ formRenderProps }) => {
  const dispatch = useAppDispatch();
  const itemList = useAppSelector((state) => state.item.ItemList);
  const processdetails = formRenderProps.valueGetter("processdetails");
  const item_ID = formRenderProps.valueGetter("item_id");
  const isItemRef = useRef(true);

  /* Item Details Change Watcher */
  useEffect(() => {
    if (!isItemRef.current) {
      const ItemIndex = findIndex(itemList, { id: item_ID });
      if (ItemIndex > -1) {
        const ItemData = itemList[ItemIndex];
        const remarksValue = ItemData.remarks;
        dispatch(getImageById(ItemData?.id));
        processdetails.map((item: any, index: number) =>
          formRenderProps.onChange(`processdetails.${index}.image_id`, {
            value: null,
          })
        );
        formRenderProps.onChange("remark", {
          value: remarksValue || " ",
        });
        formRenderProps.onChange("item_name", { value: ItemData.item_name });
        formRenderProps.onChange("item_code_id", { value: ItemData.item_id });
      } else {
        formRenderProps.onChange("remark", { value: "" });
        formRenderProps.onChange("item_code_id", { value: "" });
        formRenderProps.onChange("item_name", { value: "" });
      }
    } else {
      isItemRef.current = false;
    }
  }, [item_ID]);

  /* Date Change Watcher */
  useEffect(() => {
    if (processdetails && processdetails.length > 0) {
      processdetails?.map((item: any, index: number) => {
        const receive_date = item?.given_date
          ? moment(item?.given_date)
              .add(+item?.duration_days || 0, "days")
              .toDate()
          : "";
        formRenderProps.onChange(`processdetails.${index}.receive_date`, {
          value: receive_date || "",
        });
        formRenderProps.onChange(`processdetails.${index}.description`, {
          value: processdetails[index]?.description,
        });
      });
    }
  }, [
    processdetails?.map((item: any) => item?.duration_days).join("-"),
    processdetails?.map((item: any) => item?.given_date).join("-"),
    processdetails?.map((item: any) => item?.description).join("-"),
  ]);
  return null;
};

const CreateOrder: React.FC = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const location = useLocation();
  const [formKey, setFormKey] = useState(1);
  const OrderDetail = useAppSelector(
    (state: RootState) => state.order.OrderDetail
  );

  const partyList = useAppSelector((state) => state.party.PartyList);
  const priorityList = useAppSelector((state) => state.priority.PriorityList);
  const PurityList = useAppSelector((state) => state.purity.PurityList);
  const formLoading = useAppSelector((state) => state.order.formLoading);
  const order_guid = location.state?.ID;
  const gridRef = useRef<any>(null);
  const today = new Date();
  const maxDate =
    new Date(today.getFullYear(), today.getMonth(), today.getDate()) || null;
  useEffect(() => {
    dispatch(findAllActiveParty());
    dispatch(findAllActivePriority());
    dispatch(findAllActivePurity());
    dispatch(findAllActiveItem());
    dispatch(findAllActiveKarigar());
    dispatch(findAllActiveProcess());

    if (order_guid) {
      dispatch(getOrderById(order_guid));
    }
    return () => {
      dispatch(closed());
      dispatch(clearOrderDetail());
      dispatch(clearImageList());
    };
  }, []);

  useEffect(() => {
    if (OrderDetail.item_id) {
      dispatch(getImageById(OrderDetail.item_id));
    }
  }, [OrderDetail.item_id]);

  const handleSubmit = async (values: any) => {
    const formData = {
      order_date: values.order_date
        ? moment(values.order_date).format("YYYY-MM-DD")
        : "",
      delivery_date: values.delivery_date
        ? moment(values.delivery_date).format("YYYY-MM-DD")
        : "",
      party_id: values.party_id ? values.party_id : null,
      item_id: values.item_id ? values.item_id : null,
      description: values.description,
      estimate_weight: values.estimate_weight ? +values.estimate_weight : null,
      purity: values.purity ? +values.purity : null,
      priority_id: values.priority_id,
      item_code_id: values.item_code_id,
      color: values.color,
      estimate_amount: values.estimate_amount ? +values.estimate_amount : null,
      remark: values.remark,
      size: values.size,
      isactive: true,
      processdetails: values.processdetails
        ? values.processdetails.map((process_info: any) => {
            return {
              order_id: process_info.order_id,
              process_id: process_info.process_id
                ? +process_info.process_id
                : null,
              estimated_weight: process_info.estimated_weight
                ? +process_info.estimated_weight
                : null,
              karigar_id: process_info.karigar_id
                ? +process_info.karigar_id
                : null,
              given_date: process_info.given_date
                ? moment(process_info.given_date).format("YYYY-MM-DD")
                : "",
              duration_days: process_info.duration_days
                ? +process_info.duration_days
                : null,
              image_id: process_info.image_id ? +process_info.image_id : null,
              description: process_info.description,
              receive_date: process_info.receive_date
                ? moment(process_info.receive_date).format("YYYY-MM-DD")
                : "",
            };
          })
        : [],
      sizedetails: values.sizedetails
        ? values.sizedetails.map((process_info: any) => {
            return {
              title: process_info.title,
              value: process_info.value,
            };
          })
        : [],
    };
    if (order_guid) {
      const editPayload = {
        id: values.id,
        order_guid: order_guid,
        ...formData,
      };
      try {
        const response = await dispatch(updateOrder(editPayload));
        if (response?.meta?.requestStatus === "fulfilled") {
          dispatch(clearOrderDetail());
          navigate("/order");
        }
      } catch (error) {
        console.error("Error in handleSubmit:", error);
        throw error;
      }
    } else {
      try {
        const response = await dispatch(createOrder(formData));
        if (response?.meta?.requestStatus === "fulfilled") {
          dispatch(clearOrderDetail());
          navigate("/order");
        }
      } catch (error) {
        console.error("Error in handleSubmit:", error);
        throw error;
      }
    }
  };

  const handleFormClose = () => {
    dispatch(closed());
    dispatch(clearOrderDetail());
    dispatch(clearImageList());
    navigate("/order");
  };

  const handleSelectProduct = () => {
    dispatch(openDialog("SelectProductDialog"));
  };

  useEffect(() => {
    setFormKey(formKey + 1);
  }, [OrderDetail]);

  if (formLoading) return <LoadingPanel gridRef={gridRef} />;

  return (
    <>
      <Form
        onSubmit={handleSubmit}
        // key={formKey}
        initialValues={OrderDetail}
        render={(formRenderProps: FormRenderProps) => (
          <FormElement>
            <ShadowCard style={{ padding: 12 }}>
              <GridLayout
                style={{ marginRight: 20 }}
                gap={{ rows: 0, cols: 10 }}
                cols={[
                  { width: "33.33%" },
                  { width: "33.33%" },
                  { width: "33.33%" },
                ]}
              >
                <GridLayoutItem colSpan={3}>
                  <Typography.h4>
                    {order_guid ? "Update Order" : "New Order"}
                  </Typography.h4>
                </GridLayoutItem>
                <GridLayoutItem colSpan={3}>
                  {order_guid ? (
                    <Typography.h5>
                      Order No :{" "}
                      <span style={{ color: "green" }}>
                        {OrderDetail.order_no}
                      </span>
                    </Typography.h5>
                  ) : (
                    ""
                  )}
                </GridLayoutItem>
                <GridLayoutItem>
                  <Field
                    name="party_id"
                    label="Vepari Name"
                    placeholder="Vepari Name"
                    component={FormSelectionField}
                    validator={requiredValidator}
                    options={partyList?.map((party: any) => {
                      return {
                        value: party.id,
                        label: party.party_name,
                      };
                    })}
                  />
                </GridLayoutItem>
                <GridLayoutItem>
                  <Field
                    name="order_date"
                    label="Order Date"
                    placeholder="day/month/year"
                    format={"dd/MM/yyyy"}
                    max={maxDate}
                    component={FormDatePicker}
                    validator={requiredValidator}
                    value={new Date()}
                  />
                </GridLayoutItem>
                <GridLayoutItem>
                  <Field
                    name="delivery_date"
                    label="Delivery Date"
                    placeholder="day/month/year"
                    format={"dd/MM/yyyy"}
                    value={new Date()}
                    component={FormDatePicker}
                    validator={requiredValidator}
                  />
                </GridLayoutItem>
                <GridLayoutItem
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "end",
                  }}
                >
                  <Button
                    type="button"
                    fillMode={"solid"}
                    themeColor={"primary"}
                    style={{ width: "fit-content", padding: "5px" }}
                    onClick={handleSelectProduct}
                  >
                    Select Product
                  </Button>
                  {formRenderProps.valueGetter("item_id") !== null && (
                    <Field
                      name="item_name"
                      label="Product Name"
                      placeholder="Product Name"
                      disabled
                      value={""}
                      component={FormTextField}
                      validator={requiredValidator}
                    />
                  )}
                </GridLayoutItem>
                <GridLayoutItem>
                  <Field
                    name="item_code_id"
                    label="Product ID"
                    placeholder="Product ID"
                    disabled
                    component={FormTextField}
                    validator={!order_guid ? requiredValidator : skipValidator}
                  />
                </GridLayoutItem>
                <GridLayoutItem
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "end",
                  }}
                >
                  {formRenderProps.valueGetter("item_id") !== null && (
                    <Button
                      type="button"
                      themeColor={"success"}
                      onClick={() => dispatch(openDialog("showProductImage"))}
                      fillMode={"link"}
                    >
                      View Images
                    </Button>
                  )}
                </GridLayoutItem>
                <ShowProductImageDialog />
                <GridLayoutItem
                  style={{ width: "100%", display: "flex", gap: 10 }}
                >
                  <Field
                    wrapperClassName="w-100"
                    name="priority_id"
                    label="Priority"
                    placeholder="Select Priority"
                    type="number"
                    component={FormSelectionField}
                    // validator={requiredValidator}
                    options={priorityList?.map((priority: any) => {
                      return {
                        value: priority.ID,
                        label: priority.priority,
                      };
                    })}
                  />
                  <Field
                    wrapperClassName="w-100"
                    name="estimate_weight"
                    label="Estimate Weight"
                    placeholder="0.000"
                    format="n3"
                    type="number"
                    component={FormNumericTextField}
                    validator={requiredValidator}
                  />
                </GridLayoutItem>
                <GridLayoutItem
                  style={{ width: "100%", display: "flex", gap: 10 }}
                >
                  <Field
                    wrapperClassName="w-100"
                    name="estimate_amount"
                    label="Estimate Rs"
                    placeholder="0"
                    type="number"
                    // min={0}
                    component={FormNumericTextField}
                    // validator={requiredValidator}
                  />
                  <Field
                    wrapperClassName="w-100"
                    name="size"
                    label="Size"
                    placeholder="0"
                    type="text"
                    format="n2"
                    component={FormTextField}
                    // validator={requiredValidator}
                  />
                </GridLayoutItem>
                <GridLayoutItem
                  style={{ width: "100%", display: "flex", gap: 10 }}
                >
                  <Field
                    wrapperClassName="w-100"
                    name="purity"
                    label="Purity"
                    placeholder="Select Purity"
                    type="number"
                    component={FormSelectionField}
                    // validator={requiredValidator}
                    options={PurityList?.map((purity: any) => {
                      return {
                        value: purity.ID,
                        label: purity.purity_name,
                      };
                    })}
                  />
                  <Field
                    wrapperClassName="w-100"
                    name="color"
                    label="Color"
                    placeholder="i.e. Rose Gold"
                    component={FormTextField}
                    // validator={requiredValidator}
                  />
                </GridLayoutItem>
                <GridLayoutItem colSpan={2}>
                  <Field
                    name="remark"
                    label="Description"
                    placeholder="Description"
                    component={FormRichTextField}
                    // validator={requiredValidator}
                  />
                </GridLayoutItem>
              </GridLayout>
            </ShadowCard>
            <ShadowCard style={{ marginTop: 10 }}>
              <GridLayoutItem colSpan={6}>
                <Typography.h4>{"Process Details"}</Typography.h4>
              </GridLayoutItem>
              <FieldArray
                formRenderProps={formRenderProps}
                component={ProcessDetailArray}
                name="processdetails"
              />
            </ShadowCard>
            <ShadowCard style={{ marginTop: 10 }}>
              <GridLayoutItem colSpan={3}>
                <Typography.h4>Size Details List</Typography.h4>
              </GridLayoutItem>
              <FieldArray
                formRenderProps={formRenderProps}
                component={SizeDetailArray}
                name="sizedetails"
              />
            </ShadowCard>
            <ShadowCard style={{ marginTop: 10 }}>
              <GridLayout
                style={{ marginRight: 20 }}
                gap={{ rows: 0, cols: 10 }}
                cols={[
                  { width: "33.33%" },
                  { width: "33.33%" },
                  { width: "33.33%" },
                ]}
              >
                {/* <GridLayoutItem colSpan={2}>
                  <Field
                    name="remark"
                    label="Remarks"
                    component={FormRichTextField}
                  />
                </GridLayoutItem> */}
                <GridLayoutItem
                  colSpan={3}
                  style={{
                    textAlign: "end",
                    marginTop: "20px",
                    width: "100%",
                  }}
                >
                  <Button
                    type="button"
                    fillMode={"outline"}
                    themeColor={"primary"}
                    style={{ marginRight: 4 }}
                    onClick={handleFormClose}
                  >
                    Cancel
                  </Button>
                  <ButtonWithLoading
                    label={!order_guid ? "Create" : "Update"}
                    type="submit"
                    disabled={!formRenderProps.allowSubmit || formLoading}
                  />
                </GridLayoutItem>
              </GridLayout>
              <OrderChangeWatcher formRenderProps={formRenderProps} />
              <SelectProductDialog formRenderProps={formRenderProps} />
            </ShadowCard>
          </FormElement>
        )}
      />
    </>
  );
};

export default CreateOrder;

const ShowProductImageDialog: React.FC = () => {
  const settings = {
    dots: true,
    infinite: true,
    autoplay: false,
    centerMode: true,
    autoplaySpeed: 2000,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: true,
    centerPadding: "0px",
    centerMargin: "0px",
    draggable: true,
    responsive: [
      {
        breakpoint: 991,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  const dialogName = useAppSelector((state) => state.dialog.dialogName);
  const loading = useAppSelector((state) => state.item.loading);
  const ImageList = useAppSelector((state) => state.item.ImageList);
  const gridRef = useRef<any>(null);

  const dispatch = useAppDispatch();

  const handleOpenImage = (url: string) => {
    if (url && typeof url === "string") {
      window.open(url);
    }
  };

  return (
    <>
      {dialogName === "showProductImage" && (
        <AppDialog>
          {loading ? (
            <div>
              <LoadingPanel gridRef={gridRef} />
            </div>
          ) : (
            <div style={{ width: "40vw" }}>
              <GridLayout
                style={{
                  marginLeft: 10,
                }}
                gap={{ rows: 10, cols: 10 }}
              >
                <GridLayoutItem
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                  }}
                >
                  <Typography.h4 style={{ margin: "0" }}>
                    {"Product Images"}
                  </Typography.h4>
                  <ImCross
                    style={{
                      color: "white",
                      background: "red",
                      borderRadius: "50%",
                      padding: "5px",
                      fontSize: "24px",
                    }}
                    onClick={() => dispatch(closeDialog())}
                  />
                </GridLayoutItem>
                <GridLayoutItem>
                  {ImageList && ImageList?.length === 0 && (
                    <AlertBox alert="No Image Available!" />
                  )}
                </GridLayoutItem>
                <GridLayoutItem
                  style={{ display: "flex", justifyContent: "center" }}
                >
                  <div className="slider-container">
                    {ImageList && ImageList.length > 1 ? (
                      <Slider {...settings}>
                        {ImageList &&
                          ImageList.length > 0 &&
                          ImageList.map((image: any, index: number) => (
                            <div key={index}>
                              <img
                                src={image.image_name}
                                style={{ width: "100%", height: "auto" }}
                                onClick={() =>
                                  handleOpenImage(image.image_name)
                                }
                              />
                              <Typography.h5 style={{ textAlign: "center" }}>
                                {image.title}
                              </Typography.h5>
                            </div>
                          ))}
                      </Slider>
                    ) : (
                      <>
                        {ImageList &&
                          ImageList.length > 0 &&
                          ImageList.map((image: any, index: number) => (
                            <div
                              style={{
                                width: "40%",
                                margin: "auto",
                              }}
                              key={index}
                            >
                              <img
                                src={image.image_name}
                                style={{
                                  width: "100%",
                                  height: "auto",
                                  objectFit: "contain",
                                }}
                                onClick={() =>
                                  handleOpenImage(image.image_name)
                                }
                              />
                            </div>
                          ))}
                      </>
                    )}
                  </div>
                </GridLayoutItem>
                <GridLayoutItem
                  style={{
                    textAlign: "center",
                    marginTop: 20,
                  }}
                ></GridLayoutItem>
              </GridLayout>
            </div>
          )}
        </AppDialog>
      )}
    </>
  );
};

const SelectProductDialog: React.FC<IChangeWatcher> = ({ formRenderProps }) => {
  const dispatch = useAppDispatch();
  const dialogName = useAppSelector((state) => state.dialog?.dialogName);
  const productList = useAppSelector((state) => state.item?.ItemList);

  const loading = useAppSelector((state) => state.item?.loading);
  const gridRef = useRef<any>(null);
  interface PageState {
    skip: number;
    take: number;
  }
  const initialDataState: PageState = { skip: 0, take: 10 };

  const [page, setPage] = React.useState<PageState>(initialDataState);
  const [pageSizeValue, setPageSizeValue] = React.useState<
    number | string | undefined
  >();
  const [filter, setFilter] = React.useState<
    CompositeFilterDescriptor | undefined
  >(undefined);

  const pageChange = (event: GridPageChangeEvent) => {
    const targetEvent = event.targetEvent as PagerTargetEvent;
    const take =
      targetEvent.value === "All" ? productList.length : event.page.take;

    if (targetEvent.value) {
      setPageSizeValue(targetEvent.value);
    }
    setPage({
      ...event.page,
      take,
    });
  };

  const MyImageCommandCell = (props: GridCellProps) => {
    const handlClickItem = (image: string) => {
      if (image && typeof image === "string") {
        window.open(image);
      }
    };
    if (props.dataItem?.image_name === null) {
      return (
        <td>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              height: "50px",
            }}
          >
            <MdOutlineImageNotSupported style={{ fontSize: "30px" }} />
            <p style={{ margin: 0, fontSize: "12px" }}>No Image!</p>
          </div>
        </td>
      );
    }
    return (
      <td className="action-td">
        <div
          style={{
            position: "relative",
            border: "1px dotted lightgrey",
            borderRadius: "5px",
            height: "50px",
            overflow: "hidden",
          }}
        >
          <img
            className="product-image"
            src={props.dataItem?.image_name}
            alt=""
            style={{
              height: "100%",
              width: "100%",
              objectFit: "cover",
              borderRadius: "5px",
            }}
          />
          <div className="image-view">
            <FaRegEye
              style={{
                color: "white",
                height: 24,
                width: 24,
                cursor: "pointer",
              }}
              onClick={() => handlClickItem(props.dataItem?.image_name)}
            />
          </div>
        </div>
      </td>
    );
  };

  const MyEditCommandCell = (props: GridCellProps) => {
    const handleSelectProduct = (id: number) => {
      formRenderProps.onChange("item_id", { value: id });
      dispatch(closeDialog());
    };

    return (
      <td className="action-td">
        <Button
          type="button"
          fillMode="solid"
          themeColor={"primary"}
          size="small"
          title="Select Product"
          onClick={() => handleSelectProduct(props.dataItem?.id)}
        >
          Select
        </Button>
      </td>
    );
  };
  const handleFilterChange = (e: GridFilterChangeEvent) => {
    setFilter(e.filter);
    setPage({ skip: 0, take: page.take });
  };

  const HeaderCustomCell = (props: GridCustomHeaderCellProps) => (
    <HeaderThElement
      columnId={props.thProps?.columnId || ""}
      {...props.thProps}
      className={`table-header ${props.index === 5 ? "locked-header" : ""}`}
    >
      {props.children}
    </HeaderThElement>
  );
  const CustomCell = (props: any) => {
    return (
      <td {...props.tdProps} colSpan={1} className={"table-cell"}>
        {props.children}
      </td>
    );
  };
  const MyDataCustomCell = (props: GridCustomCellProps) => (
    <CustomCell {...props} />
  );

  return (
    <>
      {dialogName === "SelectProductDialog" && (
        <AppDialog>
          <div style={{ width: "80vw" }}>
            {loading ? (
              <LoadingPanel gridRef={gridRef} />
            ) : (
              <GridLayout gap={{ rows: 10, cols: 10 }}>
                <GridLayoutItem
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                  }}
                >
                  <Typography.h4>{"Select Product"}</Typography.h4>
                  <ImCross
                    style={{
                      color: "white",
                      background: "red",
                      borderRadius: "50%",
                      padding: "5px",
                      fontSize: "24px",
                    }}
                    onClick={() => dispatch(closeDialog())}
                  />
                </GridLayoutItem>
                <GridLayoutItem>
                  {productList && productList.length ? (
                    <KendoGrid
                      className="selection-dialog"
                      style={{ maxHeight: "calc(100vh - 152px)" }}
                      filterable={true}
                      filter={filter}
                      data={
                        filter
                          ? filterBy(productList, filter).slice(
                              page.skip,
                              page.take + page.skip
                            )
                          : productList.slice(page.skip, page.take + page.skip)
                      }
                      total={productList[0]?.itemcount || productList.length}
                      onFilterChange={handleFilterChange}
                      skip={page.skip}
                      take={page.take}
                      pageable={{
                        buttonCount: 5,
                        pageSizes: [5, 10, 15, "All"],
                        pageSizeValue: pageSizeValue,
                        type: "input",
                      }}
                      onPageChange={pageChange}
                      cells={{
                        headerCell: HeaderCustomCell,
                        data: MyDataCustomCell,
                      }}
                    >
                      <Column
                        field="image_name"
                        title="Image"
                        width={100}
                        cell={MyImageCommandCell}
                        filterable={false}
                        // locked={true}
                      />
                      <Column
                        field="item_name"
                        title="Product Name"
                        cell={(props: any) => (
                          <td>
                            <div style={{ color: "green" }}>
                              <strong>{props.dataItem?.item_name}</strong>
                            </div>
                          </td>
                        )}
                      />
                      <Column
                        field="item_nick_name"
                        title="Product Short Name"
                      />
                      <Column field="category_name" title="Product Category" />
                      <Column
                        field="item_subcategory_name"
                        title="Product Sub Category"
                      />
                      <Column
                        field="id"
                        title="Actions"
                        cell={MyEditCommandCell}
                        width={"150px"}
                        filterable={false}
                        // locked={true}
                      />
                    </KendoGrid>
                  ) : (
                    <AlertBox />
                  )}
                </GridLayoutItem>
                <GridLayoutItem
                  style={{
                    textAlign: "center",
                    marginTop: 20,
                  }}
                ></GridLayoutItem>
              </GridLayout>
            )}
          </div>
        </AppDialog>
      )}
    </>
  );
};
