import { createAsyncThunk } from "@reduxjs/toolkit";
import { API_URL } from "../../../app/config";
import { axiosApiInstance } from "../../../app/axios";
import { ErrorToast, SuccessToast } from "../../../components/toast/Toasts";
import { ICity } from "../cityModel";

export const getAllCities = createAsyncThunk("City/FindAllCity", async () => {
  try {
    const response = await axiosApiInstance.post(
      `${API_URL}/City/FindAllCity`,
      {
        CityName: "",
      }
    );
    return response.data?.Data;
  } catch (error: any) {
    console.error("Error Fetching cities:", error);
    return Promise.reject({
      message: error?.response?.data?.Details || "Something went wrong",
    });
  }
});

export const createCity = createAsyncThunk(
  "City/CreateCity",
  async (CityData: any) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/City/InsertCity`,
        CityData
      );
      SuccessToast(response.data?.Details || "Success");
      return response.data;
    } catch (error: any) {
      ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error Fetching cities:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const updateCity = createAsyncThunk(
  "City/UpdateCity",
  async (CityData: any) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/City/UpdateCity`,
        CityData
      );
      SuccessToast(response.data?.Details || "Success");
      return response.data;
    } catch (error: any) {
      ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error updating city:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const getCityById = createAsyncThunk(
  "City/FindByIDCity",
  async (ID: any) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/City/FindByIDCity`,
        { city_guid:ID }
      );
      // SuccessToast(response.data?.Details || "Success")
      const result = response.data?.Data as any;
      return {
        id: result.id,
        country_id: result.country_id,
        city_name: result.city_name,
        state_id: result.state_id,
        isactive: result.isactive,
      } as ICity;
    } catch (error: any) {
      ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error Fetching cities:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const deleteCity = createAsyncThunk(
  "City/DeleteCity",
  async (ID: number) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/City/DeleteCity`,
        { city_guid:ID }
      );
      SuccessToast(response.data?.Details || "Success");
      return response.data;
    } catch (error: any) {
      ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error Deleting city:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const findAllActiveCity = createAsyncThunk(
  "City/FindAllActiveCity",
  async (CityData: any) => {
    try {
      const response = await axiosApiInstance.get(
        `${API_URL}/City/FindAllActiveCity`,
        CityData
      );
      // SuccessToast(response.data?.Details || "Success");
      return response.data.Data;
    } catch (error: any) {
      console.error("Error Deleting cities:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const cityActiveInactive = createAsyncThunk(
  "City/ActiveInActiveCity",
  async (formData: any) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/City/ActiveInActiveCity`,
        formData
      );
      SuccessToast(response.data?.Details || "Success");
      return response.data;
    } catch (error: any) {
      ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error active-inactive city:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);
